export const listenerAdded = Symbol("listenerAdded")
export const listenerRemoved = Symbol("listenerRemoved")

export type MetaEvents = {
  [listenerAdded]: ListenerChangedData
  [listenerRemoved]: ListenerChangedData
}

export type ListenerChangedData =
  | {
      eventName: EventName
      listener: EventListener<any>
    }
  | {
      eventName?: undefined
      listener: AnyEventListener<any>
    }

export type EventName = PropertyKey
export type DefaultEvents = Record<EventName, any>

type AnyEvent<Events> = {
  [Name in keyof Events]: [event: Name, data: Events[Name]]
}[keyof Events]

export type Unsubscribe = () => void
export type EventListener<EventData> = (eventData: EventData) => void | Promise<void>
export type AnyEventListener<Events> = (...args: AnyEvent<Events>) => void | Promise<void>

interface EventListenerMap<Events> {
  get<Key extends keyof Events>(eventName: Key): Set<EventListener<Events[Key]>> | undefined
  set<Key extends keyof Events>(eventName: Key, value: Set<EventListener<Events[Key]>>): this
  has<Key extends keyof Events>(eventName: Key): boolean
  delete<Key extends keyof Events>(eventName: Key): boolean
  clear(): void
}

export type EventPromise<EventData> = {
  off(): void
} & Promise<EventData>

export interface EventObservable<Events = DefaultEvents> {
  on<Name extends keyof Events>(eventName: Name, listener: EventListener<Events[Name]>): Unsubscribe
  off<Name extends keyof Events>(eventName: Name, listener: EventListener<Events[Name]>): void
  once<Name extends keyof Events>(eventName: Name): EventPromise<Events[Name]>

  onAny(listener: AnyEventListener<Events>): Unsubscribe
  offAny(listener: AnyEventListener<Events>): void
}

const resolvedPromise = Promise.resolve()

export class EventEmitter<Events = DefaultEvents> implements EventObservable<Events> {
  public static isGlobalDebugEnabled: boolean = false

  private anyListenersSet = new Set<AnyEventListener<Events>>()
  private listenersMap: EventListenerMap<Events> = new Map()
  private metaListenersMap: EventListenerMap<MetaEvents> = new Map()

  constructor(
    readonly name: string = "",
    public debugEnabled: boolean = false,
  ) {}

  private log(type: string, eventName?: EventName, eventData?: any) {
    if (EventEmitter.isGlobalDebugEnabled || this.debugEnabled) {
      try {
        eventData = JSON.stringify(eventData)
      } catch {
        eventData = `Object with the following keys failed to stringify: ${Object.keys(eventData).join(",")}`
      }

      if (typeof eventName === "symbol" || typeof eventName === "number") {
        eventName = eventName.toString()
      }

      const currentTime = new Date()
      const logTime = `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}.${currentTime.getMilliseconds()}`
      console.log(
        `[${logTime}][event-emitter:${this.name}][${type}] Event Name: ${eventName}\n\tdata: ${eventData}`,
      )
    }
  }

  on<Name extends keyof Events>(
    eventName: Name,
    listener: EventListener<Events[Name]>,
  ): Unsubscribe {
    let listeners = this.listenersMap.get(eventName)
    if (!listeners) {
      listeners = new Set()
      this.listenersMap.set(eventName, listeners)
    }

    listeners.add(listener)

    this.log("subscribe", eventName)
    this.emitMetaEvent(listenerAdded, { eventName, listener })

    return () => this.off(eventName, listener)
  }

  off<Name extends keyof Events>(eventName: Name, listener: EventListener<Events[Name]>) {
    this.log("unsubscribe", eventName)
    const exists = this.listenersMap.get(eventName)?.delete(listener)
    if (exists) {
      this.emitMetaEvent(listenerRemoved, { eventName, listener })
    }
  }

  once<Name extends keyof Events>(eventName: Name): EventPromise<Events[Name]> {
    let unsubscribe: Unsubscribe

    return Object.assign(
      new Promise<Events[Name]>((resolve) => {
        unsubscribe = this.on(eventName, (data) => {
          unsubscribe()
          resolve(data)
        })
      }),
      {
        off: () => unsubscribe(),
      },
    )
  }

  onAny(listener: AnyEventListener<Events>): Unsubscribe {
    this.log("subscribeAny")

    this.anyListenersSet.add(listener)
    this.emitMetaEvent(listenerAdded, { listener })
    return this.offAny.bind(this, listener)
  }

  offAny(listener: AnyEventListener<Events>) {
    const exists = this.anyListenersSet.delete(listener)
    if (exists) {
      this.emitMetaEvent(listenerRemoved, { listener })
    }
  }

  onMeta<Name extends keyof MetaEvents>(
    eventName: Name,
    listener: EventListener<MetaEvents[Name]>,
  ): Unsubscribe {
    let listeners = this.metaListenersMap.get(eventName)
    if (!listeners) {
      listeners = new Set()
      this.metaListenersMap.set(eventName, listeners)
    }

    listeners.add(listener)

    this.log("subscribeMeta", eventName)
    return () => this.offMeta(eventName, listener)
  }

  offMeta<Name extends keyof MetaEvents>(
    eventName: Name,
    listener: EventListener<MetaEvents[Name]>,
  ) {
    this.log("unsubscribeMeta", eventName)
    this.metaListenersMap.get(eventName)?.delete(listener)
  }

  private async emitMetaEvent<Name extends keyof MetaEvents>(
    eventName: Name,
    eventData: MetaEvents[Name],
  ) {
    const listeners = this.metaListenersMap.get(eventName) ?? new Set()
    const staticListeners = [...listeners]

    await Promise.all([
      ...staticListeners.map(async (listener) => {
        if (listeners.has(listener)) {
          return listener(eventData)
        }
      }),
    ])
  }

  async emit<Name extends keyof Events>(
    ...[eventName, data]: Events[Name] extends undefined
      ? [eventName: Name]
      : [eventName: Name, data: Events[Name]]
  ) {
    const eventData = data as Events[Name]
    this.log("emit", eventName, eventData)

    const listeners = this.listenersMap.get(eventName) ?? new Set()
    const anyListeners = this.anyListenersSet
    const staticListeners = [...listeners]
    const staticAnyListeners = [...anyListeners]

    await resolvedPromise
    await Promise.all([
      ...staticListeners.map(async (listener) => {
        if (listeners.has(listener)) {
          return listener(eventData)
        }
      }),
      ...staticAnyListeners.map(async (listener) => {
        if (anyListeners.has(listener)) {
          return listener(eventName, eventData)
        }
      }),
    ])
  }

  async emitSerial<Name extends keyof Events>(
    ...[eventName, data]: Events[Name] extends void
      ? [eventName: Name]
      : [eventName: Name, data: Events[Name]]
  ) {
    const eventData = data as Events[Name]
    this.log("emitSerial", eventName, eventData)

    const listeners = this.listenersMap.get(eventName) ?? new Set()
    const anyListeners = this.anyListenersSet
    const staticListeners = [...listeners]
    const staticAnyListeners = [...anyListeners]

    await resolvedPromise
    for (const listener of staticListeners) {
      if (listeners.has(listener)) {
        await listener(eventData)
      }
    }

    for (const listener of staticAnyListeners) {
      if (anyListeners.has(listener)) {
        await listener(eventName, eventData)
      }
    }
  }

  emitSync<Name extends keyof Events>(
    ...[eventName, data]: Events[Name] extends void
      ? [eventName: Name]
      : [eventName: Name, data: Events[Name]]
  ) {
    const eventData = data as Events[Name]

    const listeners = this.listenersMap.get(eventName) ?? new Set()
    const anyListeners = this.anyListenersSet
    const staticListeners = [...listeners]
    const staticAnyListeners = [...anyListeners]

    for (const listener of staticListeners) {
      if (listeners.has(listener)) {
        listener(eventData)
      }
    }

    for (const listener of staticAnyListeners) {
      if (anyListeners.has(listener)) {
        listener(eventName, eventData)
      }
    }
  }

  clearListeners<Name extends keyof Events>(eventName?: Name | undefined) {
    this.log("clear", eventName, undefined)

    if (eventName !== undefined) {
      this.listenersMap.get(eventName)?.clear()
    } else {
      this.anyListenersSet.clear()
      this.listenersMap.clear()
    }
  }
}

export class BaseEventObservable<Events = DefaultEvents> implements EventObservable<Events> {
  protected emitter = new EventEmitter<Events>()

  on<Name extends keyof Events>(
    eventName: Name,
    listener: EventListener<Events[Name]>,
  ): Unsubscribe {
    return this.emitter.on(eventName, listener)
  }

  off<Name extends keyof Events>(eventName: Name, listener: EventListener<Events[Name]>) {
    this.emitter.off(eventName, listener)
  }

  once<Name extends keyof Events>(eventName: Name): EventPromise<Events[Name]> {
    return this.emitter.once(eventName)
  }

  onAny(listener: AnyEventListener<Events>): Unsubscribe {
    return this.emitter.onAny(listener)
  }

  offAny(listener: AnyEventListener<Events>) {
    this.emitter.offAny(listener)
  }
}
