import jspreadsheet, { JspreadsheetInstance } from "@/packages/jspreadsheet"
import { JSpreadsheetOptions, CellValue } from "@/packages/jspreadsheet/types"
import * as Y from "yjs"
import { Callable } from "@/packages/util/types"
import { TableOptionsKeys } from "@/engine/state/types"
import { StrictYMap } from "../util/yjs"

export type JssOptions = Partial<JSpreadsheetOptions> & { onrefresh?: () => void }

export class YJSpreadsheetBinding {
  readonly jss: JspreadsheetInstance

  private undoManager: Y.UndoManager

  constructor(
    private yDoc: Y.Doc,
    private yData: Y.Map<any>,
    private yOptions: StrictYMap<TableOptionsKeys>,
    private element: HTMLDivElement,
    private jssOptions?: JssOptions,
  ) {
    let minDimensions: [number, number] | undefined
    if (yOptions.has("minSize")) {
      minDimensions = yOptions.get("minSize")
    } else {
      minDimensions = jssOptions?.minDimensions
    }

    this.jss = jspreadsheet(element, buildDataArray(yData), {
      ...jssOptions,
      minDimensions,
      onundo: () => {
        this.undoManager.undo()
      },
      onredo: () => {
        this.undoManager.redo()
      },
      ...this.forwardJssEvent("onchange", this.onChange),
      ...this.forwardJssEvent("onmovecolumn", this.onMoveColumn),
      ...this.forwardJssEvent("onmoverow", this.onMoveRow),
      ...this.forwardJssEvent("ondeletecolumn", this.onDeleteColumn),
      ...this.forwardJssEvent("ondeleterow", this.onDeleteRow),
      ...this.forwardJssEvent("oninsertcolumn", this.onInsertColumn),
      ...this.forwardJssEvent("oninsertrow", this.onInsertRow),
      ...this.forwardJssEvent("onresizecolumn", this.onResizeColumn),
      ...this.forwardJssEvent("onchangeheader", this.onChangeHeader),
    })
    this.jss.init()

    const columnMeta = yOptions.get("columnMeta")
    if (columnMeta) {
      this.jss.ignoreEvents = true
      for (const [key, value] of Object.entries(columnMeta)) {
        const colIndex = Number(key)
        if (value.title) {
          this.jss.setHeader(colIndex, value.title)
        }
        if (value.size) {
          this.jss.setWidth(colIndex, value.size, value.size)
        }
      }
      this.jss.ignoreEvents = false
    }

    this.undoManager = new Y.UndoManager(yData, {
      trackedOrigins: new Set([this]),
    })

    yData.observe(this.handleDataYEvent)
    yOptions.observe(this.handleOptionsYEvent)

    this.element.addEventListener("keydown", this.onKeyDown, true)
  }

  private forwardJssEvent<
    Event extends keyof JssOptions & string,
    Handler extends JssOptions[Event] & Callable,
  >(this: YJSpreadsheetBinding, eventName: Event, handler: Handler) {
    const boundHandler = handler.bind(this)
    return {
      [eventName]: (...args: Parameters<JssOptions[Event]>) => {
        boundHandler(...args)
        this.jssOptions?.[eventName]?.(...args)
      },
    }
  }

  private handleOptionsYEvent = (event: Y.YMapEvent<any>, transaction: Y.Transaction) => {
    if (transaction.origin === this) return

    try {
      this.jss.ignoreEvents = true

      event.changes.keys.forEach((change, key) => {
        const optionKey = key as keyof TableOptionsKeys
        if (optionKey === "minSize" && change.action !== "delete") {
          const [minCols, minRows] = this.yOptions.get("minSize")!

          const currentRowCount = this.jss.rowElements.length
          if (currentRowCount < minRows) {
            this.jss.insertRow(minRows - currentRowCount)
          } else if (currentRowCount > minRows) {
            // TODO: might cause conflicts and data loss
            this.jss.deleteRow(minRows - 1, currentRowCount - minRows)
          }

          const currentColCount = this.jss.headerElements.length
          if (currentColCount < minCols) {
            this.jss.insertColumn(minCols - currentColCount)
          } else if (currentColCount > minCols) {
            this.jss.deleteColumn(minCols - 1, currentColCount - minCols)
          }
        } else if (optionKey === "columnMeta") {
          const columnMeta = this.yOptions.get("columnMeta")

          for (let i = 0; i < this.jss.colgroupElements.length; i++) {
            const colMeta = columnMeta?.[i]
            if (colMeta) {
              this.jss.setHeader(i, colMeta.title || null)
              if (colMeta.size) {
                this.jss.setWidth(i, colMeta.size, colMeta.size)
              }
            } else {
              this.jss.setHeader(i, null)
            }
          }
        }
      })
    } finally {
      this.jss.ignoreEvents = false
    }
  }

  private handleDataYEvent = (event: Y.YMapEvent<any>, transaction: Y.Transaction) => {
    if (transaction.origin === this) return

    try {
      this.jss.ignoreEvents = true
      event.changes.keys.forEach((change, key) => {
        const [row, col] = key.split(",").map(Number)

        this.jss.ignoreEvents = true

        const currentRowCount = this.jss.rowElements.length
        if (currentRowCount <= row) {
          this.jss.insertRow(row - currentRowCount + 1)
        }

        const currentColCount = this.jss.headerElements.length
        if (currentColCount <= col) {
          this.jss.insertColumn(col - currentColCount + 1)
        }

        if (change.action === "add") {
          this.jss.setValueAtCoords(col, row, this.yData.get(key) as CellValue)
        } else if (change.action === "update") {
          this.jss.setValueAtCoords(col, row, this.yData.get(key) as CellValue)
        } else if (change.action === "delete") {
          this.jss.setValueAtCoords(col, row, "")
        }
      })
    } finally {
      this.jss.ignoreEvents = false
    }
  }

  private onKeyDown = (e: KeyboardEvent) => {
    const isUndo = e.key === "z" && (e.ctrlKey || e.metaKey) && !e.shiftKey
    const isRedo =
      (e.key === "y" && (e.ctrlKey || e.metaKey) && !e.shiftKey) ||
      (e.key === "z" && (e.ctrlKey || e.metaKey) && e.shiftKey)

    if (isUndo) {
      this.undoManager.undo()
      e.preventDefault()
      e.stopPropagation()
      return
    }

    if (isRedo) {
      this.undoManager.redo()
      e.preventDefault()
      e.stopPropagation()
      return
    }
  }

  private onChange(
    cell: HTMLTableCellElement,
    colArg: number,
    rowArg: number,
    newValue: CellValue,
    oldValue: CellValue,
  ) {
    if (newValue !== oldValue) {
      this.yDoc.transact(() => {
        if (newValue === "") {
          this.yData.delete(`${rowArg},${colArg}`)
        } else {
          this.yData.set(`${rowArg},${colArg}`, newValue)
        }
      }, this)
    }
  }

  private onMoveColumn(oldPosition: number, newPosition: number) {
    this.yDoc.transact(() => {
      const startColumn = Math.min(oldPosition, newPosition)
      const endColumn = Math.max(oldPosition, newPosition)
      const moveDirection = Math.sign(oldPosition - newPosition)

      const moved: [number, number, any][] = []
      for (const [key, value] of this.yData) {
        const [row, col] = key.split(",").map(Number)
        if (col >= startColumn && col <= endColumn) {
          const newCol = col === oldPosition ? newPosition : col + moveDirection
          moved.push([row, newCol, value])
          this.yData.delete(key)
        }
      }
      for (const [row, col, value] of moved) {
        this.yData.set(`${row},${col}`, value)
      }

      const currentColumnMeta = this.yOptions.get("columnMeta")
      if (currentColumnMeta) {
        const newColumnMeta: TableOptionsKeys["columnMeta"] = {}
        let changed = false
        for (const [key, value] of Object.entries(currentColumnMeta)) {
          const col = Number(key)
          if (col >= startColumn && col <= endColumn) {
            const newCol = col === oldPosition ? newPosition : col + moveDirection
            newColumnMeta[newCol] = value
            changed = true
          } else {
            newColumnMeta[col] = value
          }
        }
        if (changed) {
          this.yOptions.set("columnMeta", newColumnMeta)
        }
      }
    }, this)
  }

  private onMoveRow(oldPositionArg: number | string, newPosition: number) {
    const oldPosition = Number(oldPositionArg)
    this.yDoc.transact(() => {
      const startRow = Math.min(oldPosition, newPosition)
      const endRow = Math.max(oldPosition, newPosition)
      const moveDirection = Math.sign(oldPosition - newPosition)

      const moved: [number, number, any][] = []
      for (const [key, value] of this.yData) {
        const [row, col] = key.split(",").map(Number)
        if (row >= startRow && row <= endRow) {
          const newRow = row === oldPosition ? newPosition : row + moveDirection
          moved.push([newRow, col, value])
          this.yData.delete(key)
        }
      }
      for (const [row, col, value] of moved) {
        this.yData.set(`${row},${col}`, value)
      }
    }, this)
  }

  private onDeleteColumn(colIndex: number, numOfColumns: number) {
    this.yDoc.transact(() => {
      const startColumn = colIndex
      const endColumn = colIndex + numOfColumns - 1

      const moved: [number, number, any][] = []
      for (const [key] of this.yData) {
        const [row, col] = key.split(",").map(Number)
        if (col >= startColumn) {
          if (col > endColumn) {
            const newCol = col - numOfColumns
            moved.push([row, newCol, this.yData.get(key)])
          }
          this.yData.delete(key)
        }
      }
      for (const [row, col, value] of moved) {
        this.yData.set(`${row},${col}`, value)
      }

      const currentColumnMeta = this.yOptions.get("columnMeta")
      if (currentColumnMeta) {
        const newColumnMeta: TableOptionsKeys["columnMeta"] = {}
        let changed = false
        for (const [key, value] of Object.entries(currentColumnMeta)) {
          const col = Number(key)
          if (col < startColumn) {
            newColumnMeta[col] = value
          } else if (col > endColumn) {
            newColumnMeta[col - numOfColumns] = value
            changed = true
          } else {
            changed = true
          }
        }
        if (changed) {
          this.yOptions.set("columnMeta", newColumnMeta)
        }
      }

      this.yOptions.set("minSize", [this.jss.colgroupElements.length, this.jss.rowElements.length])
    }, this)
  }

  private onDeleteRow(rowIndex: number, numOfRows: number, deletedCells: HTMLTableCellElement[][]) {
    this.yDoc.transact(() => {
      const startRow = rowIndex
      const endRow = rowIndex + numOfRows - 1

      const moved: [number, number, any][] = []
      for (const [key] of this.yData) {
        const [row, col] = key.split(",").map(Number)
        if (row >= startRow) {
          if (row > endRow) {
            const newRow = row - numOfRows
            moved.push([newRow, col, this.yData.get(key)])
          }
          this.yData.delete(key)
        }
      }
      for (const [row, col, value] of moved) {
        this.yData.set(`${row},${col}`, value)
      }

      this.yOptions.set("minSize", [this.jss.colgroupElements.length, this.jss.rowElements.length])
    }, this)
  }

  private onInsertColumn(colIndex: number, numOfColumns: number, insertBefore?: boolean) {
    this.yDoc.transact(() => {
      const startColumn = insertBefore ? colIndex : colIndex + 1
      const moved: [number, number, any][] = []
      for (const [key] of this.yData) {
        const [row, col] = key.split(",").map(Number)
        if (col >= startColumn) {
          const newCol = col + numOfColumns
          moved.push([row, newCol, this.yData.get(key)])
          this.yData.delete(key)
        }
      }
      for (const [row, col, value] of moved) {
        this.yData.set(`${row},${col}`, value)
      }

      const currentColumnMeta = this.yOptions.get("columnMeta")
      if (currentColumnMeta) {
        const newColumnMeta: TableOptionsKeys["columnMeta"] = {}
        let changed = false
        for (const [key, value] of Object.entries(currentColumnMeta)) {
          const col = Number(key)
          if (col < startColumn) {
            newColumnMeta[col] = value
          } else {
            newColumnMeta[col + numOfColumns] = value
            changed = true
          }
        }
        if (changed) {
          this.yOptions.set("columnMeta", newColumnMeta)
        }
      }

      this.yOptions.set("minSize", [this.jss.colgroupElements.length, this.jss.rowElements.length])
    }, this)
  }

  private onInsertRow(
    rowIndex: number,
    numOfRows: number,
    addedCells: HTMLTableCellElement[][],
    insertBefore: boolean,
  ) {
    this.yDoc.transact(() => {
      const startRow = insertBefore ? rowIndex : rowIndex + 1
      const moved: [number, number, any][] = []
      for (const [key] of this.yData) {
        const [row, col] = key.split(",").map(Number)
        if (row >= startRow) {
          const newRow = row + numOfRows
          moved.push([newRow, col, this.yData.get(key)])
          this.yData.delete(key)
        }
      }
      for (const [row, col, value] of moved) {
        this.yData.set(`${row},${col}`, value)
      }

      this.yOptions.set("minSize", [this.jss.colgroupElements.length, this.jss.rowElements.length])
    }, this)
  }

  private onResizeColumn(columns: number[], newWidth: number[], oldWidth: number[]) {
    const changed: [number, number][] = columns
      .filter((_, i) => newWidth[i] !== oldWidth[i])
      .map((column, i) => [column, newWidth[i]])

    if (changed.length) {
      this.yDoc.transact(() => {
        const columnMeta = { ...this.yOptions.get("columnMeta") }
        for (const [column, width] of changed) {
          columnMeta[column] = { ...columnMeta[column], size: width }
        }
        this.yOptions.set("columnMeta", columnMeta)
      }, this)
    }
  }

  private onChangeHeader(col: number, oldValue: string, value: string) {
    if (oldValue === value) return

    this.yDoc.transact(() => {
      const columnMeta = { ...this.yOptions.get("columnMeta") }
      columnMeta[col] = { ...columnMeta[col], title: value }
      this.yOptions.set("columnMeta", columnMeta)
    }, this)
  }

  destroy() {
    this.jss.destroy()
    this.yData.unobserve(this.handleDataYEvent)
    this.yOptions.unobserve(this.handleOptionsYEvent)
    this.element.removeEventListener("keydown", this.onKeyDown, true)
    this.undoManager.destroy()
  }
}

export function buildDataArray(map: Y.Map<any>) {
  let rows = 0
  let cols = 0
  const values: [number, number, any][] = []
  for (const [key, value] of map) {
    const [row, col] = key.split(",").map(Number)
    rows = Math.max(rows, row + 1)
    cols = Math.max(cols, col + 1)
    values.push([row, col, value])
  }

  const arr = Array.from(Array(rows), () => Array.from(Array(cols), () => ""))
  for (const [row, col, value] of values) {
    arr[row][col] = value
  }

  return arr
}
