import { CheckIcon, MinusIcon } from "lucide-react"
import { ReactNode } from "react"
import {
  Checkbox as AriaCheckbox,
  CheckboxGroup as AriaCheckboxGroup,
  CheckboxGroupProps as AriaCheckboxGroupProps,
  CheckboxProps as AriaCheckboxProps,
  ValidationResult as AriaValidationResult,
  composeRenderProps,
} from "react-aria-components"
import { tv } from "tailwind-variants"
import { Description, FieldError, Label } from "./Field"
import { composeTailwindRenderProps, focusRing } from "./utils"

export interface CheckboxGroupProps extends Omit<AriaCheckboxGroupProps, "children"> {
  label?: string
  children?: ReactNode
  description?: string
  errorMessage?: string | ((validation: AriaValidationResult) => string)
}

export function CheckboxGroup(props: CheckboxGroupProps) {
  return (
    <AriaCheckboxGroup
      {...props}
      className={composeTailwindRenderProps(props.className, "flex flex-col gap-2")}
    >
      <Label>{props.label}</Label>
      {props.children}
      {props.description && <Description>{props.description}</Description>}
      <FieldError>{props.errorMessage}</FieldError>
    </AriaCheckboxGroup>
  )
}

const checkboxStyles = tv({
  base: "group flex items-center gap-2 text-sm transition",
  variants: {
    isDisabled: {
      false: "text-zinc-200",
      true: "text-zinc-600",
    },
  },
})

const boxStyles = tv({
  extend: focusRing,
  base: "flex h-5 w-5 flex-shrink-0 items-center justify-center rounded border-2 transition",
  variants: {
    isSelected: {
      false:
        "[--color:colors.zinc-400)] border-[--color] bg-zinc-900 group-pressed:[--color:theme(colors.zinc.300)]",
      true: "border-[--color] bg-[--color] [--color:theme(colors.slate.300)] group-pressed:[--color:theme(colors.slate.200)]",
    },
    isInvalid: {
      true: "[--color:theme(colors.red.600)] group-pressed:[--color:theme(colors.red.700)]",
    },
    isDisabled: {
      true: "[--color:theme(colors.zinc.700)]",
    },
  },
})

const iconStyles = "w-4 h-4 text-slate-900 group-disabled:text-slate-600"

export function Checkbox(props: AriaCheckboxProps) {
  return (
    <AriaCheckbox
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        checkboxStyles({ ...renderProps, className }),
      )}
    >
      {({ isSelected, isIndeterminate, ...renderProps }) => (
        <>
          <div className={boxStyles({ isSelected: isSelected || isIndeterminate, ...renderProps })}>
            {isIndeterminate ? (
              <MinusIcon aria-hidden className={iconStyles} />
            ) : isSelected ? (
              <CheckIcon aria-hidden className={iconStyles} />
            ) : null}
          </div>
          {props.children}
        </>
      )}
    </AriaCheckbox>
  )
}
