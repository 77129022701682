import { useSpring, animated, SpringRef, SpringValue } from "@react-spring/web"
import { useGesture, usePinch } from "@use-gesture/react"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { selectGridBounds, selectViewportOrigin } from "@/app/store/cellsSlice"
import { useAppSelector } from "../../../store"
import { Vec2 } from "@/packages/util/geometry"
import {
  GRID_HORIZONTAL_PADDING,
  GRID_MAX_SCALE,
  GRID_MIN_SCALE,
  GRID_STEP,
  GRID_VERTICAL_PADDING,
} from "./utils"
import { useEngine } from "../document/EngineContext"
import { FullscreenIcon, LandPlotIcon } from "lucide-react"
import { useTransientUiState } from "../../components/useUiState"
import { twJoin } from "tailwind-merge"
import ScrollViewBackground, { ScrollViewBackgroundHandle } from "./ScrollViewBackground"

type ContentTransform = {
  x: number
  y: number
  scale: number
  originX: number
  originY: number
}

type BoardUiState = {
  transform: ContentTransform | null
  contentInset: Vec2
  contentSize: Vec2
  scroll: Vec2
}

interface BoardScrollViewProps {
  onDoubleClick?: (position: Vec2) => void
  onLayoutUpdate?: (transform: ContentTransform | null) => void
  nativeScroll?: boolean
  enablePanning?: boolean
  children: React.ReactNode
}

export default function BoardScrollView({
  onDoubleClick,
  onLayoutUpdate,
  enablePanning = true,
  nativeScroll = true,
  children,
}: BoardScrollViewProps) {
  const engine = useEngine()

  const gridBounds = useAppSelector(selectGridBounds)
  const [viewportOriginLeft, viewportOriginTop] = useAppSelector(selectViewportOrigin)

  // TODO: either create a proper hook for this or skip hooks altogether
  const [uiState, setUiState] = useTransientUiState<BoardUiState>(engine.docId, {
    transform: null,
    scroll: [0, 0],
    contentInset: [0, 0],
    contentSize: [0, 0],
  })

  const scrollViewRef = useRef<HTMLDivElement>(null)
  const scrollContentRef = useRef<HTMLDivElement>(null)
  const gridContainerRef = useRef<HTMLDivElement>(null)
  const gestureCaptureRef = useRef<HTMLDivElement>(null)
  const backgroundRef = useRef<ScrollViewBackgroundHandle>(null)

  const [[contentInsetX, contentInsetY], setContentInset] = useState<Vec2>(uiState.contentInset)
  const [[contentWidth, contentHeight], setContentSize] = useState<Vec2>(uiState.contentSize)
  const [transform, setTransform] = useState<ContentTransform | null>(uiState.transform)

  const saveUiState = () => {
    setUiState({
      transform,
      contentInset: [contentInsetX, contentInsetY],
      contentSize: [contentWidth, contentHeight],
      scroll: [scrollViewRef.current?.scrollLeft ?? 0, scrollViewRef.current?.scrollTop ?? 0],
    })
  }

  useEffect(() => {
    saveUiState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transform, contentInsetX, contentInsetY, contentWidth, contentHeight])

  useLayoutEffect(() => {
    scrollViewRef.current?.scrollTo({
      left: uiState.scroll[0],
      top: uiState.scroll[1],
      behavior: "instant",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetTransform = () => {
    setTransform(null)
  }

  const [style, spring] = useSpring(
    () => ({
      x: transform?.x ?? 0,
      y: transform?.y ?? 0,
      scale: transform?.scale ?? 1,
      onChange: (result, ctrl) => {
        if (!backgroundRef.current || ctrl.idle) return

        const { x, y, scale } = result.value
        const [halfWidth, halfHeight] = [
          (gridBounds.right - gridBounds.left) / 2,
          (gridBounds.bottom - gridBounds.top) / 2,
        ]

        const dx = halfWidth - halfWidth * scale
        const dy = halfHeight - halfHeight * scale

        backgroundRef.current.setTransform({
          x: x + dx,
          y: y + dy,
          scale,
        })
      },
      onRest(result) {
        const { x, y, scale } = result.value
        setTransform({
          x,
          y,
          scale,
          originX: computeCenter(gridBounds.left, gridBounds.right),
          originY: computeCenter(gridBounds.top, gridBounds.bottom),
        })
      },
      config: {
        mass: 1,
        friction: 50,
        tension: 500,
      },
    }),
    [gridBounds, transform],
  )

  // Update the translation amount and transform origin when the grid bounds change
  // in order to keep the surface visually in the same place on screen when scaled
  useLayoutEffect(() => {
    if (!transform) return

    const [newOriginX, newOriginY] = [
      computeCenter(gridBounds.left, gridBounds.right),
      computeCenter(gridBounds.top, gridBounds.bottom),
    ]

    const [xDiff, yDiff] = [newOriginX - transform.originX, newOriginY - transform.originY]
    if (xDiff === 0 && yDiff === 0) return

    const translationX = xDiff - xDiff * transform.scale
    const translationY = yDiff - yDiff * transform.scale

    console.log("bounds update", xDiff, yDiff, translationX, translationY)

    const newTransform = {
      scale: transform.scale,
      x: transform.x - translationX,
      y: transform.y - translationY,
      originX: newOriginX,
      originY: newOriginY,
    }
    spring.set({ x: newTransform.x, y: newTransform.y })
    setTransform(newTransform)
  }, [gridBounds, transform, spring])

  // Update the layout when the grid bounds or transform change
  // This is a very brute force approach, should probably be refactored to use a transformation matrix
  useLayoutEffect(() => {
    console.log("update layout")

    const scrollView = scrollViewRef.current!
    const scrollSurface = scrollContentRef.current!

    const [currentScrollX, currentScrollY] = [scrollView.scrollLeft, scrollView.scrollTop]
    // Translate the scroll position into offsets relative to the previous canvas
    const [scrollOffsetX, scrollOffsetY] = [
      currentScrollX - contentInsetX,
      currentScrollY - contentInsetY,
    ]

    let relativeBounds = { ...gridBounds }
    relativeBounds.left -= viewportOriginLeft
    relativeBounds.right -= viewportOriginLeft
    relativeBounds.top -= viewportOriginTop
    relativeBounds.bottom -= viewportOriginTop

    // If we have a transform, we need to account for it when calculating the bounds
    // TODO: assumes the transform origin is the center
    if (transform) {
      const [halfWidth, halfHeight] = [
        (relativeBounds.right - relativeBounds.left) / 2,
        (relativeBounds.bottom - relativeBounds.top) / 2,
      ]

      const dx = halfWidth - halfWidth * transform.scale
      const dy = halfHeight - halfHeight * transform.scale

      relativeBounds = {
        left: relativeBounds.left + dx + transform.x,
        top: relativeBounds.top + dy + transform.y,
        right: relativeBounds.right - dx + transform.x,
        bottom: relativeBounds.bottom - dy + transform.y,
      }
    }

    // Only apply insets if we have negative bounds
    // Since we can't scroll into negative values we need to offset the surface
    let [newInsetX, newInsetY] = [
      -Math.min(relativeBounds.left, 0),
      -Math.min(relativeBounds.top, 0),
    ]

    if (gridBounds.bottom - gridBounds.top > 0) {
      newInsetY += GRID_VERTICAL_PADDING
    }

    const { width: scrollViewWidth, height: scrollViewHeight } = scrollView.getBoundingClientRect()

    let [newWidth, newHeight]: Vec2 = [
      Math.max(scrollViewWidth, relativeBounds.right),
      Math.max(scrollViewHeight, relativeBounds.bottom) + GRID_VERTICAL_PADDING,
    ]

    // Only add padding if the view is already scrollable, horizontal scrolling makes
    // vertical scrolling more awkward on trackpads so we'll avoid it if possible
    if (newInsetX > 0 || newWidth > scrollViewWidth) {
      newInsetX += GRID_HORIZONTAL_PADDING
      newWidth += GRID_HORIZONTAL_PADDING
    }

    // Keep the surface larger if the user previously scrolled past the new bounds
    newInsetX = Math.max(newInsetX, -scrollOffsetX)
    newInsetY = Math.max(newInsetY, -scrollOffsetY)
    newWidth = Math.max(newWidth, scrollViewWidth + scrollOffsetX)
    newHeight = Math.max(newHeight, scrollViewHeight + scrollOffsetY)

    setContentInset([newInsetX, newInsetY])
    setContentSize([newWidth, newHeight])

    // Manually set the new dimensions to make sure scrollTo works
    scrollSurface.style.width = newWidth + "px"
    scrollSurface.style.height = newHeight + "px"
    scrollSurface.style.paddingLeft = newInsetX + "px"
    scrollSurface.style.paddingTop = newInsetY + "px"

    // Scroll to a position that keeps the grid in the same place on screen
    const [newScrollX, newScrollY] = [newInsetX + scrollOffsetX, newInsetY + scrollOffsetY]
    scrollView.scrollTo({
      left: newScrollX,
      top: newScrollY,
      behavior: "instant",
    })

    // Update the background canvas
    if (gridContainerRef.current && backgroundRef.current) {
      const [halfWidth, halfHeight] = [
        (gridBounds.right - gridBounds.left) / 2,
        (gridBounds.bottom - gridBounds.top) / 2,
      ]

      const scale = transform?.scale ?? 1

      const dx = halfWidth - halfWidth * scale
      const dy = halfHeight - halfHeight * scale

      backgroundRef.current.setBounds(
        [-gridContainerRef.current.offsetLeft, -gridContainerRef.current.offsetTop],
        gridBounds,
        {
          x: (transform?.x ?? 0) + dx,
          y: (transform?.y ?? 0) + dy,
          scale: scale,
        },
      )
    }

    onLayoutUpdate?.(transform!)

    // levaSet({
    //   viewportSize: `${Math.round(scrollViewWidth)}x${Math.round(scrollViewHeight)}`,
    //   contentSize: `${Math.round(newWidth)}x${Math.round(newHeight)}`,
    //   contentInset: `${Math.round(newInsetX)}x${Math.round(newInsetY)}`,
    //   grid:
    //     `l:${Math.round(gridBounds.left)} t:${Math.round(gridBounds.top)} \n` +
    //     `r:${Math.round(gridBounds.right)} b:${Math.round(gridBounds.bottom)}`,
    //   relative:
    //     `l:${Math.round(relativeBounds.left)} t:${Math.round(relativeBounds.top)} \n` +
    //     `r:${Math.round(relativeBounds.right)} b:${Math.round(relativeBounds.bottom)}`,
    //   transform:
    //     `${Math.round(transform?.x ?? 0)},${Math.round(transform?.y ?? 0)} \n` +
    //     `s: ${transform?.scale ?? 1}`,
    // })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridBounds, transform, viewportOriginLeft, viewportOriginTop])

  // this flag should never change during the lifetime of the component
  if (enablePanning) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    usePanGesture(gestureCaptureRef, spring, style)
  }
  useZoomGesture(scrollContentRef, gridContainerRef, spring, style)

  const handleDoubleClick = ({
    clientX,
    clientY,
  }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!gridContainerRef.current) return
    const gridContainerBounds = gridContainerRef.current!.getBoundingClientRect()
    const [x, y] = [clientX - gridContainerBounds.x, clientY - gridContainerBounds.y]
    const scale = transform?.scale ?? 1

    const [gridX, gridY] = [
      Math.round((x / scale + gridBounds.left) / GRID_STEP) * GRID_STEP,
      Math.round((y / scale + gridBounds.top) / GRID_STEP) * GRID_STEP,
    ]

    onDoubleClick?.([gridX, gridY])
  }

  const updateViewportOrigin = () => {
    const scrollView = scrollViewRef.current!

    const [currentScrollX, currentScrollY] = [scrollView.scrollLeft, scrollView.scrollTop]
    const [originX, originY] = [
      currentScrollX - contentInsetX + viewportOriginLeft - (transform?.x ?? 0),
      currentScrollY - contentInsetY + viewportOriginTop - (transform?.y ?? 0),
    ]

    engine.stateManager.setViewportOrigin([originX, originY])

    console.log("origin", originX, originY)

    scrollView.scrollTo({
      left: 0,
      top: 0,
      behavior: "instant",
    })
    setContentInset([0, 0])
    resetTransform()
    spring.set({ x: 0, y: 0, scale: 1 })
  }

  return (
    <div className="relative h-full w-full overflow-hidden">
      <div
        ref={scrollViewRef}
        className={twJoin(
          "overflow-anchor-none h-full w-full overscroll-x-none",
          nativeScroll ? "overflow-auto" : "overflow-hidden",
        )}
        style={{
          // Hack to make safari render the scrollbars on top of the content
          transform: "translate3d(0,0,0)",
        }}
        onScroll={(e) => {
          saveUiState()
        }}
      >
        <div
          ref={scrollContentRef}
          className="relative box-content h-full w-full touch-pan-x touch-pan-y overflow-hidden"
          style={{
            width: contentWidth,
            height: contentHeight,
            paddingLeft: contentInsetX,
            paddingTop: contentInsetY,
          }}
        >
          <ScrollViewBackground ref={backgroundRef} className="absolute inset-0 touch-none" />
          <div
            ref={gestureCaptureRef}
            onDoubleClick={handleDoubleClick}
            className="absolute inset-0 touch-pan-x touch-pan-y"
          />
          <animated.div
            ref={gridContainerRef}
            className="pointer-events-none relative origin-center *:pointer-events-auto"
            style={{
              ...style,
              left: gridBounds.left - viewportOriginLeft,
              top: gridBounds.top - viewportOriginTop,
              width: gridBounds.right - gridBounds.left,
              height: gridBounds.bottom - gridBounds.top,
            }}
          >
            {children}
          </animated.div>
        </div>
      </div>
      <div className="absolute bottom-1 right-2 z-[900] flex h-8 text-gray-400">
        <button
          onClick={() => resetTransform()}
          className="h-8 w-8 rounded p-1 hover:bg-slate-900/20 hover:text-gray-200"
        >
          <FullscreenIcon strokeWidth={1} />
        </button>
        <button
          onClick={() => updateViewportOrigin()}
          className="h-8 w-8 rounded p-1 hover:bg-slate-900/20 hover:text-gray-200"
        >
          <LandPlotIcon strokeWidth={1} />
        </button>
      </div>
    </div>
  )
}

function useZoomGesture(
  eventTarget: React.RefObject<HTMLElement>,
  transformTarget: React.RefObject<HTMLElement>,
  api: SpringRef<{ x: number; y: number; scale: number }>,
  style: { x: SpringValue<number>; y: SpringValue<number>; scale: SpringValue<number> },
) {
  usePinch(
    ({ origin: [originX, originY], first, movement: [delta], offset: [scale], memo, target }) => {
      if (first) {
        const { width, height, x, y } = transformTarget.current!.getBoundingClientRect()
        const tx = originX - (x + width / 2)
        const ty = originY - (y + height / 2)
        memo = [style.x.get(), style.y.get(), tx, ty]
      }

      const x = memo[0] - (delta - 1) * memo[2]
      const y = memo[1] - (delta - 1) * memo[3]

      api.start({ scale: scale, x, y })
      return memo
    },
    {
      target: eventTarget,
      from: () => [style.scale.get(), style.scale.get()],
      scaleBounds: { min: GRID_MIN_SCALE, max: GRID_MAX_SCALE },
      rubberband: true,
      eventOptions: { passive: false, capture: true },
    },
  )

  // Double tap and drag to zoom gesture
  useEffect(() => {
    const element = eventTarget.current
    if (!element) return

    let lastTouchAt = 0
    let isZooming = false

    let startScale = 1
    let [originX, originY] = [0, 0]
    let memo: [number, number, number, number] = [0, 0, 0, 0]

    const handleTouchDown = (e: TouchEvent) => {
      if (e.touches.length !== 1) return

      const now = Date.now()
      if (
        now - lastTouchAt < 500 &&
        Math.hypot(e.touches[0].clientX - originX, e.touches[0].clientY - originY) < 100
      ) {
        lastTouchAt = 0
        isZooming = true
        originX = e.touches[0].clientX
        originY = e.touches[0].clientY
        startScale = style.scale.get()

        const { width, height, x, y } = transformTarget.current!.getBoundingClientRect()
        const tx = originX - (x + width / 2)
        const ty = originY - (y + height / 2)
        memo = [style.x.get(), style.y.get(), tx, ty]

        // Safari fix
        document.body.style.setProperty("-webkit-user-select", "none")
      } else {
        lastTouchAt = now
        originX = e.touches[0].clientX
        originY = e.touches[0].clientY
      }
    }

    const handleTouchUp = (e: TouchEvent) => {
      if (isZooming) {
        document.body.style.removeProperty("-webkit-user-select")
        isZooming = false
        e.preventDefault()
        e.stopPropagation()
      }
    }

    const handleTouchMove = (e: TouchEvent) => {
      if (!isZooming) return

      e.preventDefault()
      e.stopPropagation()

      const distance = originY - e.touches[0].clientY

      const maxDistance = 300
      const computedScale = startScale - Math.pow(distance / maxDistance, 2) * Math.sign(distance)

      const scale = Math.min(GRID_MAX_SCALE, Math.max(computedScale, GRID_MIN_SCALE))
      const delta = scale / startScale

      const x = memo[0] - (delta - 1) * memo[2]
      const y = memo[1] - (delta - 1) * memo[3]
      api.start({ scale, x, y })
    }

    element.addEventListener("touchstart", handleTouchDown)
    element.addEventListener("touchend", handleTouchUp)
    element.addEventListener("touchmove", handleTouchMove)

    return () => {
      element.removeEventListener("touchstart", handleTouchDown)
      element.removeEventListener("touchend", handleTouchUp)
      element.removeEventListener("touchmove", handleTouchMove)
    }
  }, [eventTarget, transformTarget, style, api])
}

function usePanGesture(
  target: React.RefObject<HTMLElement>,
  api: SpringRef<{ x: number; y: number; scale: number }>,
  style: { x: SpringValue<number>; y: SpringValue<number>; scale: SpringValue<number> },
) {
  useGesture(
    {
      onDragStart: ({ event }) => {
        // Safari fix
        target.current?.parentElement?.style.setProperty("-webkit-user-select", "none")
      },
      onDrag({ pinching, cancel, offset: [x, y], target: eventTarget, event }) {
        if (pinching) return cancel()
        api.start({ x, y })
      },
      onDragEnd: ({ event }) => {
        target.current?.parentElement?.style.removeProperty("-webkit-user-select")
      },
      onPinch() {
        // We need to add the handler to detect pinch in the drag gesture
      },
    },
    {
      target: target,
      drag: {
        from: () => [style.x.get(), style.y.get()],
        filterTaps: true,
      },
      pinch: {},
    },
  )
}

function computeCenter(start: number, end: number) {
  return start + (end - start) / 2
}
