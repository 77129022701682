import {
  ModalOverlay as AriaModalOverlay,
  ModalOverlayProps as AriaModalOverlayProps,
  Modal as AriaModal,
  composeRenderProps,
} from "react-aria-components"
import { tv } from "tailwind-variants"

const overlayStyles = tv({
  base: "fixed left-0 top-0 isolate z-20 flex h-[--visual-viewport-height] w-full items-center justify-center bg-black/[15%] p-4 text-center backdrop-blur-lg",
  variants: {
    isEntering: {
      true: "duration-200 ease-out animate-in fade-in",
    },
    isExiting: {
      true: "duration-200 ease-in animate-out fade-out",
    },
  },
})

const modalStyles = tv({
  base: "flex max-h-full w-full max-w-md flex-col overflow-hidden rounded-lg border border-white/10 bg-zinc-800/70 bg-clip-padding text-left align-middle text-zinc-300 shadow-2xl backdrop-blur-2xl backdrop-saturate-200",
  variants: {
    isEntering: {
      true: "duration-200 ease-out animate-in zoom-in-105",
    },
    isExiting: {
      true: "duration-200 ease-in animate-out zoom-out-95",
    },
  },
})

export function Modal(props: AriaModalOverlayProps) {
  return (
    <AriaModalOverlay
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        overlayStyles({ ...renderProps, className }),
      )}
    >
      <AriaModal {...props} className={modalStyles} />
    </AriaModalOverlay>
  )
}
