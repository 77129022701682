import { ReactDOMAttributes } from "@use-gesture/react/dist/declarations/src/types"
import CellHeader from "./../cells/CellHeader"
import { animated, useSpring } from "@react-spring/web"
import { CellId } from "@/engine/state/types"
import CellView from "./../cells/CellView"
import { useAppSelector } from "../../../store"
import { selectCellLayoutById } from "@/app/store/cellsSlice"
import { useGesture } from "@use-gesture/react"
import { useRef } from "react"
import { getScaledOffset, useResizeGestures } from "./utils"
import { useEngine } from "../document/EngineContext"
import { PlusCircleIcon } from "lucide-react"

interface EmbeddedCellFrameProps {
  cellId: CellId
  index: number
  moveGestures: (() => ReactDOMAttributes) | null
  connectGestures: (cellId: CellId) => ReactDOMAttributes
  onResize: (heightOffset: number) => void
}

export function EmbeddedCellFrame({
  cellId,
  index,
  moveGestures,
  connectGestures,
  onResize,
}: EmbeddedCellFrameProps) {
  const engine = useEngine()

  const cellLayout = useAppSelector((state) => selectCellLayoutById(state, cellId))

  const containerRef = useRef<HTMLDivElement>(null)

  const [heightSpringStyle, heightSpring] = useSpring(
    () => ({
      height: cellLayout.height,
      config: {
        mass: 1,
        friction: 50,
        tension: 500,
        clamp: true,
      },
      onRest(result, ctrl) {
        const { height } = result.value
        engine.stateManager.updateCellLayout(cellId, {
          ...cellLayout,
          height,
        })
      },
    }),
    [cellLayout],
  )

  const resizeGestures = useResizeGestures(containerRef, cellLayout, (args) => {
    const { height } = args
    heightSpring.start({ height })
    onResize(height! - cellLayout.height)
  })

  const [detachSpringStyle, detachSpring] = useSpring(() => ({
    x: 0,
    y: 0,
  }))

  const detachGestures = useGesture(
    {
      onDrag: ({ offset: [x, y] }) => {
        detachSpring.start({ x, y })
      },
      onDragEnd: ({ offset: [x, y] }) => {
        if (Math.abs(x) > 100 || Math.abs(y) > 100) {
          engine.stateManager.updateCellLayout(cellId, {
            ...cellLayout,
            type: "absolute",
            x: cellLayout.x + x,
            y: cellLayout.y + y,
          })
        } else {
          detachSpring.start({ x: 0, y: 0 })
        }
      },
    },
    {
      drag: {
        from: () => [0, 0],
        transform: (offset) => getScaledOffset(offset, containerRef),
      },
    },
  )

  return (
    <animated.div
      ref={containerRef}
      className={`flex flex-col ${index === 0 ? "" : "border-t border-zinc-800"}`}
      style={{
        ...heightSpringStyle,
        ...detachSpringStyle,
      }}
    >
      <CellHeader
        eventHandlers={moveGestures?.() || detachGestures()}
        cellId={cellId}
        className="bg-slate-800/30"
      />
      <div className="min-h-0 flex-1">
        <CellView cellId={cellId} />
      </div>
      <div
        {...resizeGestures(0, 1)}
        className="flex cursor-ns-resize touch-none items-center justify-end text-gray-400"
      >
        <div {...connectGestures(cellId)} className="cursor-crosshair">
          <PlusCircleIcon className="h-3 w-3 hover:text-blue-600" />
        </div>
      </div>
    </animated.div>
  )
}
