import { CircleAlertIcon, CircleArrowDown, MenuIcon, RefreshCcwIcon } from "lucide-react"
import { useRef, useState } from "react"
import { useServiceWorker } from "../ServiceWorkerContext"
import DocumentTree from "./DocumentTree"
import { useDrag } from "@use-gesture/react"
import { animated, useSpring } from "@react-spring/web"

export default function WorkspaceNavbarMobile() {
  const [collapsed, setCollapsed] = useState(true)

  const serviceWorker = useServiceWorker()

  const dragHandleRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  const menuWidth = 280

  const [style, spring] = useSpring(() => ({
    x: 0,
    onRest: (result) => {
      if (result.value.x === -menuWidth) {
        setCollapsed(true)
      } else if (result.value.x === 0) {
        setCollapsed(false)
      }
    },
    config: {
      duration: 200,
    },
  }))

  useDrag(
    ({ movement: [x], swipe: [swipeX], first, last, memo }) => {
      if (first) {
        setCollapsed(false)
        memo = -menuWidth
        spring.set({ x: memo })
      } else if (last) {
        if (x > menuWidth / 4 || swipeX == 1) {
          spring.start({ x: 0 })
        } else {
          spring.start({ x: memo })
        }
      } else {
        spring.start({ x: Math.max(Math.min(0, memo + x), memo) })
      }
      return memo
    },
    {
      target: dragHandleRef,
      pointer: { touch: true },
      preventDefault: true,
      eventOptions: { passive: false, capture: true },
      axis: "x",
    },
  )

  const hide = () => {
    spring.start({ x: -menuWidth })
  }

  const show = () => {
    setCollapsed(false)
    spring.set({ x: -menuWidth })
    spring.start({ x: 0 })
  }

  return (
    <div className="pointer-events-none absolute inset-0 flex *:pointer-events-auto">
      <div ref={dragHandleRef} className="z-1 absolute bottom-0 left-0 top-0 w-7" />
      <button
        className="absolute left-0 top-0 flex h-11 w-11 cursor-pointer items-center justify-center rounded-br rounded-tr active:bg-ui active:shadow-sm active:shadow-slate-950"
        onClick={() => show()}
      >
        <MenuIcon className="w-9 px-2" strokeWidth={1.5} />
      </button>

      {!collapsed && (
        <div className="z-2 absolute inset-0 bg-black bg-opacity-40" onClick={() => hide()} />
      )}

      {!collapsed && (
        <animated.div
          ref={menuRef}
          className="z-3 relative flex h-full flex-initial flex-col overflow-hidden border-r border-zinc-900 bg-ui pt-3"
          style={{ ...style, width: menuWidth, paddingBottom: "env(safe-area-inset-bottom)" }}
        >
          <DocumentTree
            onSelect={() => {
              hide()
            }}
          />
          <div className="flex h-6 w-full items-center justify-center gap-x-2">
            <div className="h-4 w-4 text-gray-400">
              {serviceWorker.updateStatus === "loading" && (
                <RefreshCcwIcon className="h-4 w-4 animate-spin" strokeWidth={1.5} />
              )}
              {serviceWorker.updateStatus === "outdated" && (
                <button
                  className="flex cursor-pointer items-center justify-center rounded active:bg-slate-950/20 hover:text-white"
                  onClick={() => serviceWorker.updateServiceWorker()}
                >
                  <CircleArrowDown className="h-4 w-4" strokeWidth={1.5} />
                </button>
              )}
              {serviceWorker.updateStatus === "current" && (
                <button
                  className="flex cursor-pointer items-center justify-center rounded active:bg-slate-950/20 hover:text-white"
                  onClick={() => serviceWorker.checkForUpdate()}
                >
                  <RefreshCcwIcon className="h-4 w-4" strokeWidth={1.5} />
                </button>
              )}
              {(serviceWorker.updateStatus === "error" ||
                serviceWorker.updateStatus === "unknown") && (
                <CircleAlertIcon className="h-4 w-4" strokeWidth={1.5} />
              )}
            </div>

            <div className="z-1 text-center text-xs text-gray-500">
              {serviceWorker.buildVersion}
            </div>
          </div>
        </animated.div>
      )}
    </div>
  )
}
