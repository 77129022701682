import { CellId } from "@/engine/state/types"
import { animated, useSpring } from "@react-spring/web"
import { selectGridBounds, selectCellLayoutById } from "@/app/store/cellsSlice"
import { useAppSelector } from "../../../store"
import { Box, Vec2 } from "@/packages/util/geometry"
import { Ref, forwardRef, useImperativeHandle, useRef } from "react"
import CellHeader from "./../cells/CellHeader"
import {
  getNextZIndex,
  useConnectGesture,
  useEvalShortcut,
  useMoveGesture,
  useResizeGestures,
} from "./utils"
import CellView from "./../cells/CellView"
import { useEngine } from "../document/EngineContext"
import { PlusCircleIcon } from "lucide-react"

interface CellFrameProps {
  cellId: CellId
  onLayoutUpdating: (layout: Box, dragging: boolean) => void
  onNewConnection: (cellId: CellId, to: Vec2, dragging: boolean) => void
}

export type CellFrameHandle = unknown

export default forwardRef(function CellFrame(
  { cellId, onLayoutUpdating, onNewConnection }: CellFrameProps,
  ref: Ref<CellFrameHandle>,
) {
  const engine = useEngine()

  const cellLayout = useAppSelector((state) => selectCellLayoutById(state, cellId))

  const gridBounds = useAppSelector(selectGridBounds)
  const containerRef = useRef<HTMLDivElement>(null)

  useEvalShortcut(containerRef, cellId, engine)

  const [springStyle, spring] = useSpring(
    () => ({
      x: 0,
      y: 0,
      width: cellLayout.width,
      height: cellLayout.height,
      config: {
        mass: 1,
        friction: 50,
        tension: 500,
        clamp: true,
      },
      immediate: true,
      onChange(result) {
        const { x: dx, y: dy, width, height } = result.value
        onLayoutUpdating?.({ x: cellLayout.x + dx, y: cellLayout.y + dy, width, height }, true)
      },
      onRest(result, ctrl) {},
    }),
    [cellLayout],
  )

  useImperativeHandle(ref, () => ({}))

  const moveGestures = useMoveGesture(containerRef, cellLayout, false, ([x, y], dragging) => {
    if (dragging) {
      spring.start({ x, y })
    } else {
      onLayoutUpdating({ x, y, width: cellLayout.width, height: cellLayout.height }, false)
      // TODO
      // const position: Vec2 = [cellLayout.x + x + cellLayout.width / 2, cellLayout.y + y]
      const hit = false // findIntersectingCell(position, store.getState().cells.layouts, cellId)
      if (hit) {
        spring.stop(true)
        engine.stateManager.updateCellLayout(cellId, {
          ...cellLayout,
          type: "stack",
          stackId: hit,
          x: cellLayout.x + x,
          y: cellLayout.y + y,
        })
      } else {
        spring.stop(true)
        engine.stateManager.updateCellLayout(cellId, {
          ...cellLayout,
          x: cellLayout.x + x,
          y: cellLayout.y + y,
        })
      }
    }
  })
  const resizeGestures = useResizeGestures(containerRef, cellLayout, (args, down) => {
    if (down) {
      spring.start(args)
    } else {
      const { x: dx = 0, y: dy = 0, width, height } = args
      const box = {
        x: cellLayout.x + dx,
        y: cellLayout.y + dy,
        width: width ?? cellLayout.width,
        height: height ?? cellLayout.height,
      }
      onLayoutUpdating?.(box, false)
      spring.stop(true)
      engine.stateManager.updateCellLayout(cellId, {
        ...cellLayout,
        ...box,
      })
    }
  })
  const connectGestures = useConnectGesture(containerRef, cellLayout, onNewConnection)

  return (
    <animated.div
      ref={containerRef}
      onMouseDown={() => {
        if (containerRef.current) {
          containerRef.current.style.zIndex = getNextZIndex(containerRef.current.style.zIndex)
        }
      }}
      className="cell z-10 grid grid-cols-[4px_minmax(0,_1fr)_4px] grid-rows-[auto_minmax(0,_1fr)_16px]"
      style={{
        position: "absolute",
        left: cellLayout.x - gridBounds.left,
        top: cellLayout.y - gridBounds.top,
        ...springStyle,
      }}
    >
      <div
        {...resizeGestures(-1, -1)}
        className="cursor-nwse-resize touch-none bg-slate-800/30"
      ></div>
      <CellHeader eventHandlers={moveGestures()} cellId={cellId} className="h-8 bg-slate-800/30" />
      <div
        {...resizeGestures(1, -1)}
        className="cursor-nesw-resize touch-none bg-slate-800/30"
      ></div>
      <div {...resizeGestures(-1, 0)} className="cursor-ew-resize touch-none"></div>
      <div className="">
        <CellView cellId={cellId} />
      </div>
      <div {...resizeGestures(1, 0)} className="cursor-ew-resize touch-none"></div>
      <div {...resizeGestures(-1, 1)} className="cursor-nesw-resize touch-none"></div>
      <div
        {...resizeGestures(0, 1)}
        className="flex cursor-ns-resize touch-none items-center justify-end text-gray-400"
      >
        <div {...connectGestures(cellId)} className="cursor-crosshair">
          <PlusCircleIcon className="h-3 w-3 hover:text-blue-600" />
        </div>
      </div>
      <div {...resizeGestures(1, 1)} className="cursor-nwse-resize touch-none"></div>
    </animated.div>
  )
})
