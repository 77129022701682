export type Vec2 = [number, number]

export type Rect = {
  top: number
  left: number
  right: number
  bottom: number
}

export type Box = {
  x: number
  y: number
  width: number
  height: number
}

export type RectSide = "top" | "right" | "bottom" | "left"

export function isPointInBox([x, y]: Vec2, box: Box): boolean {
  return x > box.x && x < box.x + box.width && y > box.y && y < box.y + box.height
}

export function distanceBetween([p1x, p1y]: Vec2, [p2x, p2y]: Vec2): number {
  return Math.sqrt(Math.pow(p1x - p2x, 2) + Math.pow(p1y - p2y, 2))
}
