import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import { CellInfo, CellId, CellLayout } from "../../engine/state/types"
import { RootState } from "."
import { Rect } from "@/packages/util/geometry"
import { assertUnreachable } from "@/packages/util/assert"

export type CellStack = {
  id: string
  cells: CellId[]
}

export type GridBounds = Rect

export type CellInputs = CellId[]

export interface CellsStoreState {
  loaded: boolean
  cells: { [id: CellId]: CellInfo }
  options: { [id: CellId]: any }
  inputs: { [id: CellId]: CellInputs }
  layouts: { [id: CellId]: CellLayout }
  stacks: { [id: string]: CellStack }
  gridBounds: GridBounds
  viewportOrigin: [number, number]
}

const initialState: CellsStoreState = {
  loaded: false,
  cells: {},
  options: {},
  inputs: {},
  layouts: {},
  stacks: {},
  gridBounds: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  viewportOrigin: [0, 0],
}

export const cellsSlice = createSlice({
  name: "cells",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setCellState: (_, action: PayloadAction<Omit<CellsStoreState, "gridBounds" | "stacks">>) => {
      return {
        ...action.payload,
        stacks: {},
        gridBounds: computeGridBounds(Object.values(action.payload.layouts)),
      }
    },
    addCell: (
      state,
      action: PayloadAction<{
        cell: CellInfo
        options: any
        layout: CellLayout
        connections: CellId[]
      }>,
    ) => {
      state.cells[action.payload.cell.id] = action.payload.cell
      state.options[action.payload.cell.id] = action.payload.options
      state.layouts[action.payload.cell.id] = action.payload.layout
      state.inputs[action.payload.cell.id] = action.payload.connections

      state.gridBounds = computeGridBounds(Object.values(state.layouts))
    },
    removeCell: (state, action: PayloadAction<CellId>) => {
      // const cellLayout = state.layouts[action.payload]
      delete state.cells[action.payload]
      delete state.layouts[action.payload]
      delete state.inputs[action.payload]
      delete state.options[action.payload]

      Object.entries(state.inputs).forEach(([id, connections]) => {
        state.inputs[id] = connections.filter((connection) => connection !== action.payload)
      })

      // if (cellLayout.type === "stack") {
      //   const stack = state.stacks[cellLayout.stackId]
      //   stack.cells = stack.cells.filter((id) => id !== action.payload)
      //   if (stack.cells.length === 1) {
      //     delete state.stacks[cellLayout.stackId]
      //     state.layouts[stack.cells[0]] = {
      //       ...state.layouts[stack.cells[0]],
      //       type: "absolute",
      //     }
      //   } else {
      //     arrangeStackCells(stack, state.layouts)
      //     setupStackConnections(stack, state.inputs)
      //   }
      // }

      state.gridBounds = computeGridBounds(Object.values(state.layouts))
    },
    setCellInfo: (state, action: PayloadAction<{ id: CellId } & Partial<CellInfo>>) => {
      const cell = state.cells[action.payload.id]
      state.cells[action.payload.id] = {
        ...cell,
        ...action.payload,
      }
    },
    setCellInputs: (state, action: PayloadAction<{ id: CellId; inputs: CellInputs }>) => {
      state.inputs[action.payload.id] = action.payload.inputs
    },
    setCellOptions: (
      state,
      action: PayloadAction<{ id: CellId; options: Record<string, any> }>,
    ) => {
      state.options[action.payload.id] = action.payload.options
    },
    setCellLayout: (state, action: PayloadAction<{ id: CellId } & CellLayout>) => {
      const args = action.payload

      switch (args.type) {
        case "archived": {
          state.layouts[args.id] = {
            type: "archived",
            archivedAt: args.archivedAt,
            x: args.x,
            y: args.y,
            width: args.width,
            height: args.height,
          }
          break
        }
        case "absolute": {
          state.layouts[args.id] = {
            type: "absolute",
            x: args.x,
            y: args.y,
            width: args.width,
            height: args.height,
          }
          break
        }
        case "stack": {
          state.layouts[args.id] = {
            type: "stack",
            stackId: args.stackId,
            x: args.x,
            y: args.y,
            width: args.width,
            height: args.height,
          }
          break
        }
        default:
          assertUnreachable(args)
      }

      // const cellLayout = state.layouts[args.id]
      // if (args.type === "absolute") {
      //   if (cellLayout.type === "stack") {
      //     const stack = state.stacks[cellLayout.stackId]
      //     stack.cells = stack.cells.filter((id) => id !== args.id)
      //     if (stack.cells.length === 1) {
      //       delete state.stacks[cellLayout.stackId]
      //       state.layouts[stack.cells[0]] = {
      //         ...state.layouts[stack.cells[0]],
      //         type: "absolute",
      //       }
      //     } else {
      //       arrangeStackCells(stack, state.layouts)
      //       setupStackConnections(stack, state.inputs)
      //     }
      //   }
      //   state.layouts[args.id] = {
      //     type: "absolute",
      //     x: args.x,
      //     y: args.y,
      //     width: args.width,
      //     height: args.height,
      //   }
      // } else if (args.type === "stack") {
      //   let stackId = args.stackId
      //   let stack = state.stacks[stackId]
      //   if (stack === undefined) {
      //     const targetId = stackId
      //     const targetCellLayout = state.layouts[targetId]
      //     if (targetCellLayout.type === "stack") {
      //       stackId = targetCellLayout.stackId
      //       stack = state.stacks[stackId]
      //     } else {
      //       stackId = nanoid()
      //       state.layouts[targetId] = {
      //         ...targetCellLayout,
      //         type: "stack",
      //         stackId: stackId,
      //       }
      //       stack = {
      //         id: stackId,
      //         cells: [targetId],
      //       }
      //       state.stacks[stackId] = stack
      //     }
      //   }

      //   if (cellLayout.type === "absolute") {
      //     let index = stack.cells.length
      //     for (let i = 0; i < stack.cells.length; i++) {
      //       const stackCellLayout = state.layouts[stack.cells[i]]
      //       if (stackCellLayout.y < args.y) {
      //         index = i + 1
      //       } else {
      //         break
      //       }
      //     }

      //     stack.cells.splice(index, 0, args.id)
      //   }

      //   state.layouts[args.id] = {
      //     type: "stack",
      //     stackId: stackId,
      //     x: args.x,
      //     y: args.y,
      //     width: args.width,
      //     height: args.height,
      //   }

      //   arrangeStackCells(stack, state.layouts)
      //   setupStackConnections(stack, state.inputs)
      // }

      state.gridBounds = computeGridBounds(Object.values(state.layouts))

      // state.gridBounds = {
      //   left: Math.min(action.payload.x, state.gridBounds.left),
      //   top: Math.min(action.payload.y, state.gridBounds.top),
      //   right: Math.max(action.payload.x + action.payload.width, state.gridBounds.right),
      //   bottom: Math.max(action.payload.y + action.payload.height, state.gridBounds.bottom),
      // }
    },
    setViewportOrigin: (state, action: PayloadAction<[number, number]>) => {
      state.viewportOrigin = action.payload
    },
  },
})

export function arrangeStackCells(stack: CellStack, layouts: { [id: string]: CellLayout }) {
  const anchorCellId = stack.cells[0]
  const anchorCellLayout = layouts[anchorCellId]

  const xStart = anchorCellLayout.x
  let yStart = anchorCellLayout.y + anchorCellLayout.height

  for (let i = 1; i < stack.cells.length; i++) {
    const stackCellLayout = layouts[stack.cells[i]]
    stackCellLayout.x = xStart
    stackCellLayout.y = yStart
    stackCellLayout.width = anchorCellLayout.width
    yStart += stackCellLayout.height
  }
}

export function setupStackConnections(stack: CellStack, connections: { [id: string]: CellInputs }) {
  const anchorCellId = stack.cells[0]
  connections[anchorCellId] = connections[anchorCellId].filter((c) => !stack.cells.includes(c))
  for (let i = 1; i < stack.cells.length; i++) {
    const from = stack.cells[i - 1]
    const to = stack.cells[i]
    connections[to] = [...connections[to].filter((c) => !stack.cells.includes(c)), from]
  }
}

export function computeGridBounds(layouts: CellLayout[]) {
  const initialBounds = { left: Infinity, top: Infinity, right: -Infinity, bottom: -Infinity }

  const computedBounds = layouts.reduce((acc, cellLayout) => {
    if (cellLayout.type === "archived") return acc

    return {
      left: Math.min(cellLayout.x, acc.left),
      top: Math.min(cellLayout.y, acc.top),
      right: Math.max(cellLayout.x + cellLayout.width, acc.right),
      bottom: Math.max(cellLayout.y + cellLayout.height, acc.bottom),
    }
  }, initialBounds)

  if (computedBounds === initialBounds) {
    return { left: 0, top: 0, right: 0, bottom: 0 }
  } else {
    return computedBounds
  }
}

// export const selectCells = createSelector([(state: RootState) => state.cells.cells], (cells) =>
//   Object.values(cells),
// )

export const selectCellsLoaded = (state: RootState) => state.cells.loaded

export const selectCells = (state: RootState) => state.cells.cells

export const selectCellById = createSelector(
  [(state: RootState) => state.cells.cells, (_: RootState, id: CellId) => id],
  (cell, id) => cell[id],
)

export const selectCellConnections = (state: RootState) => state.cells.inputs

export const selectCellConnectionsById = createSelector(
  [(state: RootState) => state.cells.inputs, (_: unknown, id: CellId) => id],
  (cellConnections, id) => cellConnections[id],
)

export const selectConnectedCellsById = createSelector(
  [
    (state: RootState) => state.cells.inputs,
    (state: RootState) => state.cells.cells,
    (_: unknown, id: CellId) => id,
  ],
  (cellConnections, cells, id) => ({
    input: cellConnections[id].map((id) => cells[id]),
    output: Object.entries(cellConnections)
      .filter(([_, ids]) => ids.includes(id))
      .map(([id, _]) => cells[id]),
  }),
)

export const selectCellLayouts = (state: RootState) => state.cells.layouts

export const selectCellLayoutById = createSelector(
  [(state: RootState) => state.cells.layouts, (_: RootState, id: CellId) => id],
  (cellLayouts, id) => cellLayouts[id],
)

export const selectCellOptionsById = createSelector(
  [(state: RootState) => state.cells.options, (_: RootState, id: CellId) => id],
  (cellOptions, id) => cellOptions[id],
)

export const selectCellStacks = (state: RootState) => state.cells.stacks

export const selectCellStackById = createSelector(
  [(state: RootState) => state.cells.stacks, (_: RootState, id: string) => id],
  (cellStacks, id) => cellStacks[id],
)

export const selectGridBounds = (state: RootState) => state.cells.gridBounds

export const selectViewportOrigin = (state: RootState) => state.cells.viewportOrigin
