import { OutputPanel } from "../output/OutputPanel"
import { useEffect, useState } from "react"
import {
  ArchiveIcon,
  ChevronDownIcon,
  SearchIcon,
  SettingsIcon,
  TerminalSquareIcon,
} from "lucide-react"
import { useEngine } from "./EngineContext"
import { ArchivePanel } from "./ArchivePanel"
import { ModalOverlay, Modal, Dialog } from "react-aria-components"
import { Menu, MenuItem, MenuTrigger } from "../../components/Menu"
import { queryClient } from "@/app/supabase"
import { useNavigate } from "@tanstack/react-router"
import { documentRoute } from "../../routes"
import { IconButton } from "../../components/Button"
import { useCommandPalette } from "../CommandPalette"

type Panel = "output" | "archive"

const panelTitles: Record<Panel, string> = {
  output: "Cell Output",
  archive: "Archive",
}

export default function ToolbarMobile() {
  const engine = useEngine()
  const [openPanel, setOpenPanel] = useState<Panel | null>(null)

  const commandPalette = useCommandPalette()

  const panelTitle = openPanel ? panelTitles[openPanel] : ""

  const { slug } = documentRoute.useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = engine.on("evalStart", ({ runAction }) => {
      if (runAction !== "fill") setOpenPanel("output")
    })
    return () => {
      unsubscribe()
    }
  }, [engine])

  useEffect(() => {
    if (!openPanel) return

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpenPanel(null)
        e.preventDefault()
        e.stopImmediatePropagation()
      }
    }
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [openPanel])

  const togglePanel = (panel: Panel) => {
    if (openPanel === panel) {
      setOpenPanel(null)
    } else {
      setOpenPanel(panel)
    }
  }

  const optimizeDocument = async () => {
    console.log("optimize document")
    const newDocId = await engine.stateManager.ySync.optimize()
    console.log("new doc id", newDocId)

    queryClient.invalidateQueries({ queryKey: ["document"] })
  }

  const editDocument = () => {
    navigate({ to: "/g/$slug/edit", params: { slug } })
  }

  return (
    <div className="absolute bottom-2 left-2 flex w-full flex-row">
      <ModalOverlay
        isOpen={openPanel !== null}
        onOpenChange={(isOpen) => !isOpen && setOpenPanel(null)}
        className="fixed bottom-[calc(theme(spacing.6)+env(safe-area-inset-bottom))] left-0 right-0 top-0 z-[1001] bg-black/60"
        isDismissable
      >
        <Modal className="absolute bottom-0 h-[90dvh] w-full rounded-t-xl bg-ui shadow-lg will-change-transform">
          <Dialog className="flex h-full flex-col outline-none" aria-label={panelTitle || "panel"}>
            <div className="flex h-11 flex-initial items-center px-4">
              <button
                onClick={() => setOpenPanel(null)}
                className="mr-2 flex h-11 items-center justify-center rounded-full hover:bg-zinc-800"
              >
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" strokeWidth={1.5} />
              </button>
              <span className="text-base font-semibold leading-7 text-white">{panelTitle}</span>
            </div>
            <div className="flex-1 overflow-hidden">
              {openPanel === "output" && <OutputPanel onClose={() => setOpenPanel(null)} />}
              {openPanel === "archive" && <ArchivePanel onClose={() => setOpenPanel(null)} />}
            </div>
          </Dialog>
        </Modal>
      </ModalOverlay>
      <div className="flex h-10 flex-row items-center gap-2">
        <IconButton
          onPress={() => togglePanel("output")}
          className="h-8 w-8 rounded-full bg-zinc-600 p-1 shadow-sm hover:bg-zinc-900"
        >
          <TerminalSquareIcon className="m-auto h-5 w-5" strokeWidth={1.5} />
        </IconButton>
        <IconButton
          onPress={() => togglePanel("archive")}
          className="h-8 w-8 rounded-full bg-zinc-600 p-1 shadow-sm hover:bg-zinc-900"
        >
          <ArchiveIcon className="m-auto h-5 w-5" strokeWidth={1.5} />
        </IconButton>
        <MenuTrigger>
          <IconButton className="h-8 w-8 rounded-full bg-zinc-600 p-1 shadow-sm hover:bg-zinc-900">
            <SettingsIcon className="m-auto h-5 w-5" strokeWidth={1.5} />
          </IconButton>
          <Menu placement="top left">
            <MenuItem textValue="Optimize Document" onAction={optimizeDocument}>
              Optimize Document
            </MenuItem>
            <MenuItem textValue="Edit Document" onAction={editDocument}>
              Edit Document
            </MenuItem>
          </Menu>
        </MenuTrigger>
        <IconButton
          onPress={() => commandPalette.show()}
          className="h-8 w-8 rounded-full bg-zinc-600 p-1 shadow-sm hover:bg-zinc-900"
        >
          <SearchIcon className="m-auto h-5 w-5" strokeWidth={1.5} />
        </IconButton>
      </div>
    </div>
  )
}
