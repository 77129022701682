import * as Y from "yjs"

declare module "yjs" {
  interface Array<T> {
    toJSON(): T[]
  }
}

export type StrictYMap<Spec> = Omit<Y.Map<any>, "get" | "set"> & {
  get<K extends keyof Spec>(key: K): Spec[K]
  set<K extends keyof Spec>(key: K, value: Spec[K]): void
}

export function newYMap<Keys extends object>(args: Keys): StrictYMap<Keys> {
  const entries = Object.entries(args)
  return new Y.Map<Keys>(entries) as StrictYMap<Keys>
}

type FilterKeys<Keys, T> = {
  [K in keyof Keys as Keys[K] extends T ? K : never]: Keys[K]
}

export type StrictYDoc<Keys> = Omit<
  Y.Doc,
  "getMap" | "getArray" | "getText" | "getXmlElement" | "getXmlFragment"
> & {
  getMap<K extends keyof FilterKeys<Keys, Y.Map<any>>>(key: K): Keys[K]
  getArray<K extends keyof FilterKeys<Keys, Y.Array<any>>>(key: K): Keys[K]
  getText<K extends keyof FilterKeys<Keys, Y.Text>>(key: K): Keys[K]
  getXmlElement<K extends keyof FilterKeys<Keys, Y.XmlElement>>(key: K): Keys[K]
  getXmlFragment<K extends keyof FilterKeys<Keys, Y.XmlFragment>>(key: K): Keys[K]
}

// export type ConvertibleKeys<T> = {
//   [K in keyof T]: T[K] extends Y.Map<infer P> | undefined
//     ? Record<string, P>
//     : T[K] extends Y.Array<infer P> | undefined
//       ? P[]
//       : T[K] extends Y.AbstractType<infer P> | undefined
//         ? P
//         : T[K]
// }
