import { JspreadsheetInstance } from "./jspreadsheet"
import { CellValue, JSpreadsheetOptions } from "./types"
import "./jspreadsheet.css"
import "./jspreadsheet.theme.css"

export type { JSpreadsheetOptions, JspreadsheetInstance }

export default function (
  el: HTMLDivElement,
  data: CellValue[][],
  options: Partial<JSpreadsheetOptions>,
) {
  return new JspreadsheetInstance(el, data, options)
}
