import prettier from "prettier/standalone"
import prettierTypescript from "prettier/plugins/typescript"
import prettierEstree from "prettier/plugins/estree"
import prettierBabel from "prettier/plugins/babel"
import fastDiff from "fast-diff"
import { StateCommand } from "@codemirror/state"
import { ySyncFacet } from "@/packages/y-codemirror"
import { assertUnreachable } from "../../util/assert"

const formatOptions = {
  typescript: {
    parser: "typescript",
    plugins: [prettierTypescript, prettierEstree],
    semi: false,
    printWidth: 60,
  },
  json: {
    parser: "json",
    plugins: [prettierEstree, prettierBabel],
  },
}

export function getCodeFormater(lang: "typescript" | "json"): StateCommand {
  return ({ state }) => {
    async function format() {
      // const { from, to } = state.selection
      const text = state.doc.toString()
      const formattedText = await prettier.format(text, formatOptions[lang])

      const ySyncConfig = state.facet(ySyncFacet)
      const textDiff = fastDiff(text, formattedText)
      const deltas = textDiff.map(([op, str]) => {
        switch (op) {
          case fastDiff.INSERT:
            return { insert: str }
          case fastDiff.DELETE:
            return { delete: str.length }
          case fastDiff.EQUAL:
            return { retain: str.length }
        }
        return assertUnreachable(op)
      })
      ySyncConfig.ytext.doc?.transact(() => {
        ySyncConfig.ytext.applyDelta(deltas)
      })
    }

    format().catch((e) => {
      console.error(e)
    })
    return true
  }
}
