import * as Y from "yjs"
import { WebrtcProvider } from "y-webrtc"
import { IndexeddbPersistence } from "y-indexeddb"
import { SupabasePersistance } from "./remote"
import { SupabaseClient } from "@supabase/supabase-js"
import { Database } from "@/app/supabase/database.types"
import { BaseEventObservable } from "@/packages/emitter"
import { decodeFromBase64, Encoder, encodeToBase64 } from "@/packages/util/encoding"

export type WebrtcStatus = {
  connected: boolean
}

export type RemoteStatus = {
  connected: boolean
  isSyncing: boolean
  hasPendingUpdates: boolean
}

export type SyncEvents = {
  rtcStatus: WebrtcStatus
  remoteStatus: RemoteStatus
}

export class YSync extends BaseEventObservable<SyncEvents> {
  readonly yDoc: Y.Doc

  private readonly remotePersistence: SupabasePersistance
  private readonly localPersistence: IndexeddbPersistence
  private rtcProvider: WebrtcProvider | null = null

  private isDestroyed = false

  constructor(
    yDoc: Y.Doc,
    p2pKey: string,
    supabase: SupabaseClient<Database>,
    encoder: Encoder | null = null,
  ) {
    super()

    this.yDoc = yDoc
    this.localPersistence = new IndexeddbPersistence(yDoc.guid, yDoc)
    this.remotePersistence = new SupabasePersistance(yDoc, supabase, encoder)
    this.rtcProvider = new WebrtcProvider(yDoc.guid, yDoc, {
      signaling: ["wss://y-webrtc-server.fly.dev"],
      password: p2pKey,
    })

    this.rtcProvider.on("status", (status) => {
      this.emitter.emit("rtcStatus", status)
    })
    this.rtcProvider.connect()

    this.remotePersistence.on("statusUpdated", () => {
      this.emitter.emit("remoteStatus", {
        connected: this.remotePersistence.isSynced,
        isSyncing: this.remotePersistence.isWorking,
        hasPendingUpdates: this.remotePersistence.hasPendingUpdates,
      })
    })

    this.localPersistence.whenSynced.then(() => {
      this.remotePersistence.connect()
    })
  }

  get rtcStatus(): WebrtcStatus {
    return { connected: this.rtcProvider?.connected ?? false }
  }

  get remoteStatus(): RemoteStatus {
    return {
      connected: this.remotePersistence.isSynced,
      isSyncing: this.remotePersistence.isWorking,
      hasPendingUpdates: this.remotePersistence.hasPendingUpdates,
    }
  }

  get isLocallySynced() {
    return this.localPersistence.synced
  }

  async whenLocallySynced() {
    await this.localPersistence.whenSynced
  }

  async whenRemoteSynced() {
    await this.remotePersistence.whenSynced()
  }

  async whenRemoteSaved() {
    await this.remotePersistence.whenSaved()
  }

  saveToRemote() {
    this.remotePersistence.flush()
  }

  syncRemote() {
    this.remotePersistence.sync()
  }

  optimize() {
    this.remotePersistence.optimize()
  }

  destroy() {
    if (this.isDestroyed) return
    this.isDestroyed = true

    this.localPersistence.destroy()
    this.rtcProvider?.disconnect()
    this.rtcProvider?.destroy()
    this.remotePersistence.destroy()
  }
}
