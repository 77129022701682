import { selectCellLayouts, selectCells, selectCellsLoaded } from "@/app/store/cellsSlice"
import BoardConnections, { BoardConnectionsHandle } from "./BoardConnections"
import BoardScrollView from "./ScrollView"
import { useAppSelector } from "../../../store"
import { memo, useRef, useState } from "react"
import { Box, Vec2 } from "@/packages/util/geometry"
import { useEngine } from "../document/EngineContext"
import PreviewCellFrame from "./PreviewCellFrame"
import { CellId } from "@/engine/state/types"

export default memo(function PreviewBoard() {
  const engine = useEngine()

  const cellsLoaded = useAppSelector(selectCellsLoaded)
  const cells = useAppSelector(selectCells)
  const cellLayouts = useAppSelector(selectCellLayouts)

  const [showCellContent, setShowCellContent] = useState(true)

  const boardConnectionsRef = useRef<BoardConnectionsHandle>(null)

  function handleCellLayoutUpdate(cellId: CellId, box: Box, dragging: boolean) {
    if (dragging) {
      boardConnectionsRef.current?.setCelLayoutOverride({ [cellId]: box })
    } else {
      boardConnectionsRef.current?.reset()
    }
  }

  function createCell([x, y]: Vec2) {
    engine.stateManager.createEmptyCell({
      type: "absolute",
      x: x - 200,
      y: y,
      width: 400,
      height: 200,
    })
  }

  if (!cellsLoaded) {
    return (
      <div className="blueprint-background h-full w-full animate-pulse pt-2 text-center text-gray-600">
        loading...
      </div>
    )
  }

  return (
    <BoardScrollView
      onDoubleClick={createCell}
      enablePanning={false}
      onLayoutUpdate={(transform) => setShowCellContent((transform?.scale ?? 1) > 0.4)}
    >
      <BoardConnections ref={boardConnectionsRef} />
      {Object.values(cells)
        .filter((cell) => cellLayouts[cell.id].type === "absolute")
        .map((cell) => (
          <PreviewCellFrame
            key={cell.id}
            cellId={cell.id}
            showContent={showCellContent}
            onLayoutUpdating={(box, dragging) => handleCellLayoutUpdate(cell.id, box, dragging)}
          />
        ))}
    </BoardScrollView>
  )
})
