import { Callable } from "./types"

export type Debounced<T extends Callable<any[], void>> = ((...args: Parameters<T>) => void) & {
  clear: () => void
  flush: () => void
}

export function debounce<T extends Callable<any[], void>>(fn: T, delay = 100): Debounced<T> {
  if (delay < 0) {
    throw new RangeError("`delay` must not be negative.")
  }

  let lastContext: any
  let lastArgs: Parameters<T> | undefined

  let timeoutId: ReturnType<typeof setTimeout> | undefined

  function invoke() {
    timeoutId = undefined
    const context = lastContext
    const args = lastArgs!
    fn.apply(context, args)
  }

  const debounced: Debounced<T> = Object.assign(
    function (this: any, ...args: Parameters<T>) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      lastContext = this
      lastArgs = args

      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(invoke, delay)
    },
    {
      clear: () => {
        if (!timeoutId) {
          return
        }

        clearTimeout(timeoutId)
        timeoutId = undefined
      },
      flush: () => {
        if (!timeoutId) {
          return
        }
        clearTimeout(timeoutId)
        invoke()
      },
    },
  )

  return debounced
}
