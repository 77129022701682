import { createClient } from "@supabase/supabase-js"
import { Database } from "./database.types"
import { useMemo } from "react"
import { QueryClient, queryOptions } from "@tanstack/react-query"
import { encodeToBase64 } from "@/packages/util/encoding"
import { AesEncoder, derivePassphraseKey } from "@/packages/util/crypto"
import { customAlphabet } from "nanoid"

export const supabase = createClient<Database>(
  "https://runqppqacnnqclthwvdd.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ1bnFwcHFhY25ucWNsdGh3dmRkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDUzMzE0NDcsImV4cCI6MjAyMDkwNzQ0N30.Pzh3v7sVl1yA6jHNh3AdBiY8dSMI65LI13rl4EaWnvI",
)

export const queryClient = new QueryClient()

export function useSupabase() {
  return useMemo(() => supabase, [])
}

export const getDocumentsQuery = () =>
  queryOptions({
    queryKey: ["documents"],
    queryFn: async () =>
      (
        await supabase
          .from("document")
          .select("*")
          .eq("archived", false)
          .order("created_at")
          .throwOnError()
      ).data,
  })

export const getDocumentBySlugQuery = (slug: string) =>
  queryOptions({
    queryKey: ["document", slug],
    queryFn: async () =>
      (await supabase.from("document").select().eq("slug", slug).single().throwOnError()).data,
  })

export const getFoldersQuery = () =>
  queryOptions({
    queryKey: ["folders"],
    queryFn: async () => (await supabase.from("folder").select("*").throwOnError()).data,
  })

export const getFolderDocumentsQuery = () =>
  queryOptions({
    queryKey: ["folder_documents"],
    queryFn: async () => (await supabase.from("folder_document").select("*").throwOnError()).data,
  })

const documentSlugGenerator = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 12)
export async function createDocumentApi({
  name,
  slug,
  folderId,
  passphrase,
}: {
  name: string
  slug?: string | null
  folderId: string | null
  passphrase: string | null
}) {
  if (!slug) {
    slug = documentSlugGenerator()
  }
  let p2pKey: string | undefined
  let encryption: any = null
  if (passphrase) {
    const salt = crypto.getRandomValues(new Uint8Array(16))
    const iv = crypto.getRandomValues(new Uint8Array(16))

    const passphraseKey = await derivePassphraseKey(passphrase, salt)
    const passphraseEncoder = new AesEncoder(passphraseKey)

    const encryptionKey = await crypto.subtle.generateKey({ name: "AES-CBC", length: 128 }, true, [
      "encrypt",
      "decrypt",
    ])

    const encryptionKeyData = await crypto.subtle.exportKey("raw", encryptionKey)
    const encryptedKey = encodeToBase64(
      await passphraseEncoder.encode(new Uint8Array(encryptionKeyData)),
    )

    const randomKey = crypto.getRandomValues(new Uint8Array(16))

    p2pKey = encodeToBase64(await new AesEncoder(encryptionKey).encode(randomKey))
    encryption = {
      key: encryptedKey,
      salt: encodeToBase64(salt),
      iv: encodeToBase64(iv),
    }
  }

  return await supabase
    .rpc("document_create", {
      _name: name,
      _slug: slug,
      _folder_id: folderId!,
      _p2p_key: p2pKey || "",
      _encryption: encryption!,
    })
    .single()
    .throwOnError()
}

export async function updateDocumentApi({
  id,
  name,
  slug,
}: {
  id: string
  name: string
  slug: string
}) {
  return await supabase.from("document").update({ name, slug }).eq("id", id).throwOnError()
}

export async function moveDocumentApi({ id, folderId }: { id: string; folderId: string | null }) {
  return await supabase
    .rpc("folder_document_move", {
      _document_id: id,
      _folder_id: folderId!,
    })
    .single()
    .throwOnError()
}

export async function renameDocumentApi({ id, name }: { id: string; name: string }) {
  return await supabase.from("document").update({ name }).eq("id", id).throwOnError()
}

export async function archiveDocumentApi({ id }: { id: string }) {
  return await supabase.rpc("document_archive", { _document_id: id }).single().throwOnError()
}

export async function moveFolderApi({ id, parentId }: { id: string; parentId: string | null }) {
  return await supabase.from("folder").update({ parent_id: parentId }).eq("id", id).throwOnError()
}

export async function renameFolderApi({ id, name }: { id: string; name: string }) {
  return await supabase.from("folder").update({ name }).eq("id", id).throwOnError()
}

export async function deleteFolderApi({ id }: { id: string }) {
  return await supabase.rpc("folder_delete", { _folder_id: id }).single().throwOnError()
}

export async function createFolderApi({
  name,
  parentId,
}: {
  name: string
  parentId: string | null
}) {
  return await supabase
    .rpc("folder_create", {
      _name: name,
      _parent_id: parentId!,
    })
    .single()
    .throwOnError()
}
