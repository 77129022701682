import * as Y from "yjs"
import { customAlphabet } from "nanoid"
import { computeGridBounds } from "../../app/store/cellsSlice"
import { AppYDoc, CellKeys, CellOptionsKeys } from "./types"
import { CellId, CellInfo, CellLayout, CellType } from "./types"
import { DocObserver } from "./observer"
import { assertExists, assertUnreachable } from "@/packages/util/assert"
import { EvalData } from ".."
import { buildDataArray } from "@/packages/y-jspreadsheet/index"
import fastDiff from "fast-diff"
import { YSync } from "./sync"
import { BaseEventObservable } from "@/packages/emitter"
import { buildDependencyMap } from "@/engine/dependencies"
import { newYMap } from "@/packages/util/yjs"

export type StateManagerEvents = {
  cellInvalidated: CellId | null
  setCellOptions: { id: CellId; options: Record<string, any> }
  addCell: {
    cell: CellInfo
    options: any
    layout: CellLayout
    connections: CellId[]
  }
  removeCell: CellId
  setCellInfo: { id: CellId } & Partial<CellInfo>
  setCellLayout: { id: CellId } & CellLayout
  setCellInputs: { id: CellId; inputs: CellId[] }
  setCellsState: {
    loaded: boolean
    cells: { [id: CellId]: CellInfo }
    options: { [id: CellId]: any }
    inputs: { [id: CellId]: CellId[] }
    layouts: { [id: CellId]: CellLayout }
    viewportOrigin: [number, number]
  }
  setViewportOrigin: [number, number]
  reset: void
}

export class StateManager extends BaseEventObservable<StateManagerEvents> {
  readonly yDoc: AppYDoc
  private docObserver?: DocObserver
  private localStorage: Storage

  private cellIdGenerator = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 8)

  constructor(readonly ySync: YSync) {
    super()
    this.localStorage = localStorage
    this.yDoc = ySync.yDoc as AppYDoc
  }

  init() {
    if (this.ySync.isLocallySynced) {
      this.reloadCellState()
      this.registerObserver()
    } else {
      this.waitForSync()
    }
  }

  private async waitForSync() {
    await this.ySync.whenLocallySynced()
    this.reloadCellState()
    this.registerObserver()
  }

  save() {
    this.ySync.saveToRemote()
  }

  private registerObserver() {
    this.docObserver?.destroy()
    this.docObserver = new DocObserver(this.yDoc)
    this.docObserver.onAny((event, data) => {
      this.emitter.emit(event, data)
    })
  }

  private reloadCellState() {
    const cellsMap = this.yDoc.getMap("cells")

    const cellsState: StateManagerEvents["setCellsState"] = {
      loaded: true,
      cells: {},
      options: {},
      inputs: {},
      layouts: {},
      viewportOrigin: [0, 0],
    }

    for (const [cellId, cellData] of cellsMap.entries()) {
      const type = cellData.get("type")
      const name = cellData.get("name")
      const layout = cellData.get("layout")
      const inputs = cellData.get("inputs")
      const options = cellData.get("options")

      cellsState.cells[cellId] = {
        id: cellId,
        type: type,
        name: name,
      }

      const validInputs = inputs.toArray()
      // cleanup invalid inputs
      // const validInputs = inputs.toArray().filter((input) => cellsMap.has(input))
      // if (validInputs.length !== inputs.toArray().length) {
      //   this.yDoc.transact(() => {
      //     inputs.delete(0, inputs.length)
      //     inputs.push(validInputs)
      //   })
      // }

      cellsState.inputs[cellId] = validInputs
      cellsState.layouts[cellId] = layout
      cellsState.options[cellId] = options && Object.fromEntries([...options.entries()])
    }

    const storedViewportOrigin = this.localStorage.getItem(`${this.yDoc.guid}:viewportOrigin`)
    if (storedViewportOrigin) {
      const [x, y] = JSON.parse(storedViewportOrigin)
      cellsState.viewportOrigin = [x, y]
    } else {
      const { left: x, top: y } = computeGridBounds(Object.values(cellsState.layouts))
      this.localStorage.setItem(`${this.yDoc.guid}:viewportOrigin`, JSON.stringify([x, y]))
      cellsState.viewportOrigin = [x, y]
    }

    this.emitter.emitSync("setCellsState", cellsState)
    this.emitter.emit("cellInvalidated", null)
  }

  buildEvalData(cellIds?: CellId[]): EvalData {
    const cellsMap = this.yDoc.getMap("cells")

    const inputs: { [id: string]: string[] } = {}
    for (const [id, cellData] of cellsMap.entries()) {
      inputs[id] = cellData.get("inputs").toArray()
    }

    let cellIdsSet: Set<CellId>
    const dependenciesMap = buildDependencyMap(inputs)
    if (!cellIds) {
      cellIdsSet = new Set(cellsMap.keys())
    } else {
      cellIdsSet = new Set()
      for (const cellId of cellIds) {
        cellIdsSet.add(cellId)
        dependenciesMap.get(cellId)?.forEach((id) => cellIdsSet.add(id))
        Array.from(dependenciesMap.entries()).forEach(([id, deps]) => {
          if (deps.includes(cellId)) {
            cellIdsSet.add(id)
            deps.forEach((id) => cellIdsSet.add(id))
          }
        })
      }
    }

    const evalData: EvalData = {
      cells: {},
      content: {},
      options: {},
      inputs: {},
      dependencies: new Map(),
    }

    cellIdsSet.forEach((cellId) => {
      const cellData = cellsMap.get(cellId)
      assertExists(cellData)
      const cellContent = cellsMap.get(cellId)?.get("content")

      const type = cellData.get("type")
      evalData.cells[cellId] = {
        id: cellId,
        type: type,
        name: cellData.get("name"),
      }
      evalData.options[cellId] = cellData.get("options")?.toJSON() ?? {}
      evalData.inputs[cellId] = cellData.get("inputs").toArray()
      evalData.dependencies.set(cellId, dependenciesMap.get(cellId) ?? [])

      switch (type) {
        case "empty":
          return ""
        case "text":
        case "code":
        case "http":
        case "snippet":
        case "prompt":
          evalData.content[cellId] = cellContent?.toJSON()
          break
        case "table":
          if (cellContent) {
            evalData.content[cellId] = buildDataArray(cellContent as Y.Map<any>)
          } else {
            evalData.content[cellId] = []
          }
          break
        default:
          assertUnreachable(type)
      }
    })

    return evalData
  }

  getCellContent(cellId: CellId): { cellType: CellType; content: Y.AbstractType<any> } {
    const cellData = this.yDoc.getMap("cells").get(cellId)
    assertExists(cellData)
    const content = cellData.get("content")
    assertExists(content)

    return { cellType: cellData.get("type"), content }
  }

  getCellOptions(cellId: CellId): { cellType: CellType; options: Y.Map<any> } {
    const cellData = this.yDoc.getMap("cells").get(cellId)
    assertExists(cellData)
    const options = cellData.get("options")
    assertExists(options)

    return { cellType: cellData.get("type"), options }
  }

  setViewportOrigin(origin: [number, number]) {
    this.localStorage.setItem(`${this.yDoc.guid}:viewportOrigin`, JSON.stringify(origin))
    this.emitter.emitSync("setViewportOrigin", origin)
  }

  setCellContent(cellId: CellId, data: any) {
    const cellData = this.yDoc.getMap("cells").get(cellId)
    assertExists(cellData)

    const cellType = cellData.get("type")
    switch (cellType) {
      case "empty":
        return
      case "text":
      case "code":
      case "http":
      case "snippet":
      case "prompt":
        this.yDoc.transact(() => {
          const content = cellData.get("content") as Y.Text
          assertExists(content)
          const contentDiff = fastDiff(content.toString(), String(data))
          const deltas = contentDiff.map(([op, text]) => {
            switch (op) {
              case fastDiff.INSERT:
                return { insert: text }
              case fastDiff.DELETE:
                return { delete: text.length }
              case fastDiff.EQUAL:
                return { retain: text.length }
            }
            return assertUnreachable(op)
          })
          content.applyDelta(deltas)
        })
        break
      case "table": {
        if (!Array.isArray(data)) {
          throw new Error("Invalid content type")
        }
        const newContent: Record<string, string> = {}
        data.forEach((row, i) => {
          if (!Array.isArray(row)) {
            throw new Error("Invalid content type")
          }
          row.forEach((cell, j) => {
            if (cell !== null && cell !== undefined && cell !== "") {
              newContent[`${i},${j}`] = String(cell)
            }
          })
        })

        this.yDoc.transact(() => {
          const content = cellData.get("content") as Y.Map<any>
          assertExists(content)
          for (const key of content.keys()) {
            if (!newContent[key]) {
              content.delete(key)
            }
          }
          Object.entries(newContent).forEach(([key, value]) => {
            if (content.get(key) !== value) {
              content.set(key, value)
            }
          })
        })
        break
      }
      default:
        assertUnreachable(cellType)
    }
  }

  setCellName(cellId: CellId, name: string | undefined) {
    const cellData = this.yDoc.getMap("cells").get(cellId)
    assertExists(cellData)

    this.yDoc.transact(() => {
      cellData.set("name", name)
    })
  }

  setCellRunAction(cellId: CellId, runAction: CellOptionsKeys["runAction"]) {
    const cellOptions = this.yDoc.getMap("cells").get(cellId)?.get("options")
    assertExists(cellOptions)

    this.yDoc.transact(() => {
      cellOptions.set("runAction", runAction)
    })
  }

  private generateCellName(type: CellType) {
    const prefix = type
    const cellsMap = this.yDoc.getMap("cells")
    const existingNames = new Set([...cellsMap.values()].map((cellData) => cellData.get("name")))
    let cellCount = 1
    while (existingNames.has(`${prefix}${cellCount}`)) {
      cellCount++
    }
    return `${prefix}${cellCount}`
  }

  private generateCellId() {
    let cellId = this.cellIdGenerator()
    while (this.yDoc.getMap("cells").has(cellId)) {
      cellId = this.cellIdGenerator()
    }
    return cellId
  }

  createEmptyCell(layout: CellLayout) {
    const cellId = this.generateCellId()
    const cellData = newYMap<CellKeys>({
      id: cellId,
      type: "empty",
      layout,
      inputs: new Y.Array(),
    })

    this.yDoc.transact(() => {
      this.yDoc.getMap("cells").set(cellId, cellData)
    })
  }

  setCellType(cellId: CellId, type: CellType, options: Record<string, any> | undefined) {
    const cellData = this.yDoc.getMap("cells").get(cellId)
    assertExists(cellData)

    const currentType = cellData.get("type")
    if (currentType === type) {
      return
    }
    if (currentType !== "empty" || type === "empty") {
      throw new Error(`Cannot change cell type from ${currentType} to ${type}`)
    }

    this.yDoc.transact(() => {
      let content
      switch (type) {
        case "text":
        case "code":
        case "http":
        case "snippet":
        case "prompt":
          content = new Y.Text()
          break
        case "table":
          content = new Y.Map()
          break
        default:
          assertUnreachable(type)
      }
      cellData.set("name", this.generateCellName(type))
      cellData.set("content", content)

      const cellOptions = new Y.Map()
      if (options) {
        for (const [key, value] of Object.entries(options)) {
          cellOptions.set(key, value)
        }
      }
      cellData.set("options", cellOptions)

      cellData.set("type", type)
    })
  }

  deleteCell(cellId: CellId) {
    const cellsMap = this.yDoc.getMap("cells")
    this.yDoc.transact(() => {
      for (const cellData of cellsMap.values()) {
        const inputs = cellData.get("inputs")
        while (true) {
          const index = inputs.toArray().findIndex((input) => input === cellId)
          if (index !== -1) {
            inputs.delete(index)
          } else {
            break
          }
        }
      }
      this.yDoc.getMap("cells").delete(cellId)
    })
  }

  updateCellLayout(cellId: CellId, layout: CellLayout) {
    const cellsMap = this.yDoc.getMap("cells")
    const cellData = cellsMap.get(cellId)
    assertExists(cellData)

    this.yDoc.transact(() => {
      cellData.set("layout", layout)

      if (layout.type === "archived") {
        cellData.set("inputs", new Y.Array())
        for (const cellData of cellsMap.values()) {
          const inputs = cellData.get("inputs")
          while (true) {
            const index = inputs.toArray().findIndex((input) => input === cellId)
            if (index !== -1) {
              inputs.delete(index)
            } else {
              break
            }
          }
        }
      }
    })
  }

  addCellInput(cellId: CellId, inputId: CellId) {
    const cellData = this.yDoc.getMap("cells").get(cellId)
    assertExists(cellData)
    const inputs = cellData.get("inputs")
    if (inputs.toArray().every((input) => input !== inputId)) {
      this.yDoc.transact(() => {
        inputs.push([inputId])
      })
    }
  }

  removeCellInput(cellId: CellId, inputId: CellId) {
    const cellData = this.yDoc.getMap("cells").get(cellId)
    assertExists(cellData)

    const inputs = cellData.get("inputs")
    const index = inputs.toArray().findIndex((input) => input === inputId)
    if (index !== -1) {
      this.yDoc.transact(() => {
        inputs.delete(index)
      })
    }
  }

  destroy() {
    this.emitter.emitSync("reset")
    this.emitter.clearListeners()
    this.docObserver?.destroy()
    this.ySync.destroy()
    this.yDoc.destroy()
  }
}
