import { RefObject, useEffect } from "react"
import { Box, Vec2, isPointInBox } from "@/packages/util/geometry"
import { ReactDOMAttributes } from "@use-gesture/react/dist/declarations/src/types"
import { CellId } from "@/engine/state/types"
import { useDrag, useGesture } from "@use-gesture/react"
import { Engine } from "@/engine"

export const GRID_STEP = 10
export const GRID_VERTICAL_PADDING = 400
export const GRID_HORIZONTAL_PADDING = 200
export const MIN_CELL_WIDTH = 300
export const MIN_CELL_HEIGHT = 100
export const GRID_MIN_SCALE = 0.1
export const GRID_MAX_SCALE = 1

let currentZIndex = 10

// TODO: reset z-index at some point
export function getNextZIndex(currentValue: string) {
  if (currentZIndex.toString() === currentValue) {
    return currentValue
  } else {
    return String(currentZIndex++)
  }
}

export function useConnectGesture(
  containerRef: RefObject<HTMLDivElement>,
  layout: Box,
  onNewConnection: (cellId: CellId, to: Vec2, dragging: boolean) => void,
): (cellId: CellId) => ReactDOMAttributes {
  function transformCoordinates([offsetX, offsetY]: Vec2, target: EventTarget): Vec2 {
    const {
      width: containerWidth,
      x: containerX,
      y: containerY,
    } = containerRef.current!.getBoundingClientRect()

    const { width, height, x, y } = (target as HTMLElement).getBoundingClientRect()
    const scale = containerWidth / containerRef.current!.offsetWidth

    return [
      layout.x + (offsetX + x + width / 2 - containerX) / scale,
      layout.y + (offsetY + y + height / 2 - containerY) / scale,
    ]
  }

  return useGesture(
    {
      onDrag({ offset, event, target, args: [cellId], down }) {
        if (!containerRef.current) return
        event.stopPropagation()

        onNewConnection?.(cellId, transformCoordinates(offset, target), down)
      },
    },
    {
      drag: {
        from: () => [0, 0],
        eventOptions: { passive: false, capture: true },
        preventDefault: true,
      },
    },
  )
}

export function getScaledOffset(offset: Vec2, containerRef: RefObject<HTMLDivElement>): Vec2 {
  const scale = containerRef.current
    ? containerRef.current.getBoundingClientRect().width / containerRef.current.offsetWidth
    : 1
  return [offset[0] / scale, offset[1] / scale]
}

export function useResizeGestures(
  containerRef: RefObject<HTMLDivElement>,
  layout: Box,
  onResize: (layout: Partial<Box>, down: boolean) => void,
) {
  return useDrag(
    ({ offset: [offsetX, offsetY], args: [x, y], down }) => {
      let [gridWidthOffset, gridHeightOffset] = [
        Math.round(offsetX / GRID_STEP) * GRID_STEP - layout.width,
        Math.round(offsetY / GRID_STEP) * GRID_STEP - layout.height,
      ]

      const args: Partial<Box> = {}
      if (x === 1) {
        if (gridWidthOffset + layout.width < MIN_CELL_WIDTH) {
          gridWidthOffset = MIN_CELL_WIDTH - layout.width
        }
        args.width = gridWidthOffset + layout.width
      } else if (x === -1) {
        if (layout.width - gridWidthOffset < MIN_CELL_WIDTH) {
          gridWidthOffset = layout.width - MIN_CELL_WIDTH
        }
        args.x = gridWidthOffset
        args.width = layout.width - gridWidthOffset
      }

      if (y === 1) {
        if (gridHeightOffset + layout.height < MIN_CELL_HEIGHT) {
          gridHeightOffset = MIN_CELL_HEIGHT - layout.height
        }
        args.height = gridHeightOffset + layout.height
      } else if (y === -1) {
        if (layout.height - gridHeightOffset < MIN_CELL_HEIGHT) {
          gridHeightOffset = layout.height - MIN_CELL_HEIGHT
        }
        args.y = gridHeightOffset
        args.height = layout.height - gridHeightOffset
      }

      onResize(args, down)
    },
    {
      from: () => [layout.width, layout.height],
      transform: (offset) => getScaledOffset(offset, containerRef),
      preventDefault: true,
    },
  )
}

export function useMoveGesture(
  containerRef: RefObject<HTMLDivElement>,
  layout: Box,
  touchMode: boolean,
  onMove: (position: Vec2, dragging: boolean) => void,
) {
  return useGesture(
    {
      onDrag({ offset: [x, y], down, event }) {
        const [gridX, gridY] = [
          Math.round(x / GRID_STEP) * GRID_STEP,
          Math.round(y / GRID_STEP) * GRID_STEP,
        ]
        onMove([gridX - layout.x, gridY - layout.y], down)
      },
    },
    {
      drag: {
        from: () => [layout.x, layout.y],
        transform: (offset) => getScaledOffset(offset, containerRef),
        keyboardDisplacement: GRID_STEP,
        filterTaps: true,
        pointer: {
          touch: touchMode,
        },
        preventScroll: touchMode,
      },
    },
  )
}

export function findIntersectingCell(
  point: Vec2,
  cellLayouts: Record<CellId, Box>,
  excludeCellId?: CellId,
) {
  return Object.entries(cellLayouts).find(([cellId, layout]) => {
    if (cellId === excludeCellId) return false
    return isPointInBox(point, layout)
  })?.[0]
}

export function useEvalShortcut(
  containerRef: RefObject<HTMLElement>,
  cellId: CellId,
  engine: Engine,
) {
  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const keydownHandler = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === "Enter") {
        engine.evalCell(cellId)
        e.preventDefault()
        e.stopPropagation()
      }
    }
    container.addEventListener("keydown", keydownHandler, { capture: true })
    return () => {
      container.removeEventListener("keydown", keydownHandler, { capture: true })
    }
  }, [cellId, engine, containerRef])
}

export function useSaveShortcut(engine: Engine | null) {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "s" && e.metaKey) {
        engine?.stateManager.save()
        e.preventDefault()
      }
    }
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [engine])
}
