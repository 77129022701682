import { selectCellLayouts, selectCells, selectCellsLoaded } from "@/app/store/cellsSlice"
import CellFrame, { CellFrameHandle } from "./CellFrame"
import BoardConnections, { BoardConnectionsHandle } from "./BoardConnections"
import BoardScrollView from "./ScrollView"
import { store, useAppSelector } from "../../../store"
import { memo, useRef } from "react"
import { Box, Vec2, isPointInBox } from "@/packages/util/geometry"
import CellStackFrame from "./CellStackFrame"
import { CellId } from "@/engine/state/types"
import { useEngine } from "../document/EngineContext"

export default memo(function Board() {
  const engine = useEngine()

  const cellsLoaded = useAppSelector(selectCellsLoaded)
  const cells = useAppSelector(selectCells)
  const cellLayouts = useAppSelector(selectCellLayouts)
  const stacks = useAppSelector((state) => state.cells.stacks)

  const boardConnectionsRef = useRef<BoardConnectionsHandle>(null)
  const frameRefs = useRef<Record<string, CellFrameHandle | null>>({})

  function handleNewConnectionDrag(cellId: CellId, to: Vec2, dragging: boolean) {
    const cellInputs = store.getState().cells.inputs
    if (!to) {
      boardConnectionsRef.current?.reset()
      return
    }

    const hit = Object.entries(cellLayouts).find(([, layout]) => isPointInBox(to, layout))
    if (!hit || hit[0] === cellId) {
      if (dragging) {
        boardConnectionsRef.current?.addArrowToPoint(cellId, to)
      } else {
        boardConnectionsRef.current?.reset()
      }
    } else {
      const [hitCellId] = hit
      const hitConnections = cellInputs[hitCellId]
      if (dragging) {
        boardConnectionsRef.current?.addArrowToCell(cellId, hitCellId)
      } else if (!hitConnections.includes(cellId)) {
        engine.stateManager.addCellInput(hitCellId, cellId)
      } else {
        boardConnectionsRef.current?.reset()
      }
    }
  }

  function handleCellLayoutUpdate(cellId: CellId, box: Box, dragging: boolean) {
    if (dragging) {
      boardConnectionsRef.current?.setCelLayoutOverride({ [cellId]: box })
    } else {
      boardConnectionsRef.current?.reset()
    }
  }

  function createCell([x, y]: Vec2) {
    engine.stateManager.createEmptyCell({
      type: "absolute",
      x: x - 200,
      y: y,
      width: 400,
      height: 200,
    })
  }

  console.log("render Board")

  if (!cellsLoaded) {
    return (
      <div className="blueprint-background h-full w-full animate-pulse pt-2 text-center text-gray-600">
        loading...
      </div>
    )
  }

  return (
    <BoardScrollView onDoubleClick={createCell} nativeScroll={true}>
      <BoardConnections ref={boardConnectionsRef} />
      {Object.values(cells)
        .filter((cell) => cellLayouts[cell.id].type === "absolute")
        .map((cell) => (
          <CellFrame
            key={cell.id}
            ref={(ref) => (frameRefs.current[cell.id] = ref)}
            cellId={cell.id}
            onNewConnection={handleNewConnectionDrag}
            onLayoutUpdating={(box, dragging) => handleCellLayoutUpdate(cell.id, box, dragging)}
          />
        ))}
      {Object.values(stacks).map((stack) => (
        <CellStackFrame
          key={stack.id}
          stackId={stack.id}
          onOffsetChange={(offset) =>
            // boardConnectionsRef.current?.setCellOffsets(
            //   Object.fromEntries(stack.cells.map((cellId) => [cellId, offset])),
            // )
            {}
          }
          onNewConnection={handleNewConnectionDrag}
        />
      ))}
    </BoardScrollView>
  )
})
