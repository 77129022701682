import { Outlet, useChildMatches, useNavigate } from "@tanstack/react-router"
import { useIsMobile } from "@/packages/components/responsive"
import { useEffect } from "react"
import { getDocumentsQuery } from "@/app/supabase"
import { useQuery } from "@tanstack/react-query"
import { LAST_ACCESS_KEY } from "../routes"
import WorkspaceNavbarMobile from "./nav/WorkspaceNavbarMobile"
import WorkspaceNavbarDesktop from "./nav/WorkspaceNavbarDesktop"
import { CommandPaletteProvider } from "./CommandPalette"

export default function Workspace() {
  const { data: documents, error, isPending } = useQuery(getDocumentsQuery())

  const routeMarches = useChildMatches()
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  useEffect(() => {
    if (isPending) return

    if (routeMarches.length === 0) {
      if (documents?.length) {
        const slug = localStorage.getItem(LAST_ACCESS_KEY)
        if (slug && documents.find((w) => w.slug === slug)) {
          navigate({
            to: "/g/$slug",
            params: { slug },
            replace: true,
          })
        } else {
          navigate({
            to: "/g/$slug",
            params: { slug: documents[0].slug },
            replace: true,
          })
        }
      } else {
        navigate({
          to: "/g/new",
          replace: true,
        })
      }
    }
  }, [routeMarches, documents, isPending, navigate])

  console.log("render Workspace")

  const showNav = !(isMobile && routeMarches.length > 1)

  return (
    <CommandPaletteProvider>
      <div className="flex h-dvh min-h-dvh w-dvw touch-none flex-row-reverse">
        <div className="flex-1 overflow-hidden">
          <Outlet />
        </div>
        {showNav && (
          <div className="flex-initial">
            {isMobile ? <WorkspaceNavbarMobile /> : <WorkspaceNavbarDesktop />}
          </div>
        )}
      </div>
    </CommandPaletteProvider>
  )
}
