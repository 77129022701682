import * as Y from "yjs"
import { CellKeys, AppYDoc } from "./types"
import { assertExists, assertUnreachable } from "@/packages/util/assert"
import { CellId } from "./types"
import { BaseEventObservable } from "@/packages/emitter"
import { StateManagerEvents } from "./state"
import { StrictYMap } from "@/packages/util/yjs"

export class DocObserver extends BaseEventObservable<StateManagerEvents> {
  private cellsMap: Y.Map<StrictYMap<CellKeys>>
  private _observer: any

  constructor(private yDoc: AppYDoc) {
    super()
    this.cellsMap = yDoc.getMap("cells")
    this._observer = (events: Y.YEvent<any>[]) =>
      events.forEach((event) => this.handleYEvent(event))

    this.cellsMap.observeDeep(this._observer)
  }

  private handleYEvent(event: Y.YEvent<any>) {
    const path = event.path

    if (path.length === 0) {
      this.handleCellMapUpdate(event)
    } else if (path.length === 1) {
      this.handleCellDataUpdate(event)
    } else if (path.length === 2) {
      const [cellId, prop] = path as [CellId, keyof CellKeys]
      if (prop === "content") {
        this.emitter.emit("cellInvalidated", cellId)
      } else if (prop === "inputs") {
        this.emitter.emit("cellInvalidated", cellId)
        this.handleCellInputsUpdate(event)
      } else if (prop === "options") {
        const options = this.cellsMap.get(path[0] as string)!.get("options")!
        this.emitter.emitSync("setCellOptions", {
          id: cellId,
          options: Object.fromEntries([...options.entries()]),
        })
      } else {
        console.log("unhandled event", event, path, event.changes)
      }
    }
  }

  private handleCellMapUpdate(event: Y.YEvent<any>) {
    const { keys } = event.changes
    for (const [key, change] of keys) {
      const cellId = key as CellId

      if (change.action === "add") {
        const cellData = this.cellsMap.get(cellId)
        assertExists(cellData)

        const type = cellData.get("type")
        const name = cellData.get("name")
        const layout = cellData.get("layout")
        const inputs = cellData.get("inputs")
        const options = cellData.get("options")

        this.emitter.emitSync("addCell", {
          cell: {
            id: cellId,
            type: type,
            name: name,
          },
          layout: layout,
          connections: inputs.toJSON(),
          options: options && Object.fromEntries([...options.entries()]),
        })
        this.emitter.emit("cellInvalidated", cellId)
      } else if (change.action === "delete") {
        this.emitter.emitSync("removeCell", key as CellId)
      } else {
        throw new Error(`Unexpected action ${change.action}`)
      }
    }
  }

  private handleCellDataUpdate(event: Y.YEvent<any>) {
    const [cellId] = event.path as [CellId]
    const cellData = this.cellsMap.get(cellId)
    assertExists(cellData)

    const { keys } = event.changes

    // TODO?
    // const update: Partial<CellInfo> = {}

    for (const [key, change] of keys) {
      const prop = key as keyof CellKeys

      switch (prop) {
        case "id": {
          throw new Error("Cannot update cell id")
        }
        case "type": {
          const type = cellData.get("type")
          this.emitter.emitSync("setCellInfo", {
            id: cellId,
            type: type,
          })
          break
        }
        case "name": {
          const name = cellData.get("name")
          this.emitter.emitSync("setCellInfo", { id: cellId, name: name })
          this.emitter.emit("cellInvalidated", cellId)
          break
        }
        case "layout": {
          const layout = cellData.get("layout")
          this.emitter.emitSync("setCellLayout", { id: cellId, ...layout })
          break
        }
        case "options": {
          const options = cellData.get("options")
          this.emitter.emitSync("setCellOptions", {
            id: cellId,
            options: options ? Object.fromEntries([...options.entries()]) : {},
          })
          break
        }
        case "content":
        case "inputs":
          break
        default:
          assertUnreachable(prop)
      }
    }
  }

  private handleCellInputsUpdate(event: Y.YEvent<any>) {
    const [cellId] = event.path as [CellId]
    const cellData = this.cellsMap.get(cellId)
    assertExists(cellData)

    const inputsArray = cellData.get("inputs").toArray()

    this.emitter.emitSync("setCellInputs", { id: cellId, inputs: inputsArray })
  }

  destroy() {
    this.emitter.clearListeners()
    this.cellsMap.unobserveDeep(this._observer)
  }
}
