import {
  composeRenderProps,
  Button as AriaButton,
  ButtonProps as AriaButtonProps,
} from "react-aria-components"
import { tv } from "tailwind-variants"
import { focusRing } from "./utils"

export interface ButtonProps extends AriaButtonProps {
  variant?: "primary" | "secondary" | "destructive" | "icon"
}

const buttonStyles = tv({
  extend: focusRing,
  base: "cursor-default rounded-lg border border-white/10 px-5 py-2 text-center text-sm transition",
  variants: {
    variant: {
      primary: "bg-blue-600 text-white hover:bg-blue-700 pressed:bg-blue-800",
      secondary: "bg-zinc-600 text-zinc-100 hover:bg-zinc-500 pressed:bg-zinc-400",
      destructive: "bg-red-700 text-white hover:bg-red-800 pressed:bg-red-900",
      icon: "flex items-center justify-center border-0 p-1 text-zinc-400 hover:bg-white/10 pressed:bg-white/20 disabled:bg-transparent",
    },
    isDisabled: {
      true: "border-white/5 bg-zinc-800 text-zinc-600",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
})

export function Button(props: ButtonProps) {
  return (
    <AriaButton
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        buttonStyles({ ...renderProps, variant: props.variant, className }),
      )}
    />
  )
}

export function IconButton(props: ButtonProps) {
  return <Button {...props} variant="icon" />
}
