import { forwardRef, Ref, useImperativeHandle, useState } from "react"
import { SelectedNavItem } from "./types"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { renameDocumentApi, renameFolderApi } from "@/app/supabase"
import { toast } from "react-toastify"
import { Modal } from "../../components/Modal"
import { Dialog, Heading } from "../../components/Dialog"
import { Form } from "../../components/Form"
import { Button } from "../../components/Button"
import { TextField } from "../../components/TextField"
import { FieldError, Input } from "../../components/Field"

export interface RenameItemDialogHandle {
  show: (item: SelectedNavItem) => void
}

export default forwardRef(function RenameItemDialog(_, ref: Ref<RenameItemDialogHandle>) {
  const queryClient = useQueryClient()

  const { mutate: renameFolder } = useMutation({
    mutationFn: renameFolderApi,
    onSuccess({ data }) {
      queryClient.invalidateQueries({ queryKey: ["folders"] })
    },
    onError(err) {
      console.error(err)
      toast.error("Failed to rename folder: " + err.message)
    },
  })

  const { mutate: renameDocument } = useMutation({
    mutationFn: renameDocumentApi,
    onSuccess({ data }) {
      queryClient.invalidateQueries({ queryKey: ["documents"] })
    },
    onError(err) {
      console.error(err)
      toast.error("Failed to rename document: " + err.message)
    },
  })

  const [item, setItem] = useState<SelectedNavItem | null>(null)
  const [newName, setNewName] = useState("")

  useImperativeHandle(ref, () => ({
    show(item: SelectedNavItem) {
      setItem(item)
      setNewName(item.name)
    },
  }))

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!item || !newName) return
    if (item.type === "folder") {
      renameFolder({ id: item.id, name: newName })
    } else {
      renameDocument({ id: item.id, name: newName })
    }
    setItem(null)
  }

  return (
    <Modal
      isOpen={!!item}
      isDismissable
      onOpenChange={(open) => {
        if (!open) setItem(null)
      }}
    >
      <Dialog aria-label="Rename item">
        <Heading slot="title">Rename {item?.type}</Heading>
        <Form onSubmit={handleSubmit}>
          <TextField
            aria-label="Item name"
            name="name"
            type="text"
            autoFocus
            isRequired
            value={newName}
            onChange={setNewName}
          >
            <Input />
            <FieldError />
          </TextField>
          <Button type="submit" className="mt-2">
            Rename
          </Button>
        </Form>
      </Dialog>
    </Modal>
  )
})
