import { ChevronDownIcon } from "lucide-react"
import React from "react"
import {
  Select as AriaSelect,
  SelectProps as AriaSelectProps,
  Button as AriaButton,
  ListBox as AriaListBox,
  ListBoxItemProps as AriaListBoxItemProps,
  SelectValue as AriaSelectValue,
  ValidationResult as AriaValidationResult,
} from "react-aria-components"
import { tv } from "tailwind-variants"
import { Description, FieldError, Label } from "./Field"
import { DropdownItem, DropdownSection, DropdownSectionProps } from "./ListBox"
import { Popover } from "./Popover"
import { composeTailwindRenderProps, focusRing } from "./utils"

const styles = tv({
  extend: focusRing,
  base: "flex w-full min-w-[150px] cursor-default items-center gap-4 rounded-lg border border-white/10 bg-zinc-700 py-2 pl-3 pr-2 text-start transition",
  variants: {
    isDisabled: {
      false: "text-zinc-300 group-invalid:border-red-600 hover:bg-zinc-600 pressed:bg-zinc-500",
      true: "border-white/5 bg-zinc-800 text-zinc-600",
    },
  },
})

export interface SelectProps<T extends object> extends Omit<AriaSelectProps<T>, "children"> {
  label?: string
  description?: string
  errorMessage?: string | ((validation: AriaValidationResult) => string)
  items?: Iterable<T>
  children: React.ReactNode | ((item: T) => React.ReactNode)
}

export function Select<T extends object>({
  label,
  description,
  errorMessage,
  children,
  items,
  ...props
}: SelectProps<T>) {
  return (
    <AriaSelect
      {...props}
      className={composeTailwindRenderProps(props.className, "group flex flex-col gap-1")}
    >
      {label && <Label>{label}</Label>}
      <AriaButton className={styles}>
        <AriaSelectValue className="flex-1 text-sm placeholder-shown:italic" />
        <ChevronDownIcon
          aria-hidden
          className="h-4 w-4 text-zinc-400 group-disabled:text-zinc-600"
        />
      </AriaButton>
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
      <Popover className="min-w-[--trigger-width]">
        <AriaListBox
          items={items}
          className="max-h-[inherit] overflow-auto p-1 outline-none [clip-path:inset(0_0_0_0_round_.75rem)]"
        >
          {children}
        </AriaListBox>
      </Popover>
    </AriaSelect>
  )
}

export function SelectItem(props: AriaListBoxItemProps) {
  return <DropdownItem {...props} />
}

export function SelectSection<T extends object>(props: DropdownSectionProps<T>) {
  return <DropdownSection {...props} />
}
