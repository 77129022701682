import { CellId } from "@/engine/state/types"
import { selectGridBounds, selectCellLayoutById, selectCellById } from "@/app/store/cellsSlice"
import { useAppSelector } from "../../../store"
import { useRef } from "react"
import CellPreview from "./../cells/CellPreview"
import { useNavigate } from "@tanstack/react-router"
import { cellRoute, documentRoute } from "@/app/ui/routes"
import { PlusCircleIcon } from "lucide-react"
import CellView from "./../cells/CellView"
import { useMoveGesture } from "./utils"
import { animated, useSpring } from "@react-spring/web"
import { useEngine } from "../document/EngineContext"
import { Box } from "@/packages/util/geometry"
import { twJoin } from "tailwind-merge"

interface CellFrameProps {
  cellId: CellId
  showContent: boolean
  onLayoutUpdating?: (layout: Box, dragging: boolean) => void
}

export type CellFrameHandle = unknown

export default function PreviewCellFrame({
  cellId,
  showContent,
  onLayoutUpdating,
}: CellFrameProps) {
  const engine = useEngine()
  const navigate = useNavigate({ from: documentRoute.fullPath })

  const cell = useAppSelector((state) => selectCellById(state, cellId))
  const cellLayout = useAppSelector((state) => selectCellLayoutById(state, cellId))

  const gridBounds = useAppSelector(selectGridBounds)
  const containerRef = useRef<HTMLDivElement>(null)

  const openFullscreen = () => {
    navigate({ to: cellRoute.fullPath, params: (prev) => ({ ...prev, cellId }) })
  }

  const [springStyle, spring] = useSpring(
    () => ({
      x: 0,
      y: 0,
      width: cellLayout.width,
      height: cellLayout.height,
      config: {
        mass: 1,
        friction: 50,
        tension: 500,
        clamp: true,
      },
      immediate: true,
      onChange(result) {
        const { x: dx, y: dy, width, height } = result.value
        onLayoutUpdating?.({ x: cellLayout.x + dx, y: cellLayout.y + dy, width, height }, true)
      },
      onRest(result, ctrl) {},
    }),
    [cellLayout],
  )

  const moveGestures = useMoveGesture(containerRef, cellLayout, true, ([x, y], dragging) => {
    if (dragging) {
      spring.start({ x, y })
    } else {
      onLayoutUpdating?.({ x, y, width: cellLayout.width, height: cellLayout.height }, false)
      spring.stop(true)
      engine.stateManager.updateCellLayout(cellId, {
        ...cellLayout,
        x: cellLayout.x + x,
        y: cellLayout.y + y,
      })
    }
  })

  return (
    <animated.div
      ref={containerRef}
      onClick={openFullscreen}
      className="cell-preview z-10 grid grid-cols-[4px_minmax(0,_1fr)_4px] grid-rows-[auto_minmax(0,_1fr)_16px] contain-strict"
      style={{
        position: "absolute",
        left: cellLayout.x - gridBounds.left,
        top: cellLayout.y - gridBounds.top,
        ...springStyle,
      }}
    >
      <div className=""></div>
      <div
        className="flow-row relative flex h-8 select-none items-center pl-1 pr-2 text-sm text-gray-400"
        {...moveGestures()}
      >
        {showContent && (
          <>
            <span
              className={twJoin("relative truncate pb-[1px]", cell.name || "italic text-gray-600")}
              title={cell.name}
            >
              {cell.name || "untitled"}
            </span>
            <span className="ligatures-none mx-1 flex-initial whitespace-nowrap text-xs text-gray-600">
              (id: {cell.id})
            </span>
          </>
        )}
      </div>
      <div className=""></div>
      <div className=""></div>
      <div className="pointer-events-none touch-none">
        {showContent && <CellView cellId={cellId} />}
        {!showContent && <CellPreview cellId={cellId} />}
      </div>
      <div className=""></div>
      <div className=""></div>
      <div className="flex items-center justify-end text-gray-400">
        <div className="">
          <PlusCircleIcon className="h-3 w-3 hover:text-blue-600" />
        </div>
      </div>
      <div className=""></div>
    </animated.div>
  )
}
