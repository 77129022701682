import {
  FieldErrorProps as AriaFieldErrorProps,
  Group as AriaGroup,
  GroupProps as AriaGroupProps,
  InputProps as AriaInputProps,
  LabelProps as AriaLabelProps,
  FieldError as AriaFieldError,
  Input as AriaInput,
  Label as AriaLabel,
  Text as AriaText,
  TextProps as AriaTextProps,
  composeRenderProps,
} from "react-aria-components"
import { twMerge } from "tailwind-merge"
import { tv } from "tailwind-variants"
import { composeTailwindRenderProps, focusRing } from "./utils"

export function Label(props: AriaLabelProps) {
  return (
    <AriaLabel
      {...props}
      className={twMerge("w-fit cursor-default text-sm font-medium text-zinc-400", props.className)}
    />
  )
}

export function Description(props: AriaTextProps) {
  return (
    <AriaText
      {...props}
      slot="description"
      className={twMerge("text-sm text-gray-600", props.className)}
    />
  )
}

export function FieldError(props: AriaFieldErrorProps) {
  return (
    <AriaFieldError
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        "text-sm text-red-600 forced-colors:text-[Mark]",
      )}
    />
  )
}

export const fieldBorderStyles = tv({
  variants: {
    isFocusWithin: {
      false: "border-zinc-500",
      true: "border-zinc-300",
    },
    isInvalid: {
      true: "border-red-600",
    },
    isDisabled: {
      true: "border-zinc-700",
    },
  },
})

export const fieldGroupStyles = tv({
  extend: focusRing,
  base: "group flex h-9 items-center overflow-hidden rounded-lg border-2 bg-zinc-900",
  variants: fieldBorderStyles.variants,
})

export function FieldGroup(props: AriaGroupProps) {
  return (
    <AriaGroup
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        fieldGroupStyles({ ...renderProps, className }),
      )}
    />
  )
}

export function Input(props: AriaInputProps) {
  return (
    <AriaInput
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        "min-w-0 flex-1 bg-zinc-900 px-2 py-1.5 text-sm text-zinc-200 outline outline-0 disabled:text-zinc-600",
      )}
    />
  )
}
