// Based on: https://github.com/intuit/devtools-ds/tree/master/components/ObjectInspector

import { useEffect, useState } from "react"
import { parse, SupportedTypes, ASTNode, ResolvedASTNode } from "./objectParser"
import ObjectInspectorItem from "./ObjectInspectorItem"

import styles from "./inspector.module.css"
import { twJoin } from "tailwind-merge"

interface ObjectInspectorProps {
  /** JSON data to render in the tree. */
  data: SupportedTypes
  /** Depth of the tree that is open at first render. */
  expandLevel?: number
  /** Whether to sort keys like the browsers do. */
  sortKeys?: boolean
  /** Whether to include object Prototypes */
  includePrototypes?: boolean
  /** Callback when a particular node in the tree is actively selected */
  onSelect?: (node?: ASTNode | ResolvedASTNode) => void
  className?: string
}

/** An emulation of browsers JSON object inspector. */
export const ObjectInspector = ({
  data,
  expandLevel = 0,
  sortKeys = true,
  includePrototypes = false,
  className,
  onSelect,
}: ObjectInspectorProps) => {
  const [ast, setAST] = useState<ASTNode | undefined>(undefined)

  /** Handle async types */
  useEffect(() => {
    /** Async function run the parser */
    const runParser = async () => {
      setAST(await parse(data, sortKeys, includePrototypes))
    }

    runParser()
  }, [data, sortKeys, includePrototypes])

  return (
    <div className={twJoin(styles.objectInspector, className)}>
      {ast && <ObjectInspectorItem ast={ast} expandLevel={expandLevel} onSelect={onSelect} />}
    </div>
  )
}

export default ObjectInspector
