import { CellId } from "@/engine/state/types"
import { EvalResult, CellOutput, EvalContext } from "./context"
import { EvalData } from ".."
import { TypeScriptWorker } from "../ts/compiler"

export type EvalOutput = { result: EvalResult; chain: CellOutput[] }

export async function evalCell(
  cellId: CellId,
  cellData: EvalData,
  typeScriptWorker: TypeScriptWorker,
): Promise<EvalOutput> {
  const context = new EvalContext(
    cellId,
    cellData.cells,
    cellData.content,
    cellData.dependencies,
    typeScriptWorker,
  )
  const result = await context.eval()
  const chain = Array.from(context.cache.values())
    .filter((r) => cellData.dependencies.get(cellId)?.includes(r.cellId))
    .sort((a, b) => a.startTimestamp - b.startTimestamp)

  return { result, chain }
}
