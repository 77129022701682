import { useRef, useLayoutEffect } from "react"
import { useEngine } from "../../document/EngineContext"
import { YJSpreadsheetBinding } from "@/packages/y-jspreadsheet"
import { selectCellLayoutById, selectCellOptionsById } from "@/app/store/cellsSlice"
import { useAppSelector } from "@/app/store"
import { TableOptionsKeys } from "@/engine/state/types"
import ContextMenu, { ContextMenuHandle, ContextMenuOption } from "@/app/ui/components/ContextMenu"
import { CellId } from "@/engine/state/types"

import "./jspreadsheet.css"

interface SpreadsheetCellViewProps {
  cellId: CellId
}

const MIN_COLUMN_WIDTH = 80

export default function SpreadsheetCellView({ cellId }: SpreadsheetCellViewProps) {
  const engine = useEngine()

  const cellLayout = useAppSelector((state) => selectCellLayoutById(state, cellId))
  const cellOptions = useAppSelector((state) =>
    selectCellOptionsById(state, cellId),
  ) as TableOptionsKeys

  const jssDomRef = useRef<HTMLDivElement>(null)
  const bindingRef = useRef<YJSpreadsheetBinding>()
  const contextMenuRef = useRef<ContextMenuHandle>(null)

  const defaultColWidthRef = useRef<number>(MIN_COLUMN_WIDTH)

  function computeColumnWidth(resizedColumns: Record<string, number>) {
    const jss = bindingRef.current!.jss

    const containerWidth = jssDomRef.current!.offsetWidth
    const cornerElement = jssDomRef.current!.querySelector(".jexcel_selectall") as HTMLElement
    const cornerWidth = cornerElement ? cornerElement.offsetWidth : 0

    const fixedWidth = Object.values(resizedColumns).reduce((sum, width) => sum + width, 0)
    const flexibleCount = jss.headerElements.length - Object.keys(resizedColumns).length

    if (flexibleCount === 0) {
      defaultColWidthRef.current = MIN_COLUMN_WIDTH
    } else {
      defaultColWidthRef.current = Math.floor(
        Math.max(MIN_COLUMN_WIDTH, (containerWidth - fixedWidth - cornerWidth - 4) / flexibleCount),
      )
    }
    return defaultColWidthRef.current
  }

  function resizeColumns() {
    const jss = bindingRef.current?.jss
    if (!jss) return

    const resizedColumns = Object.entries(cellOptions.columnMeta ?? {}).reduce(
      (acc, [key, value]) => {
        if (value.size) {
          acc[key] = value.size
        }
        return acc
      },
      {} as Record<string, number>,
    )
    const columnWidth = computeColumnWidth(resizedColumns)

    const ignoreEvents = jss.ignoreEvents
    jss.ignoreEvents = true

    const columns: number[] = []
    const widths: number[] = []
    for (let i = 0; i < jss.headerElements.length; i++) {
      if (resizedColumns[i]) {
        columns.push(i)
        widths.push(resizedColumns[i])
      } else {
        columns.push(i)
        widths.push(columnWidth)
      }
    }

    jss.setWidth(columns, widths, widths)

    jss.ignoreEvents = ignoreEvents
  }

  useLayoutEffect(() => {
    const container = jssDomRef.current
    if (container && !container?.innerText && !bindingRef.current) {
      const binding = engine.createTableBinding(cellId, container, {
        minDimensions: [5, 20],
        minSpareRows: 1,
        defaultColWidth: MIN_COLUMN_WIDTH,
        tableWidth: "100%",
        tableHeight: "100%",
        tableOverflow: true,
        wordWrap: true,
        columnDrag: true,
        rowDrag: true,
        copyCompatibility: false,
        onundo() {
          resizeColumns()
        },
        onredo() {
          resizeColumns()
        },
        oninsertcolumn() {
          resizeColumns()
        },
        ondeletecolumn() {
          resizeColumns()
        },
        onresizecolumn(_columnIndex, newWidth, oldWidth) {
          if (oldWidth.every((w, i) => w === newWidth[i])) return
          resizeColumns()
        },
        onrefresh() {
          resizeColumns()
        },
        oncontextmenushow(e, items) {
          const options: ContextMenuOption[] = items.map((item) => {
            if (item.type === "item") {
              return {
                type: "option",
                label: item.title,
                onClick: item.onclick,
              }
            } else {
              return {
                type: "separator",
              }
            }
          })
          contextMenuRef.current?.open(options, { x: e.pageX, y: e.pageY })
        },
        oncontextmenuhide() {
          contextMenuRef.current?.close()
        },
      })
      bindingRef.current = binding

      return () => {
        binding.destroy()
        bindingRef.current = undefined
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    resizeColumns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellLayout.width, cellOptions])

  return (
    <div className="spreadsheet-container h-full min-h-0 w-full overflow-hidden">
      <div className="h-full w-full" ref={jssDomRef} />
      <ContextMenu ref={contextMenuRef} />
    </div>
  )
}
