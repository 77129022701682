// Based on: https://github.com/dragonman225/curved-arrows
// https://dragonman225.js.org/curved-arrows.html

import { assertUnreachable } from "@/packages/util/assert"
import { Box, RectSide, Vec2, distanceBetween, isPointInBox } from "@/packages/util/geometry"

/**
 * @param box
 * @param size Add `size` to all edges.
 */
function growBox(box: Box, size: number): Box {
  return {
    x: box.x - size,
    y: box.y - size,
    width: box.width + 2 * size,
    height: box.height + 2 * size,
  }
}

/** Calculate the control point. */
function controlPointOf(
  [targetX, targetY]: Vec2,
  [otherX, otherY]: Vec2,
  sideOfTarget: RectSide,
  /**
   * The distance a control point must be far away from the target in the
   * direction of leaving the target.
   */
  minDistanceToTarget: number,
): Vec2 {
  const tension = 0.5
  switch (sideOfTarget) {
    case "top": {
      return [targetX, Math.min((targetY + otherY) * tension, targetY - minDistanceToTarget)]
    }
    case "bottom": {
      return [targetX, Math.max((targetY + otherY) * tension, targetY + minDistanceToTarget)]
    }
    case "left": {
      return [Math.min((targetX + otherX) * tension, targetX - minDistanceToTarget), targetY]
    }
    case "right": {
      return [Math.max((targetX + otherX) * tension, targetX + minDistanceToTarget), targetY]
    }
  }
}

/** Return the entering angle of a rectangle side. */
export function angleOf(enteringSide: RectSide): number {
  switch (enteringSide) {
    case "left":
      return 0
    case "top":
      return 90
    case "right":
      return 180
    case "bottom":
      return 270
  }
}

/** Parameters that describe an arrow. */
export type ArrowDescriptor = {
  /** start point */
  start: Vec2
  /** control point for start point */
  c1: Vec2
  /** control point for end point */
  c2: Vec2
  /** end point */
  end: Vec2
  /** angle of end point */
  angleStart: number
  /** angle of start point */
  angleEnd: number
  startSide: RectSide
  endSide: RectSide
}

export type Options = {
  padding?: number
  startSides?: RectSide[]
  endSides?: RectSide[]
}
/**
 * Get parameters to draw an S-curved line between two boxes.
 */
export function getBoxToBoxArrow(startBox: Box, endBox: Box, options?: Options): ArrowDescriptor {
  const opts: Required<Options> = {
    padding: 0,
    startSides: ["bottom", "right", "left"],
    endSides: ["top", "left", "right"],
    ...options,
  }

  const { x: x0, y: y0, width: w0, height: h0 } = startBox
  const { x: x1, y: y1, width: w1, height: h1 } = endBox

  /** Points of start box. */
  const startAtTop: Vec2 = [x0 + (3 * w0) / 4, y0 - 2 * opts.padding]
  const startAtBottom: Vec2 = [x0 + w0 / 2, y0 + h0 + 2 * opts.padding]
  const startAtLeft: Vec2 = [x0 - 2 * opts.padding, y0 + (3 * h0) / 4]
  const startAtRight: Vec2 = [x0 + w0 + 2 * opts.padding, y0 + (3 * h0) / 4]

  /** Points of end box. */
  const endAtTop: Vec2 = [x1 + w1 / 2, y1 - 2 * opts.padding]
  const endAtBottom: Vec2 = [x1 + w1 / 4, y1 + h1 + 2 * opts.padding]
  const endAtLeft: Vec2 = [x1 - 2 * opts.padding, y1 + h1 / 4]
  const endAtRight: Vec2 = [x1 + w1 + 2 * opts.padding, y1 + h1 / 4]

  const startPoints: Vec2[] = opts.startSides.map((side) => {
    switch (side) {
      case "top":
        return startAtTop
      case "bottom":
        return startAtBottom
      case "left":
        return startAtLeft
      case "right":
        return startAtRight
    }
    return assertUnreachable(side)
  })
  const endPoints: Vec2[] = opts.endSides.map((side) => {
    switch (side) {
      case "top":
        return endAtTop
      case "bottom":
        return endAtBottom
      case "left":
        return endAtLeft
      case "right":
        return endAtRight
    }
    return assertUnreachable(side)
  })

  const scores: [number, RectSide, RectSide, Vec2, Vec2][] = []

  const keepOutZone = 10
  const penalty = 200
  for (let startSideId = 0; startSideId < opts.startSides.length; startSideId++) {
    const startSide = opts.startSides[startSideId]
    const startPoint = startPoints[startSideId]

    for (let endSideId = 0; endSideId < opts.endSides.length; endSideId++) {
      const endSide = opts.endSides[endSideId]
      const endPoint = endPoints[endSideId]

      let score = Math.floor(distanceBetween(startPoint, endPoint))
      if (startSide === "top" && endSide === "bottom") {
        if (startPoint[1] < endPoint[1]) {
          score += Math.max(endPoint[1] - startPoint[1], penalty)
        }
      } else if (startSide === "bottom" && endSide === "top") {
        if (startPoint[1] > endPoint[1]) {
          score += Math.max(startPoint[1] - endPoint[1], penalty)
        }
      } else if (startSide === "left" && endSide === "right") {
        if (startPoint[0] < endPoint[0]) {
          score += Math.max(endPoint[0] - startPoint[0], penalty)
        }
      } else if (startSide === "right" && endSide === "left") {
        if (startPoint[0] > endPoint[0]) {
          score += Math.max(startPoint[0] - endPoint[0], penalty)
        }
      }

      if (
        isPointInBox(endPoint, growBox(startBox, keepOutZone)) ||
        isPointInBox(startPoint, growBox(endBox, keepOutZone))
      ) {
        score += penalty
      }

      scores.push([score, startSide, endSide, startPoint, endPoint])
    }
  }

  scores.sort((a, b) => a[0] - b[0])
  const [, bestStartSide, bestEndSide, bestStartPoint, bestEndPoint] = scores[0]

  const minDistanceToTarget = 50

  const controlPointForStartPoint = controlPointOf(
    bestStartPoint,
    bestEndPoint,
    bestStartSide,
    minDistanceToTarget,
  )
  const controlPointForEndPoint = controlPointOf(
    bestEndPoint,
    bestStartPoint,
    bestEndSide,
    minDistanceToTarget,
  )

  return {
    start: bestStartPoint,
    c1: controlPointForStartPoint,
    c2: controlPointForEndPoint,
    end: bestEndPoint,
    angleEnd: angleOf(bestEndSide),
    angleStart: angleOf(bestStartSide),
    startSide: bestStartSide,
    endSide: bestEndSide,
  }
}

/**
 * Get parameters to draw an S-curved line between two points.
 *
 * @param x0
 * @param y0
 * @param x1
 * @param y1
 * @param userOptions
 * @returns [sx, sy, c1x, c1y, c2x, c2y, ex, ey, ae, as]
 * @example
 * const arrowHeadSize = 9
 * const arrow = getArrow(0, 0, 100, 200, {
 *   padStart: 0,
 *   padEnd: arrowHeadSize,
 * })
 * const [sx, sy, c1x, c1y, c2x, c2y ex, ey, ae, as] = arrow
 */
// export function getArrow(
//   x0: number,
//   y0: number,
//   x1: number,
//   y1: number,
//   userOptions?: ArrowOptions,
// ): ArrowDescriptor {
//   return getBoxToBoxArrow(x0, y0, 0, 0, x1, y1, 0, 0, userOptions)
// }
