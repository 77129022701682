import * as Y from "yjs"
import { CellId, CellInfo } from "./state/types"
import * as evalEngine from "./eval"
import { typeScriptWorker, updateTsWorker } from "./ts"
import { YSync } from "./state/sync"
import { StateManager } from "./state/state"
import { debounce } from "@/packages/util/debounce"
import { EditorState, Extension } from "@codemirror/state"
import { getMarkdownExtensions } from "./markdown"
import { getHttpExtensions } from "./http"
import { yCollab } from "../packages/y-codemirror"
import { EditorView } from "@codemirror/view"
import { JssOptions, YJSpreadsheetBinding } from "../packages/y-jspreadsheet"
import { getTypeScriptExtensions } from "./ts/codemirror"
import { supabase } from "../app/supabase"
import { toast } from "react-toastify"
import { BaseEventObservable } from "@/packages/emitter"
import { EvalOutput } from "./eval"
import { CellOptionsKeys } from "@/engine/state/types"
import { assertUnreachable } from "@/packages/util/assert"
import { getSnippetExtensions } from "./snippet"
import { Encoder } from "@/packages/util/encoding"

export type EvalData = {
  cells: Record<CellId, CellInfo>
  content: Record<CellId, any>
  inputs: Record<CellId, CellId[]>
  options: Record<CellId, Record<string, any>>
  dependencies: Map<CellId, CellId[]>
}

type RunAction = CellOptionsKeys["runAction"]

type EvalEvents = {
  evalStart: { cellId: CellId; runAction: RunAction }
  evalComplete: { cellId: CellId; result: EvalOutput }
}

type EvalState =
  | { state: "idle" }
  | { state: "running"; cellId: CellId }
  | { state: "complete"; cellId: CellId; result: EvalOutput }

export class Engine extends BaseEventObservable<EvalEvents> {
  readonly docId: string

  private invalidatedCells = new Set<CellId>()
  private _evalState: EvalState = { state: "idle" }

  constructor(readonly stateManager: StateManager) {
    super()
    this.docId = stateManager.yDoc.guid
    this.stateManager.on("cellInvalidated", (cellId) => {
      if (cellId) {
        this.invalidatedCells.add(cellId)
        this.updateInvalidatedCells()
      } else {
        this.updateInvalidatedCells.clear()
        this.invalidatedCells.clear()
        updateTsWorker(this.stateManager.buildEvalData())
      }
    })
  }

  init() {
    this.stateManager.init()
    updateTsWorker(this.stateManager.buildEvalData(), true)
  }

  private updateInvalidatedCells = debounce(() => {
    const evalData = this.stateManager.buildEvalData([...this.invalidatedCells])
    console.log("updating", Object.keys(evalData.cells))
    this.invalidatedCells.clear()

    updateTsWorker(evalData)
  }, 500)

  get evalState() {
    return this._evalState
  }

  async evalCell(cellId: CellId): Promise<evalEngine.EvalOutput | undefined> {
    this._evalState = { state: "idle" }

    const cellData = this.stateManager.buildEvalData([cellId])
    console.log(cellData)

    const runAction: RunAction = cellData.options[cellId]?.runAction ?? "eval"

    let evalCellId: CellId
    if (runAction === "fill") {
      if (cellData.inputs[cellId].length !== 1) {
        toast.error("Cell must have exactly one input")
        return
      }
      evalCellId = cellData.inputs[cellId][0]
    } else if (runAction === "eval-chain") {
      // Find the longest dependency chain that includes the requested cell
      evalCellId = Array.from(cellData.dependencies.entries()).reduce<[CellId, number]>(
        (acc, [id, deps]) => {
          if (deps.includes(cellId) && deps.length > acc[1]) {
            return [id, deps.length]
          }
          return acc
        },
        [cellId, 0],
      )[0]
    } else {
      evalCellId = cellId
    }

    this._evalState = { state: "running", cellId }
    this.emitter.emit("evalStart", { cellId, runAction })
    const result = await evalEngine.evalCell(evalCellId, cellData, typeScriptWorker)

    this._evalState = { state: "complete", cellId, result }
    this.emitter.emit("evalComplete", { cellId, result: result })

    if (!result.result.success) {
      toast.error(result.result.error.message)
    } else {
      if (runAction === "fill") {
        this.stateManager.setCellContent(cellId, result.result.output.value)
      }
    }
    return result
  }

  createCellEditor(cellId: CellId, extensions: Extension) {
    const { cellType, content } = this.stateManager.getCellContent(cellId)
    const baseExtensions: Extension[] = []

    switch (cellType) {
      case "code":
        baseExtensions.push(getTypeScriptExtensions(typeScriptWorker, cellId))
        break
      case "text":
        baseExtensions.push(getMarkdownExtensions())
        break
      case "http":
        baseExtensions.push(getHttpExtensions())
        break
      case "snippet": {
        const { options } = this.stateManager.getCellOptions(cellId)
        baseExtensions.push(getSnippetExtensions(options.toJSON()))
        break
      }
      case "prompt":
      case "empty":
      case "table":
        throw new Error(`Cannot create editor for cell type "${cellType}"`)
      default:
        assertUnreachable(cellType)
    }

    if (!(content instanceof Y.Text)) {
      throw new Error("Invalid content type")
    }

    const undoManager = new Y.UndoManager(content)
    baseExtensions.push(yCollab(content, null, { undoManager }))

    return new EditorView({
      state: EditorState.create({
        doc: content.toString(),
        extensions: [baseExtensions, extensions],
      }),
    })
  }

  createTableBinding(cellId: CellId, element: HTMLDivElement, jssOptions?: JssOptions) {
    const { cellType, content } = this.stateManager.getCellContent(cellId)
    if (cellType !== "table") {
      throw new Error(`Cannot create table binding for cell type "${cellType}"`)
    }

    if (!(content instanceof Y.Map)) {
      throw new Error("Invalid content type")
    }

    const { options } = this.stateManager.getCellOptions(cellId)

    return new YJSpreadsheetBinding(
      this.stateManager.yDoc as Y.Doc,
      content,
      options,
      element,
      jssOptions,
    )
  }

  destroy() {
    this.updateInvalidatedCells.clear()
    this.stateManager.destroy()
  }
}

export function createEngine(docId: string, p2pKey: string, encoder: Encoder | null) {
  return new Engine(
    new StateManager(
      new YSync(
        new Y.Doc({
          guid: docId,
        }),
        p2pKey,
        supabase,
        encoder,
      ),
    ),
  )
}
