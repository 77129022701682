import { useLayoutEffect, useMemo, useState } from "react"
import HttpResponseView from "./HttpResponseView"
import { HttpResponse } from "@/engine/http/response"
import ObjectInspector from "@/packages/inspector/ObjectInspector"
import { TableWrapper } from "@/engine/tables/table"
import { ClipboardCopyIcon } from "lucide-react"
import ElementFrame from "./ElementFrame"
import { twJoin } from "tailwind-merge"

export interface DataRendererProps {
  data: any
  autoExpandLevel?: number
}

type Tab = "inspect" | "view" | "raw"

export default function DataRenderer({ data, autoExpandLevel = 1 }: DataRendererProps) {
  const value = useMemo(() => {
    if (data instanceof HttpResponse) {
      try {
        return data.json()
      } catch (e) {
        return data
      }
    } else {
      return data
    }
  }, [data])

  const stringValue = useMemo(() => {
    try {
      if (
        data instanceof TableWrapper ||
        data instanceof HttpResponse ||
        typeof data !== "object"
      ) {
        return String(data)
      } else if (data instanceof Element) {
        return data.outerHTML
      } else {
        return JSON.stringify(data)
      }
    } catch (e) {
      return String(e)
    }
  }, [data])

  const [isRenderable, isInspectable, isHttpResponse] = useMemo(
    () => [
      data instanceof Element,
      typeof value !== "string" && !(value instanceof Element),
      data instanceof HttpResponse,
    ],
    [data, value],
  )

  const [tab, setTab] = useState<Tab>("inspect")

  useLayoutEffect(() => {
    if (tab === "raw") {
      return
    }
    if (isRenderable) {
      setTab("view")
    } else if (isInspectable) {
      setTab("inspect")
    } else {
      setTab("raw")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  async function copyValue() {
    try {
      await navigator.clipboard.writeText(stringValue)
    } catch (err) {
      console.error("Failed to copy: ", err)
    }
  }

  return (
    <div className="relative">
      <nav className="mb-2 flex space-x-4">
        {isInspectable && (
          <button
            onClick={() => setTab("inspect")}
            className={twJoin(
              tab === "inspect" ? "bg-gray-400 text-gray-900" : "text-gray-500 hover:text-gray-400",
              "rounded-md px-2 py-1 text-xs font-medium",
            )}
          >
            Inspect
          </button>
        )}
        {isRenderable && (
          <button
            onClick={() => setTab("view")}
            className={twJoin(
              tab === "view" ? "bg-gray-400 text-gray-900" : "text-gray-500 hover:text-gray-400",
              "rounded-md px-2 py-1 text-xs font-medium",
            )}
          >
            View
          </button>
        )}
        <button
          onClick={() => setTab("raw")}
          className={twJoin(
            tab === "raw" ? "bg-gray-400 text-gray-900" : "text-gray-500 hover:text-gray-400",
            "rounded-md px-2 py-1 text-xs font-medium",
          )}
        >
          Raw
        </button>
      </nav>
      {tab === "inspect" && (
        <ObjectInspector className="font-mono text-xs" data={value} expandLevel={autoExpandLevel} />
      )}
      {tab === "view" && <ElementFrame element={data} />}
      {tab === "raw" && !isHttpResponse && (
        <pre className="w-full resize-none whitespace-pre-wrap text-wrap break-words border-none bg-transparent font-mono text-xs">
          {stringValue}
        </pre>
      )}
      {tab === "raw" && isHttpResponse && (
        <HttpResponseView content={stringValue} className="font-mono text-xs" />
      )}
      <button
        onClick={copyValue}
        className="absolute right-0 top-0 h-6 w-6 rounded p-1 text-gray-400 hover:bg-slate-900/20 hover:text-gray-200"
      >
        <ClipboardCopyIcon strokeWidth={1.5} className="h-4 w-4" />
      </button>
    </div>
  )
}
