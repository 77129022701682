import { Outlet, useMatchRoute, useNavigate } from "@tanstack/react-router"
import { useEffect, useCallback } from "react"
import { documentRoute } from "../../routes"
import Board from "../board/Board"
import Statusbar from "./Statusbar"
import ToolbarDesktop from "./ToolbarDesktop"

export default function DocumentDesktop() {
  const matchRoute = useMatchRoute()
  const hasSubRoute = !matchRoute({ to: documentRoute.fullPath })

  return (
    <div className="flex h-full w-full touch-none flex-row">
      <div className="relative flex flex-1 flex-col overflow-hidden">
        <div className="relative min-h-0 w-full flex-1">
          <Board />
          <DocumentOverlay show={hasSubRoute} />
        </div>
        <div className="box-content flex-initial">
          <Statusbar />
        </div>
      </div>
      <div className="flex-initial">
        <ToolbarDesktop />
      </div>
    </div>
  )
}

function DocumentOverlay({ show }: { show: boolean }) {
  const { slug } = documentRoute.useParams()
  const navigate = useNavigate()

  const close = useCallback(() => {
    navigate({ to: "/g/$slug", params: { slug } })
  }, [navigate, slug])

  useEffect(() => {
    if (!show) return
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        close()
        e.preventDefault()
        e.stopImmediatePropagation()
      }
    }
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [show, close])

  return (
    show && (
      <div className="absolute inset-0 z-[1000] mx-auto p-0 sm:p-6 lg:p-8">
        <div className="absolute inset-0 bg-black bg-opacity-40 backdrop-blur" onClick={close} />
        <div className="relative mx-auto h-full max-w-5xl">
          <Outlet />
        </div>
      </div>
    )
  )
}
