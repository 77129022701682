import { EditorState, Extension } from "@codemirror/state"
import { closeBrackets, autocompletion } from "@codemirror/autocomplete"
import { PostgreSQL, sql, SQLDialect, SQLite, StandardSQL } from "@codemirror/lang-sql"
import {
  indentOnInput,
  syntaxHighlighting,
  defaultHighlightStyle,
  bracketMatching,
  foldGutter,
} from "@codemirror/language"
import { highlightSelectionMatches } from "@codemirror/search"
import {
  highlightActiveLineGutter,
  drawSelection,
  dropCursor,
  rectangularSelection,
  crosshairCursor,
  keymap,
  EditorView,
  lineNumbers,
  highlightSpecialChars,
  highlightActiveLine,
} from "@codemirror/view"
import { vscodeKeymap } from "@/packages/codemirror/keymap"
import { json } from "@codemirror/lang-json"
import { getCodeFormater } from "@/packages/codemirror/formatter"

export function getSnippetExtensions(options: any): Extension {
  const extensions = [
    lineNumbers(),
    highlightActiveLineGutter(),
    highlightSpecialChars(),
    foldGutter(),
    drawSelection(),
    dropCursor(),
    EditorState.allowMultipleSelections.of(true),
    indentOnInput(),
    syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
    bracketMatching(),
    closeBrackets(),
    autocompletion({ defaultKeymap: false }),
    rectangularSelection(),
    crosshairCursor(),
    highlightActiveLine(),
    highlightSelectionMatches(),
    keymap.of(vscodeKeymap),
    EditorView.lineWrapping,
  ]

  if (options.language === "sql") {
    let dialect: SQLDialect
    switch (options.dialect) {
      case "sqlite":
        dialect = SQLite
        break
      case "postgres":
        dialect = PostgreSQL
        break
      default:
        dialect = StandardSQL
    }
    extensions.push(sql({ dialect, upperCaseKeywords: false }))
  } else if (options.language === "json") {
    extensions.push(json())
    extensions.push(
      keymap.of([
        { key: "Meta-Alt-l", run: getCodeFormater("json"), preventDefault: true },
        { key: "Alt-Shift-f", run: getCodeFormater("json"), preventDefault: true },
      ]),
    )
  }

  return extensions
}
