import usePageVisibility from "@/packages/components/usePageVisibility"
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useRegisterSW } from "virtual:pwa-register/react"

type UpdateStatus = "unknown" | "loading" | "current" | "outdated" | "error"

interface IServiceWorkerContext {
  checkForUpdate: () => Promise<void>
  updateServiceWorker: () => Promise<void>
  updateStatus: UpdateStatus
  buildVersion: string
}

export const ServiceWorkerContext = createContext<IServiceWorkerContext>({
  checkForUpdate: async () => {},
  updateServiceWorker: async () => {},
  updateStatus: "unknown",
  buildVersion: "",
})

export function ServiceWorkerProvider({ children }: { children: React.ReactNode }) {
  const [status, setStatus] = useState<UpdateStatus>("unknown")
  const pageVisibility = usePageVisibility()

  const registration = useRef<ServiceWorkerRegistration>()

  const registerSW = useRegisterSW({
    onRegistered(r) {
      console.log("SW Registered")
      setStatus("loading")
      if (!r) return

      registration.current = r
      checkForUpdate()
    },
    onRegisterError(error) {
      console.log("SW registration error", error)
      setStatus("error")
    },
  })

  const checkForUpdate = useCallback(async () => {
    const r = registration.current
    if (!r) return

    try {
      await r.update()
    } catch (e) {
      setStatus("error")
      throw e
    }

    setStatus(r.waiting || r.installing ? "outdated" : "current")
  }, [])

  const updateServiceWorker = useCallback(async () => {
    registerSW.updateServiceWorker()
  }, [registerSW])

  const buildVersion = useMemo(() => {
    const date = new Date(BUILD_TIME)
    return `${BUILD_BRANCH || "local"}-${BUILD_HASH?.substring(0, 7) || "dev"}-${date.getUTCFullYear().toString().substring(2)}${(date.getUTCMonth() + 1).toString().padStart(2, "0")}${date.getUTCDate().toString().padStart(2, "0")}${date.getUTCHours().toString().padStart(2, "0")}${date.getUTCMinutes().toString().padStart(2, "0")}`
  }, [])

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        checkForUpdate()
      },
      1000 * 60 * 30, // 30 minutes
    )

    return () => clearInterval(intervalId)
  }, [checkForUpdate])

  useEffect(() => {
    if (!pageVisibility) return
    checkForUpdate()
  }, [pageVisibility, checkForUpdate])

  return (
    <ServiceWorkerContext.Provider
      value={{
        updateStatus: status,
        checkForUpdate: checkForUpdate,
        updateServiceWorker: updateServiceWorker,
        buildVersion,
      }}
    >
      {children}
    </ServiceWorkerContext.Provider>
  )
}

export function useServiceWorker() {
  return useContext(ServiceWorkerContext)
}
