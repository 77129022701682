import { useRef, useState, useLayoutEffect } from "react"

export default function ElementFrame({ element }: { element: Element }) {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeLoaded, setIframeLoaded] = useState(false)

  const initialContent = `<!DOCTYPE html><html><head><script src="https://cdn.tailwindcss.com"></script></head><body><div id="frameRoot"></div></body></html>`

  useLayoutEffect(() => {
    const resizer = new IFrameResizer()
    if (iframeRef.current) {
      resizer.start(iframeRef.current)
    }
    return () => {
      resizer.stop()
    }
  }, [])

  useLayoutEffect(() => {
    if (iframeLoaded && iframeRef.current) {
      const iframe = iframeRef.current
      const container = iframe.contentDocument?.querySelector("#frameRoot")
      if (container) {
        container.innerHTML = ""
        container.appendChild(element)
      }
    }
  }, [iframeLoaded, element])

  return (
    <iframe
      title="component"
      width="100%"
      ref={iframeRef}
      srcDoc={initialContent}
      onLoad={() => setIframeLoaded(true)}
    />
  )
}

class IFrameResizer {
  private running = false
  iframe: HTMLIFrameElement | null = null

  start(iframe: HTMLIFrameElement) {
    this.iframe = iframe
    this.running = true
    requestAnimationFrame(this.fit)
  }

  stop() {
    this.running = false
  }

  fit = () => {
    if (!this.iframe || !this.running) {
      return
    }
    const win = this.iframe?.contentWindow
    const doc = this.iframe?.contentDocument?.documentElement
    if (win && doc) {
      const style = win.getComputedStyle(doc)
      this.iframe.height = style.getPropertyValue("height")
    }

    requestAnimationFrame(this.fit)
  }
}
