import { supabase } from "@/app/supabase"
import { useOnMountUnsafe } from "@/packages/components/useOnMountUnsafe"
import { Session } from "@supabase/supabase-js"
import { createContext, useContext, useState } from "react"

export interface User {
  session: Session
}

export interface Auth {
  isAuthenticated: boolean
  setUser: (user: User | null) => void
  user: User | null
}

const AuthContext = createContext<Auth | null>(null)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [sessionLoaded, setSessionLoaded] = useState(false)
  const [user, setUser] = useState<User | null>(null)
  const isAuthenticated = !!user

  useOnMountUnsafe(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSessionLoaded(true)
      if (session !== null) {
        setUser({ session })
      }
    })
  })

  if (!sessionLoaded) {
    return null
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}
