import { DependencyList, MutableRefObject, useMemo, useRef } from "react"

const registry = new FinalizationRegistry((cleanupRef: MutableRefObject<any>) => {
  cleanupRef.current?.() // cleanup on unmount
})

/** useMemoCleanup
 * A version of useMemo that allows cleanup.
 * Return a tuple from the callback: [returnValue, cleanupFunction]
 * https://stackoverflow.com/questions/66446642/react-usememo-memory-clean
 * */
export default function useMemoCleanup<T>(factory: () => [T, () => void], deps: DependencyList): T {
  const cleanupRef = useRef<(() => void) | null>(null) // holds a cleanup value
  const unmountRef = useRef(false) // the GC-triggering candidate

  if (!unmountRef.current) {
    unmountRef.current = true
    // this works since refs are preserved for the component's lifetime
    registry.register(unmountRef, cleanupRef)
  }

  const returned = useMemo(() => {
    cleanupRef.current?.()
    cleanupRef.current = null

    const [returned, cleanup] = factory()
    cleanupRef.current = typeof cleanup === "function" ? cleanup : null

    return returned
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return returned
}
