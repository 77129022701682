import { CellId } from "@/engine/state/types"
import { useAppSelector } from "@/app/store"
import { ReactDOMAttributes } from "@use-gesture/react/dist/declarations/src/types"
import {
  selectCellById,
  selectCellLayoutById,
  selectCellOptionsById,
  selectCells,
  selectConnectedCellsById,
} from "@/app/store/cellsSlice"
import { useMemo, useState } from "react"
import { useEngine } from "../document/EngineContext"
import { useNavigate } from "@tanstack/react-router"
import { cellRoute, documentRoute } from "@/app/ui/routes"
import {
  Code2Icon,
  ExpandIcon,
  FastForwardIcon,
  FileIcon,
  GitCompareArrowsIcon,
  GlobeIcon,
  ImportIcon,
  LayoutGridIcon,
  LucideIcon,
  MinusCircleIcon,
  MoreVertical,
  NotepadTextIcon,
  PlayIcon,
  PlusCircleIcon,
  SheetIcon,
  Trash2Icon,
} from "lucide-react"
import { Menu, MenuItem, MenuTrigger, Submenu, SubmenuTrigger } from "@/app/ui/components/Menu"
import { twJoin } from "tailwind-merge"
import { CellOptionsKeys } from "@/engine/state/types"
import { Button, IconButton } from "../../components/Button"
import { transferCellToDocument } from "@/engine/transfer"
import { toast } from "react-toastify"

interface CellHeaderProps {
  cellId: CellId
  className?: string
  fullScreen?: boolean
  eventHandlers?: ReactDOMAttributes
}

export default function CellHeader({
  cellId,
  fullScreen,
  className,
  eventHandlers,
}: CellHeaderProps) {
  const engine = useEngine()
  const navigate = useNavigate({ from: documentRoute.fullPath })

  const cell = useAppSelector((state) => selectCellById(state, cellId))
  const cells = useAppSelector(selectCells)
  const connectedCells = useAppSelector((state) => selectConnectedCellsById(state, cellId))
  const cellOptions = useAppSelector((state) => selectCellOptionsById(state, cellId))
  const cellLayout = useAppSelector((state) => selectCellLayoutById(state, cellId))

  const RunIcon = useMemo(() => {
    const runAction: CellOptionsKeys["runAction"] = cellOptions?.runAction || "eval"
    switch (runAction) {
      case "fill":
        return ImportIcon
      case "eval-chain":
        return FastForwardIcon
      default:
        return PlayIcon
    }
  }, [cellOptions])

  const setRunAction = (action: CellOptionsKeys["runAction"]) => {
    engine.stateManager.setCellRunAction(cell.id, action)
  }

  const evalCell = async () => {
    const result = await engine.evalCell(cell.id)
    console.log("result", result)
  }

  const deleteCell = () => {
    engine.stateManager.deleteCell(cell.id)
  }

  const connectedCellCount = connectedCells.input.length + connectedCells.output.length

  const removeInput = (from: CellId) => {
    engine.stateManager.removeCellInput(cell.id, from)
    return connectedCellCount > 1
  }

  const removeOutput = (to: CellId) => {
    engine.stateManager.removeCellInput(to, cell.id)
    return connectedCellCount > 1
  }

  const addInput = (from: CellId) => {
    engine.stateManager.addCellInput(cell.id, from)
  }

  const openFullscreen = () => {
    navigate({ to: cellRoute.fullPath, params: (prev) => ({ ...prev, cellId }) })
  }

  const transferCell = () => {
    const target = prompt("Enter target document slug")
    if (!target) return

    try {
      transferCellToDocument(cell.id, engine.stateManager, target)
    } catch (e: any) {
      console.error(e)
      toast.error("Failed to transfer cell: " + (e?.message || e))
    }
  }

  const [cellName, setCellName] = useState(cell.name || "")
  const [isEditingName, setIsEditingName] = useState(false)
  const handleNameChange = () => {
    if (cellName !== cell.name) {
      let newName: string | undefined = cellName.trim()
      if (!newName) {
        newName = undefined
      }
      engine.stateManager.setCellName(cell.id, newName)
    }
    setIsEditingName(false)
  }

  const handleToggleArchived = () => {
    if (cellLayout.type === "archived") {
      engine.stateManager.updateCellLayout(cell.id, {
        type: "absolute",
        x: cellLayout.x,
        y: cellLayout.y,
        width: cellLayout.width,
        height: cellLayout.height,
      })
    } else {
      engine.stateManager.updateCellLayout(cell.id, {
        ...cellLayout,
        type: "archived",
        archivedAt: Date.now(),
      })
    }
  }

  let Icon: LucideIcon
  switch (cell.type) {
    case "code":
      Icon = Code2Icon
      break
    case "table":
      Icon = SheetIcon
      break
    case "text":
      Icon = NotepadTextIcon
      break
    case "http":
      Icon = GlobeIcon
      break
    case "snippet":
      Icon = FileIcon
      break
    default:
      Icon = LayoutGridIcon
      break
  }

  return (
    <div
      className={twJoin(
        "flow-row relative flex select-none items-center pl-1 pr-2 text-sm text-gray-400",
        className,
      )}
    >
      <div className="absolute inset-0 cursor-move touch-none" {...eventHandlers} />
      <Icon className="mr-2 h-4 w-4 min-w-4 flex-initial" />
      <span className="flex flex-auto items-center overflow-hidden">
        {isEditingName ? (
          <input
            className="relative flex-auto bg-transparent p-0 pr-1 text-sm"
            autoFocus={isEditingName}
            autoCapitalize="none"
            autoCorrect="off"
            onChange={(e) => setCellName(e.target.value)}
            onBlur={handleNameChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleNameChange()
              }
            }}
            value={cellName}
          />
        ) : (
          <>
            <span
              className={twJoin("relative truncate pb-[1px]", cell.name || "italic text-gray-600")}
              title={cell.name}
              onDoubleClick={() => {
                setCellName(cell.name || "")
                setIsEditingName(true)
              }}
            >
              {cell.name || "untitled"}
            </span>
            <span className="ligatures-none mx-1 flex-initial whitespace-nowrap text-xs text-gray-600">
              (id: {cell.id})
            </span>
          </>
        )}
      </span>

      <div className={twJoin("relative flex items-center", fullScreen ? "gap-2" : "gap-1")}>
        {!fullScreen && (
          <IconButton onPress={openFullscreen} className="hover:text-blue-600">
            <ExpandIcon className="h-4 w-4" strokeWidth={1.5} />
          </IconButton>
        )}

        <MenuTrigger>
          <IconButton className="hover:text-blue-600">
            <GitCompareArrowsIcon className="h-4 w-4" strokeWidth={1.5} />
          </IconButton>
          <Menu>
            <SubmenuTrigger>
              <MenuItem>Add Input</MenuItem>
              <Submenu className="max-w-44">
                {Object.values(cells)
                  .filter((e) => e.id !== cellId)
                  .map((inputCell) => (
                    <MenuItem
                      className="max-w-44"
                      key={inputCell.id}
                      onAction={() => {
                        addInput(inputCell.id)
                      }}
                    >
                      <span className="truncate">{inputCell.name}</span>&nbsp;(id: {inputCell.id})
                    </MenuItem>
                  ))}
              </Submenu>
            </SubmenuTrigger>

            {connectedCells.input.map((connectedCell) => (
              <MenuItem
                key={connectedCell.id}
                onAction={() => {
                  removeInput(connectedCell.id)
                }}
                className="flex w-full items-center truncate px-3 py-2 text-xs text-gray-400"
              >
                <MinusCircleIcon className="mr-1 h-4 w-4" strokeWidth={1.5} />
                IN: {connectedCell.name} (id: {connectedCell.id})
              </MenuItem>
            ))}
            {connectedCells.output.map((connectedCell) => (
              <MenuItem
                key={connectedCell.id}
                onAction={() => {
                  removeOutput(connectedCell.id)
                }}
                className="flex w-full items-center truncate px-3 py-2 text-xs text-gray-400"
              >
                <MinusCircleIcon className="mr-1 h-4 w-4" />
                OUT: {connectedCell.name} (id: {connectedCell.id})
              </MenuItem>
            ))}
          </Menu>
        </MenuTrigger>

        <IconButton onPress={evalCell} className="hover:text-blue-600">
          <RunIcon className="h-4 w-4" strokeWidth={1.5} />
        </IconButton>

        <MenuTrigger>
          <IconButton className="hover:text-blue-600" aria-label="">
            <MoreVertical className="h-4 w-4" strokeWidth={1.5} />
          </IconButton>
          <Menu>
            <SubmenuTrigger>
              <MenuItem textValue="Run Action">Run Action</MenuItem>
              <Submenu>
                <MenuItem
                  textValue="Eval"
                  onAction={() => setRunAction("eval")}
                  className="flex w-full px-3 py-2"
                >
                  Eval
                </MenuItem>
                <MenuItem
                  textValue="Fill"
                  onAction={() => setRunAction("fill")}
                  className="flex w-full px-3 py-2"
                >
                  Fill
                </MenuItem>
                <MenuItem
                  textValue="Chain"
                  onAction={() => setRunAction("eval-chain")}
                  className="flex w-full px-3 py-2"
                >
                  Eval Chain
                </MenuItem>
              </Submenu>
            </SubmenuTrigger>

            <MenuItem
              textValue="Archive"
              onAction={handleToggleArchived}
              className="flex w-full px-3 py-2"
            >
              {cellLayout.type === "archived" ? "Unarchive" : "Archive"}
            </MenuItem>

            <MenuItem
              textValue="Transfer"
              onAction={transferCell}
              className="flex w-full px-3 py-2"
            >
              Transfer
            </MenuItem>

            <MenuItem
              textValue="Delete"
              onAction={deleteCell}
              className="flex w-full px-3 py-2 hover:text-red-600"
            >
              <Trash2Icon className="mr-2 h-4 w-4" strokeWidth={1.5} />
              Delete
            </MenuItem>
          </Menu>
        </MenuTrigger>
      </div>
    </div>
  )
}
