import { EditorState, Extension } from "@codemirror/state"
import { markdown, markdownLanguage } from "@codemirror/lang-markdown"
import { liquidCompletionSource, liquidLanguage } from "@codemirror/lang-liquid"
import { languages } from "@codemirror/language-data"
import { decorateMarkdown } from "@/packages/codemirror/markdown-preview"
import { liquidExtension } from "@/packages/codemirror/markdown-liquid"
import { closeBrackets, autocompletion } from "@codemirror/autocomplete"
import {
  indentOnInput,
  syntaxHighlighting,
  defaultHighlightStyle,
  bracketMatching,
} from "@codemirror/language"
import { highlightSelectionMatches } from "@codemirror/search"
import {
  highlightActiveLineGutter,
  drawSelection,
  dropCursor,
  rectangularSelection,
  crosshairCursor,
  keymap,
  EditorView,
} from "@codemirror/view"
import { vscodeKeymap } from "@/packages/codemirror/keymap"

export function getMarkdownExtensions(): Extension {
  return [
    highlightActiveLineGutter(),
    // history(),
    drawSelection(),
    dropCursor(),
    EditorState.allowMultipleSelections.of(true),
    EditorView.contentAttributes.of({
      spellcheck: "true",
      autocorrect: "on",
      autocapitalize: "on",
    }),
    indentOnInput(),
    syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
    bracketMatching(),
    closeBrackets(),
    autocompletion({ defaultKeymap: false }),
    rectangularSelection(),
    crosshairCursor(),
    highlightSelectionMatches(),
    keymap.of(vscodeKeymap),
    EditorView.lineWrapping,
    markdown({
      base: markdownLanguage,
      codeLanguages: languages,
      extensions: [liquidExtension],
      addKeymap: true,
    }),
    markdownLanguage.data.of({
      closeBrackets: { brackets: ["(", "[", "{", "'", '"', "`", "```", "*", "**", "_", "__"] },
    }),
    liquidLanguage.data.of({
      autocomplete: liquidCompletionSource(),
    }),
    decorateMarkdown(),
  ]
}
