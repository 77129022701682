import {
  createContext,
  forwardRef,
  Ref,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import { useQuery } from "@tanstack/react-query"
import { getDocumentsQuery, getFolderDocumentsQuery, getFoldersQuery } from "@/app/supabase"
import { Modal } from "../components/Modal"
import { ListBox, ListBoxItem } from "../components/ListBox"
import { useRouter } from "@tanstack/react-router"
import fuzzysort from "fuzzysort"
import DOMPurify from "dompurify"
import {
  Dialog as AriaDialog,
  SearchField as AriaSearchField,
  Input as AriaInput,
  Group as AriaGroup,
} from "react-aria-components"
import { SearchIcon, XIcon } from "lucide-react"
import { Button } from "../components/Button"

export interface ICommandPaletteContext {
  show: (command?: string) => void
}

export const CommandPaletteContext = createContext<ICommandPaletteContext>(null!)

export function useCommandPalette() {
  return useContext(CommandPaletteContext)
}

export const CommandPaletteProvider = ({ children }: { children: React.ReactNode }) => {
  const commandPaletteRef = useRef<CommandPaletteHandle>(null)

  useCommandPaletteShortcut({
    onShow: () => {
      commandPaletteRef.current?.show()
    },
  })

  return (
    <CommandPaletteContext.Provider
      value={{
        show: (command) => {
          commandPaletteRef.current?.show(command)
        },
      }}
    >
      {children}
      <CommandPalette ref={commandPaletteRef} />
    </CommandPaletteContext.Provider>
  )
}

interface CommandPaletteHandle {
  show: (command?: string) => void
}

const CommandPalette = forwardRef(function CommandPalette(_, ref: Ref<CommandPaletteHandle>) {
  const router = useRouter()

  const [open, setOpen] = useState(false)
  const [command, setCommand] = useState("")
  const [selectedIndex, setSelectedIndex] = useState(0)

  const { documentList } = useDocumentList()

  useImperativeHandle(ref, () => ({
    show(command?: string) {
      setCommand(command || "")
      setOpen(true)
      setSelectedIndex(0)
    },
  }))

  const filteredDocumentList = useMemo(() => {
    setSelectedIndex(0)
    const result = fuzzysort.go(command, documentList, {
      key: "pathString",
      all: true,
    })

    return result.map((item) => ({
      label: DOMPurify.sanitize(item.highlight(), { ALLOWED_TAGS: ["b"] }),
      value: item.obj,
    }))
  }, [documentList, command])

  const openDocument = (slug: string) => {
    router.navigate({ to: "/g/$slug", params: { slug } })
    setOpen(false)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      e.preventDefault()
      setOpen(false)
    } else if (e.key === "ArrowDown") {
      e.preventDefault()
      setSelectedIndex((i) => Math.min(i + 1, filteredDocumentList.length - 1))
    } else if (e.key === "ArrowUp") {
      e.preventDefault()
      setSelectedIndex((i) => Math.max(i - 1, 0))
    }
  }

  return (
    <Modal isOpen={open} isDismissable onOpenChange={setOpen} className="items-start">
      <AriaDialog aria-label="Command Palette" className="flex flex-col overflow-hidden">
        <AriaSearchField
          aria-label="Search documents"
          autoFocus
          value={command}
          onChange={setCommand}
          onKeyDown={handleKeyDown}
          className="group flex min-w-[40px] flex-col gap-1"
          onSubmit={() => {
            if (filteredDocumentList.length) {
              openDocument(filteredDocumentList[selectedIndex].value.slug)
            }
          }}
        >
          <AriaGroup className="group flex h-9 items-center overflow-hidden">
            <SearchIcon
              aria-hidden
              className="ml-2.5 h-4 w-4 text-zinc-400 group-disabled:text-zinc-600"
            />
            <AriaInput className="min-w-0 flex-1 border-0 bg-transparent px-2 py-1.5 text-sm text-zinc-200 disabled:text-zinc-600 [&::-webkit-search-cancel-button]:hidden" />
            <Button variant="icon" className="mr-1 w-6 group-empty:invisible">
              <XIcon aria-hidden className="h-4 w-4" />
            </Button>
          </AriaGroup>
        </AriaSearchField>
        <ListBox
          aria-label="Select target folder"
          className="flex-1 overflow-y-auto"
          renderEmptyState={() => <div className="h-14 text-center text-sm">No results found.</div>}
          selectedKeys={[filteredDocumentList[selectedIndex]?.value.id]}
          onSelectionChange={(keys) => {
            const id = (keys as Set<string>).values().next().value
            if (!id) return
            const selected = filteredDocumentList.find((item) => item.value.id === id)
            if (!selected) return
            openDocument(selected.value.slug)
          }}
          selectionMode="single"
          shouldFocusWrap
        >
          {filteredDocumentList.map((item, index) => (
            <ListBoxItem
              aria-label={item.value.name}
              id={item.value.id}
              key={item.value.id}
              onAction={() => openDocument(item.value.slug)}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: item.label,
                }}
              />
            </ListBoxItem>
          ))}
        </ListBox>
      </AriaDialog>
    </Modal>
  )
})

export function useDocumentList() {
  const { data: documents } = useQuery(getDocumentsQuery())
  const { data: folders } = useQuery(getFoldersQuery())
  const { data: folderDocuments } = useQuery(getFolderDocumentsQuery())

  const documentList = useMemo(() => {
    if (!documents || !folders || !folderDocuments) return []

    const buildPath = (folderId: string, chain: string[] = []): string[] => {
      const folder = folders.find((f) => f.id === folderId)
      if (!folder || chain.includes(folderId)) return []

      if (folder.parent_id) {
        return [...buildPath(folder.parent_id, [...chain, folder.id])]
      } else {
        return [folder.name]
      }
    }

    return documents
      .map((doc) => {
        const folderDocument = folderDocuments.find((fd) => fd.document_id === doc.id)
        const path = folderDocument ? buildPath(folderDocument.folder_id) : []
        return {
          ...doc,
          path: path,
          pathString: path.join("/") + "/" + doc.name,
        }
      })
      .sort((a, b) => a.pathString.localeCompare(b.pathString))
  }, [documents, folders, folderDocuments])

  return { documentList }
}

function useCommandPaletteShortcut({ onShow }: { onShow: () => void }) {
  useEffect(() => {
    let shiftDown = false
    let lastShiftUp = 0

    const handleKeyDown = (e: KeyboardEvent) => {
      shiftDown = e.key === "Shift" && e.shiftKey
    }

    const handleKeyUp = (e: KeyboardEvent) => {
      if (!shiftDown || e.key !== "Shift") {
        shiftDown = false
        lastShiftUp = 0
        return
      }

      const now = Date.now()
      if (now - lastShiftUp < 500) {
        shiftDown = false
        lastShiftUp = 0
        onShow()
      } else {
        lastShiftUp = now
      }
    }

    window.addEventListener("keydown", handleKeyDown)
    window.addEventListener("keyup", handleKeyUp)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
      window.removeEventListener("keyup", handleKeyUp)
    }
  }, [onShow])
}
