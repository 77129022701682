import { FormEvent, useState } from "react"
import { createDocumentApi } from "@/app/supabase"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useNavigate, useRouter } from "@tanstack/react-router"
import { toast } from "react-toastify"

export default function CreateDocument() {
  const navigate = useNavigate()
  const router = useRouter()
  const queryClient = useQueryClient()

  const { mutate: create } = useMutation({
    mutationFn: createDocumentApi,
    onSuccess({ data }) {
      queryClient.invalidateQueries({ queryKey: ["documents"] })
      navigate({ to: "/g/$slug", params: { slug: data!.slug }, replace: true })
    },
    onError(err) {
      console.error(err)
      toast.error("Failed to create document: " + err.message)
    },
  })

  const [name, setName] = useState("")
  const [slug, setSlug] = useState("")
  const [passphrase, setPassphrase] = useState("")
  const [isSlugGenerated, setIsSlugGenerated] = useState(true)

  const handleNameChange = (e: FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value)
    if (isSlugGenerated) {
      setSlug(e.currentTarget.value.toLowerCase().replace(/\s/g, "-"))
    }
  }

  const handleSlugChange = (e: FormEvent<HTMLInputElement>) => {
    setSlug(e.currentTarget.value)
    setIsSlugGenerated(false)
  }

  const handleCancel = () => {
    router.history.back()
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (!name || !slug) {
      return
    }

    create({
      name: name,
      slug: slug,
      folderId: null,
      passphrase: passphrase,
    })
  }

  return (
    <div className="blueprint-background h-dvh w-full overflow-auto">
      <form
        className="min-h-full max-w-3xl rounded border border-slate-900 border-slate-950/40 bg-cell px-6 py-10 sm:mx-auto sm:min-h-0"
        onSubmit={handleSubmit}
      >
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-300">Create Document</h2>
          <div className="mt-6 grid grid-cols-1 gap-y-5 sm:grid-cols-4">
            <div className="sm:col-span-4">
              <label htmlFor="name" className="block text-sm leading-4 text-gray-300">
                Document Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="slug" className="block text-sm leading-4 text-gray-300">
                Document Slug
              </label>
              <div className="mt-2">
                <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                  <span className="flex select-none items-center whitespace-nowrap pl-3 text-gray-500 sm:text-sm">
                    typegrid.app/g/
                  </span>
                  <input
                    type="text"
                    name="slug"
                    id="slug"
                    autoCapitalize="none"
                    autoCorrect="off"
                    className="flex-1 border-0 bg-transparent py-1.5 pl-1 text-white focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="slug"
                    value={slug}
                    onChange={handleSlugChange}
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="passphrase" className="block text-sm leading-4 text-gray-300">
                Passphrase (optional)
              </label>
              <div className="mt-2">
                <input
                  id="passphrase"
                  name="passphrase"
                  type="password"
                  autoComplete="off"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  value={passphrase}
                  onChange={(e) => setPassphrase(e.currentTarget.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-300"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-gray-300 shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  )
}
