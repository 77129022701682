import { closeBrackets, autocompletion } from "@codemirror/autocomplete"
import {
  foldGutter,
  indentOnInput,
  syntaxHighlighting,
  defaultHighlightStyle,
  bracketMatching,
} from "@codemirror/language"
import { highlightSelectionMatches } from "@codemirror/search"
import { EditorState, Extension } from "@codemirror/state"
import {
  lineNumbers,
  highlightActiveLineGutter,
  highlightSpecialChars,
  drawSelection,
  dropCursor,
  rectangularSelection,
  crosshairCursor,
  highlightActiveLine,
  keymap,
  EditorView,
} from "@codemirror/view"
import { http } from "@/packages/codemirror/lang-http"
import { vscodeKeymap } from "@/packages/codemirror/keymap"

export function getHttpExtensions(): Extension {
  return [
    lineNumbers(),
    highlightActiveLineGutter(),
    highlightSpecialChars(),
    // history(),
    foldGutter(),
    drawSelection(),
    dropCursor(),
    EditorState.allowMultipleSelections.of(true),
    indentOnInput(),
    syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
    bracketMatching(),
    closeBrackets(),
    rectangularSelection(),
    crosshairCursor(),
    highlightActiveLine(),
    highlightSelectionMatches(),
    autocompletion({ defaultKeymap: false }),
    keymap.of(vscodeKeymap),
    EditorView.lineWrapping,
    http(),
  ]
}
