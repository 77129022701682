import { FormEvent, useState } from "react"
import { updateDocumentApi, getDocumentBySlugQuery } from "@/app/supabase"
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"
import { toast } from "react-toastify"
import { editDocumentRoute } from "../../routes"

type Props = unknown

export default function EditDocument(_: Props) {
  const { slug } = editDocumentRoute.useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data: document } = useSuspenseQuery(getDocumentBySlugQuery(slug))

  const [name, setName] = useState(document?.name || "")
  const [newSlug, setNewSlug] = useState(document?.slug || "")

  const { mutate: save } = useMutation({
    mutationFn: updateDocumentApi,
    onSuccess({ data }) {
      queryClient.invalidateQueries({ queryKey: ["documents"] })
      navigate({ to: "/g/$slug", params: { slug: newSlug } })
    },
    onError(err) {
      console.error(err)
      toast.error("Failed to edit document: " + err.message)
    },
  })

  const handleCancel = () => {
    navigate({ to: "/g/$slug", params: { slug: slug } })
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (!name || !newSlug) {
      return
    }
    save({
      id: document!.id,
      name: name,
      slug: newSlug,
    })
  }

  return (
    <div className="h-full w-full overflow-y-auto">
      <form
        className="w-full rounded border border-slate-900 bg-ui p-4 shadow-lg"
        onSubmit={handleSubmit}
      >
        <div className="space-y-12">
          <div className="border-b border-white/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-white">Edit Document</h2>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="name" className="block text-sm font-medium leading-6 text-white">
                  Document Name
                </label>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="slug" className="block text-sm font-medium leading-6 text-white">
                  Document Slug
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                    <span className="flex select-none items-center whitespace-nowrap pl-3 text-gray-500 sm:text-sm">
                      typegrid.app/g/
                    </span>
                    <input
                      type="text"
                      name="slug"
                      id="slug"
                      autoCapitalize="none"
                      autoCorrect="off"
                      className="flex-1 border-0 bg-transparent py-1.5 pl-1 text-white focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="slug"
                      value={slug}
                      onChange={(e) => setNewSlug(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-white"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}
