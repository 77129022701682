import * as Y from "yjs"
import { supabase } from "@/app/supabase"
import { StateManager } from "./state/state"
import { CellId, CellKeys } from "./state/types"
import { YSync } from "./state/sync"
import { newYMap } from "@/packages/util/yjs"

// TODO: prototype, refactor this and the sync states while we're at it
export async function transferCellToDocument(
  cellId: CellId,
  source: StateManager,
  targetDocumentSlug: string,
) {
  const targetDocument = (
    await supabase
      .from("document")
      .select("*")
      .eq("slug", targetDocumentSlug)
      .single()
      .throwOnError()
  ).data

  if (!targetDocument) {
    throw new Error("Document not found")
  }

  if (targetDocument.encryption) {
    throw new Error("Encrypted documents are not currently supported")
  }

  const targetYDoc = new Y.Doc({ guid: targetDocument.y_doc_id })
  const targetSync = new YSync(targetYDoc, targetDocument.p2p_key, supabase)
  await targetSync.whenLocallySynced()
  await targetSync.whenRemoteSynced()

  const cellData = source.yDoc.getMap("cells").get(cellId)
  if (!cellData) {
    throw new Error("Cell not found")
  }

  if (targetSync.yDoc.getMap("cells").has(cellId)) {
    throw new Error("Cell already exists in target document")
  }

  const cellLayout = cellData.get("layout")
  const cellOptions = cellData.get("options")
  const cellContent = cellData.get("content")

  let newCellContent: Y.AbstractType<any> | undefined
  if (!cellContent) {
    newCellContent = undefined
  } else if (cellContent instanceof Y.Text) {
    newCellContent = new Y.Text(cellContent.toString())
  } else if (cellContent instanceof Y.Map) {
    newCellContent = new Y.Map(Object.entries(cellContent.toJSON()))
  } else if (cellContent instanceof Y.Array) {
    newCellContent = Y.Array.from(cellContent.toJSON())
  } else {
    throw new Error("Invalid content type")
  }

  const newCellData = newYMap<Required<CellKeys>>({
    id: cellId,
    name: cellData.get("name") || undefined!,
    type: cellData.get("type"),
    layout: {
      type: "absolute",
      x: 0,
      y: 0,
      width: cellLayout.width,
      height: cellLayout.height,
    },
    inputs: new Y.Array(),
    options: cellOptions ? new Y.Map(Object.entries(cellOptions.toJSON())) : undefined!,
    content: newCellContent!,
  })

  targetSync.yDoc.getMap("cells").set(cellId, newCellData)
  targetSync.saveToRemote()

  await targetSync.whenRemoteSaved()

  targetSync.destroy()
  targetYDoc.destroy()

  source.deleteCell(cellId)
}
