import {
  ChevronsLeftIcon,
  CircleAlertIcon,
  CircleArrowDown,
  MenuIcon,
  RefreshCcwIcon,
} from "lucide-react"
import { useState } from "react"
import { useServiceWorker } from "../ServiceWorkerContext"
import DocumentTree from "./DocumentTree"

export default function WorkspaceNavbarDesktop() {
  const [collapsed, setCollapsed] = useState(false)
  const serviceWorker = useServiceWorker()

  if (collapsed) {
    return (
      <button
        className="absolute left-0 top-0 flex h-11 w-11 cursor-pointer items-center justify-center rounded-br rounded-tr active:bg-ui active:shadow-sm active:shadow-slate-950"
        onClick={() => setCollapsed(false)}
      >
        <MenuIcon className="w-9 px-2" strokeWidth={1.5} />
      </button>
    )
  } else {
    return (
      <div className="relative flex h-full w-64 flex-col overflow-visible border-r border-zinc-900 bg-ui pt-3">
        <button
          className="absolute -right-11 top-0 flex h-11 w-11 cursor-pointer items-center justify-center rounded-br rounded-tr active:bg-ui active:shadow-sm active:shadow-slate-950"
          onClick={() => setCollapsed(true)}
        >
          <ChevronsLeftIcon className="w-9 px-2" strokeWidth={1.5} />
        </button>

        <DocumentTree />

        <div className="flex h-6 w-full items-center justify-center gap-x-2">
          <div className="h-4 w-4 text-gray-400">
            {serviceWorker.updateStatus === "loading" && (
              <RefreshCcwIcon className="h-4 w-4 animate-spin" strokeWidth={1.5} />
            )}
            {serviceWorker.updateStatus === "outdated" && (
              <button
                className="flex cursor-pointer items-center justify-center rounded active:bg-slate-950/20 hover:text-white"
                onClick={() => serviceWorker.updateServiceWorker()}
              >
                <CircleArrowDown className="h-4 w-4" strokeWidth={1.5} />
              </button>
            )}
            {serviceWorker.updateStatus === "current" && (
              <button
                className="flex cursor-pointer items-center justify-center rounded active:bg-slate-950/20 hover:text-white"
                onClick={() => serviceWorker.checkForUpdate()}
              >
                <RefreshCcwIcon className="h-4 w-4" strokeWidth={1.5} />
              </button>
            )}
            {(serviceWorker.updateStatus === "error" ||
              serviceWorker.updateStatus === "unknown") && (
              <CircleAlertIcon className="h-4 w-4" strokeWidth={1.5} />
            )}
          </div>

          <div className="text-center text-xs text-gray-500">{serviceWorker.buildVersion}</div>
        </div>
      </div>
    )
  }
}
