import * as Y from "yjs"
import * as cmView from "@codemirror/view"
import { Awareness } from "y-protocols/awareness"
import { YRange } from "./y-range.js"
import { ySyncPlugin, ySyncFacet, YSyncConfig } from "./y-sync.js"
import { yRemoteSelections, yRemoteSelectionsTheme } from "./y-remote-selections.js"
import {
  yUndoManager,
  yUndoManagerFacet,
  YUndoManagerConfig,
  undo,
  redo,
  yUndoManagerKeymap,
} from "./y-undomanager.js"

export {
  YRange,
  yRemoteSelections,
  yRemoteSelectionsTheme,
  ySyncPlugin,
  ySyncFacet,
  YSyncConfig,
  yUndoManagerKeymap,
}

/**
 * @param {Y.Text} ytext
 * @param {any} awareness
 * @param {Object} [opts]
 * @param {Y.UndoManager | false} [opts.undoManager] Set undoManager to false to disable the undo-redo plugin
 * @return {cmState.Extension}
 */
export const yCollab = (
  ytext: Y.Text,
  awareness: Awareness | null = null,
  { undoManager = new Y.UndoManager(ytext) } = {},
) => {
  const ySyncConfig = new YSyncConfig(ytext, awareness)
  const plugins = [ySyncFacet.of(ySyncConfig), ySyncPlugin]
  if (awareness) {
    plugins.push(yRemoteSelectionsTheme, yRemoteSelections)
  }
  if (undoManager) {
    // By default, only track changes that are produced by the sync plugin (local edits)
    plugins.push(
      yUndoManagerFacet.of(new YUndoManagerConfig(undoManager)),
      yUndoManager,
      cmView.keymap.of([...yUndoManagerKeymap]),
      cmView.EditorView.domEventHandlers({
        beforeinput(e, view) {
          if (e.inputType === "historyUndo") return undo(view)
          if (e.inputType === "historyRedo") return redo(view)
          return false
        },
      }),
    )
  }
  return plugins
}
