export class HttpResponse {
  private static textDecoder = new TextDecoder()
  private cachedJson: any | undefined

  public constructor(
    public readonly statusCode: number,
    public readonly statusMessage: string,
    public readonly headers: [string, string][],
    public readonly body: string | ArrayBuffer,
  ) {}

  toString(): string {
    return (
      `HTTP/1.1 ${this.statusCode} ${this.statusMessage}\n` +
      this.headers.map(([key, value]) => `${key}: ${value}`).join("\n") +
      "\n\n" +
      this.text()
    )
  }

  json(): any {
    if (!this.cachedJson) {
      if (typeof this.body === "string") {
        this.cachedJson = JSON.parse(this.body)
      } else {
        this.cachedJson = JSON.parse(HttpResponse.textDecoder.decode(this.body))
      }
    }
    return this.cachedJson
  }

  text(): string {
    if (typeof this.body === "string") {
      return this.body
    } else {
      return HttpResponse.textDecoder.decode(this.body)
    }
  }
}
