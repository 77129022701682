import {
  DialogProps as AriaDialogProps,
  Dialog as AriaDialog,
  Heading as AriaHeading,
  HeadingProps as AriaHeadingProps,
} from "react-aria-components"
import { twMerge } from "tailwind-merge"

export function Dialog(props: AriaDialogProps) {
  return (
    <AriaDialog
      {...props}
      className={twMerge(
        "relative max-h-[inherit] overflow-auto p-6 outline outline-0 [[data-placement]>&]:p-4",
        props.className,
      )}
    />
  )
}

export function Heading(props: AriaHeadingProps) {
  return <AriaHeading {...props} className={twMerge("mb-4", props.className)} />
}
