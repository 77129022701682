import { liquidLanguage } from "@codemirror/lang-liquid"
import { parseMixed } from "@lezer/common"
import { styleTags, tags as t } from "@lezer/highlight"
import { MarkdownExtension } from "@lezer/markdown"

const LiquidTagDelim = { resolve: "LiquidTag", mark: "LiquidTagMark" }
const LiquidOutputDelim = { resolve: "LiquidOutput", mark: "LiquidOutputMark" }

export const liquidExtension: MarkdownExtension = {
  defineNodes: [
    { name: "LiquidTag" },
    { name: "LiquidOutput" },
    { name: "LiquidTagMark", style: t.processingInstruction },
    { name: "LiquidOutputMark", style: t.processingInstruction },
  ],
  props: [
    styleTags({
      LiquidTag: [t.monospace],
      LiquidTagMark: [t.processingInstruction, t.monospace],
    }),
  ],
  wrap: parseMixed((node) => {
    if (node.name === "LiquidTag" || node.name === "LiquidOutput") {
      return {
        parser: liquidLanguage.parser,
        overlay: [{ from: node.from, to: node.to }],
      }
    } else {
      return null
    }
  }),
  parseInline: [
    {
      name: "Liquid",
      parse(cx, next, pos) {
        if (next === 123 /* '{' */ && cx.char(pos + 1) === 37 /* '%' */) {
          return cx.addDelimiter(LiquidTagDelim, pos, pos + 2, true, false)
        } else if (next === 37 /* '%' */ && cx.char(pos + 1) === 125 /* '}' */) {
          return cx.addDelimiter(LiquidTagDelim, pos, pos + 2, false, true)
        } else if (next === 123 /* '{' */ && cx.char(pos + 1) === 123 /* '{' */) {
          return cx.addDelimiter(LiquidOutputDelim, pos, pos + 2, true, false)
        } else if (next === 125 /* '}' */ && cx.char(pos + 1) === 125 /* '}' */) {
          return cx.addDelimiter(LiquidOutputDelim, pos, pos + 2, false, true)
        }
        return -1
      },
      before: "HTMLTag",
    },
  ],
}
