import { SupabaseClient } from "@supabase/supabase-js"
import { QueryClient } from "@tanstack/react-query"
import { Outlet, createRootRouteWithContext, createRoute, redirect } from "@tanstack/react-router"
import { Auth } from "./auth/AuthProvider"
import { z } from "zod"
import Login from "./auth/Login"
import { getDocumentsQuery, getDocumentBySlugQuery } from "@/app/supabase"
import Workspace from "./workspace/Workspace"
import Document from "./workspace/document/Document"
import CreateDocument from "./workspace/CreateDocument"
import EditDocument from "./workspace/document/EditDocument"
import FullscreenCell from "./workspace/document/FullscreenCell"

export const LAST_ACCESS_KEY = "lastAccessedWorkspace"

export const rootRoute = createRootRouteWithContext<{
  auth: Auth
  supabase: SupabaseClient
  queryClient: QueryClient
}>()({
  component: Outlet,
})

export const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      })
    } else {
      throw redirect({
        to: "/g",
      })
    }
  },
})

export const loginRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/login",
  validateSearch: z.object({
    redirect: z.string().catch("/"),
  }),
  component: Login,
})

export const workspaceRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/g",
  component: Workspace,
  beforeLoad: async ({ context: { queryClient, auth }, location, params }) => {
    if (!auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      })
    }
  },
  loader: async ({ context: { queryClient } }) =>
    await queryClient.ensureQueryData(getDocumentsQuery()),
})

export const createDocumentRoute = createRoute({
  getParentRoute: () => workspaceRoute,
  path: "new",
  component: CreateDocument,
  beforeLoad: ({ context: { auth } }) => {
    if (!auth.isAuthenticated) {
      throw redirect({ to: "/login", search: { redirect: "/g/new" } })
    }
  },
})

export const documentRoute = createRoute({
  getParentRoute: () => workspaceRoute,
  path: "$slug",
  component: Document,
  loader: ({ context: { queryClient }, params: { slug } }) => {
    localStorage.setItem(LAST_ACCESS_KEY, slug)
    return queryClient.ensureQueryData(getDocumentBySlugQuery(slug))
  },
})

export const editDocumentRoute = createRoute({
  getParentRoute: () => documentRoute,
  path: "edit",
  component: EditDocument,
})

export const cellRoute = createRoute({
  getParentRoute: () => documentRoute,
  path: "cell/$cellId",
  component: FullscreenCell,
})

export const routeTree = rootRoute.addChildren([
  indexRoute,
  loginRoute,
  workspaceRoute.addChildren([
    createDocumentRoute,
    documentRoute.addChildren([cellRoute, editDocumentRoute]),
  ]),
])
