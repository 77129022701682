import { HttpResponse } from "./response"

export class HttpRequest {
  public constructor(
    public readonly path: string,
    public readonly method: string,
    public readonly headers: [string, string][],
    public readonly body: HttpRequestBody,
  ) {}

  private async executeLocal(url: URL): Promise<HttpResponse> {
    const response = await fetch(url, {
      method: this.method,
      headers: this.headers.reduce((acc, [key, value]) => {
        acc.append(key, value)
        return acc
      }, new Headers()),
      body: this.body.size ? this.body.getArrayBuffer() : undefined,
    })
    return new HttpResponse(
      response.status,
      response.statusText,
      Array.from(response.headers.entries()),
      await response.text(),
    )
  }

  private async executeProxy(url: URL): Promise<HttpResponse> {
    const form = new FormData()
    form.append(
      "spec",
      JSON.stringify({
        method: this.method,
        path: this.path,
        headers: this.headers,
      }),
    )
    if (this.body.size) {
      form.append("body", new Blob([this.body.getArrayBuffer()]))
    }
    const response = await fetch("https://proxy.vali.workers.dev", {
      method: "POST",
      body: form,
    })
    if (response.status === 200) {
      const responseBody = await response.json()
      return new HttpResponse(
        responseBody.statusCode,
        responseBody.statusMessage,
        responseBody.headers,
        responseBody.body,
      )
    } else {
      const message = await response.text()
      throw new Error(`${response.status} ${message}`)
    }
  }

  execute(): Promise<HttpResponse> {
    let url: URL
    if (this.path.startsWith("/")) {
      url = new URL(
        this.path,
        `http://${this.headers.find(([key]) => key.toLowerCase() === "host")?.[1]}`,
      )
    } else {
      url = new URL(this.path)
    }

    if (
      url.hostname === "localhost" ||
      url.hostname === "127.0.0.1" ||
      url.hostname.endsWith(".ts.net")
    ) {
      return this.executeLocal(url)
    } else {
      return this.executeProxy(url)
    }
  }
}

export class HttpRequestBody {
  private static textEncoder = new TextEncoder()

  constructor(private string: string) {}

  getDisplayString(): string {
    return this.string
  }

  getArrayBuffer(): ArrayBuffer {
    return HttpRequestBody.textEncoder.encode(this.string).buffer
  }

  get size(): number {
    return this.string.length
  }

  private arrayBufferConcat(...buffers: ArrayBuffer[]): ArrayBuffer {
    if (buffers.length <= 0) {
      return new Uint8Array(0).buffer
    }

    const arrayBuffer = buffers.reduce((acc, buffer, index) => {
      if (index === 0) return acc

      const tmp = new Uint8Array(acc.byteLength + buffer.byteLength)
      tmp.set(new Uint8Array(acc), 0)
      tmp.set(new Uint8Array(buffer), acc.byteLength)

      return tmp.buffer
    }, buffers[0])

    return arrayBuffer
  }
}
