import { HttpRequest, HttpRequestBody } from "./request"

const NEW_LINE_REGEX = /\r?\n/g
enum ParseState {
  Request,
  Header,
  Body,
}

export default function parseHttpRequest(text: string): HttpRequest {
  const lines = text.split(NEW_LINE_REGEX)

  const requestLines: string[] = []
  const headerLines: string[] = []
  const bodyLines: string[] = []

  let state = ParseState.Request

  let currentLine: string | undefined
  while ((currentLine = lines.shift()) !== undefined) {
    const nextLine = lines[0]
    switch (state) {
      case ParseState.Request:
        requestLines.push(currentLine)
        if (nextLine?.trim()) {
          state = ParseState.Header
        } else {
          lines.shift()
          state = ParseState.Body
        }
        break
      case ParseState.Header:
        headerLines.push(currentLine)
        if (!nextLine?.trim()) {
          lines.shift()
          state = ParseState.Body
        }
        break
      case ParseState.Body:
        bodyLines.push(currentLine)
        break
    }
  }

  const { method, path } = parseRequestLine(requestLines)
  const headers = parseHeaderLines(headerLines)
  const body = parseBodyLines(bodyLines)

  return new HttpRequest(path, method, headers, body)
}

const REQUEST_LINE_REGEX = /^(?<method>[A-Z]+)\s(?<path>\S+)(?:\s(?<version>HTTP\/.+))?$/i
const HTTP_METHODS = [
  "GET",
  "POST",
  "PUT",
  "DELETE",
  "PATCH",
  "HEAD",
  "OPTIONS",
  "CONNECT",
  "TRACE",
]

function parseRequestLine(lines: string[]) {
  const requestLine = lines.map((l) => l.trim()).join("")

  const match = requestLine.match(REQUEST_LINE_REGEX)
  if (!match?.groups) {
    throw new Error("Invalid request line")
  }

  const { method, path } = match.groups

  if (!HTTP_METHODS.includes(method)) {
    throw new Error("Invalid HTTP method")
  }

  return { method, path }
}

const HEADER_LINE_REGEX = /^(?<key>[^:]+):\s(?<value>.+)$/
function parseHeaderLines(lines: string[]) {
  const headers: [string, string][] = []
  for (const line of lines) {
    const match = line.match(HEADER_LINE_REGEX)
    if (!match?.groups) {
      throw new Error("Invalid header line")
    }

    const { key, value } = match.groups
    headers.push([key, value])
  }

  return headers
}

function parseBodyLines(lines: string[]): HttpRequestBody {
  return new HttpRequestBody(lines.join("\n"))
}
