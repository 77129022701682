import { configureStore, createAsyncThunk } from "@reduxjs/toolkit"
import { cellsSlice } from "@/app/store/cellsSlice"
import { useDispatch, useSelector, useStore } from "react-redux"

export const store = configureStore({
  reducer: {
    cells: cellsSlice.reducer,
  },
})

export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
  rejectValue: string
  extra: { s: string; n: number }
}>()

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
export const useAppStore = useStore.withTypes<AppStore>()
