import { syntaxTree } from "@codemirror/language"
import { EditorState, StateField } from "@codemirror/state"

/**
 * A heading slug is a string that is used to identify/reference
 * a heading in the document. Heading slugs are URI-compatible and can be used
 * in permalinks as heading IDs.
 */
export interface HeadingSlug {
  slug: string
  pos: number
}

const whitespaceRegex = /\s+/g
const nonWordRegex = /[^\w-]+/g

/**
 *
 * @param state - The current editor state.
 * @returns An array of heading slugs.
 */
function extractSlugs(state: EditorState): HeadingSlug[] {
  const occurrences: Record<string, number> = {}
  const slugs: HeadingSlug[] = []

  syntaxTree(state).iterate({
    enter: ({ name, from, to, node }) => {
      // Capture ATXHeading and SetextHeading
      if (!name.includes("Heading")) return
      const mark = node.getChild("HeaderMark")
      if (!mark) return

      const headerText = state.sliceDoc(from, to).split("")
      headerText.splice(mark.from - from, mark.to - mark.from)

      let slug = headerText
        .join("")
        .trim()
        .toLowerCase()
        .replace(whitespaceRegex, "-")
        .replace(nonWordRegex, "")

      if (slug in occurrences) {
        occurrences[slug]++
        slug += "-" + occurrences[slug]
      } else {
        occurrences[slug] = 1
      }

      slugs.push({ slug, pos: from })
    },
  })
  return slugs
}

/**
 * A plugin that stores the calculated slugs of the document headings in the
 * editor state. These can be useful when resolving links to headings inside
 * the document.
 */
export const headingSlugField = StateField.define<HeadingSlug[]>({
  create: (state) => {
    const slugs = extractSlugs(state)
    return slugs
  },
  update: (value, tx) => {
    if (tx.docChanged) return extractSlugs(tx.state)
    return value
  },
  compare: (a, b) =>
    a.length === b.length && a.every((slug, i) => slug.slug === b[i].slug && slug.pos === b[i].pos),
})
