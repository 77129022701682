import { CellId } from "@/engine/state/types"
import { animated, useSpring } from "@react-spring/web"
import { selectGridBounds, selectCellStackById, selectCellLayouts } from "@/app/store/cellsSlice"
import { useAppSelector } from "../../../store"
import { Box, Vec2 } from "@/packages/util/geometry"
import { Ref, forwardRef, useImperativeHandle, useRef } from "react"
import { getNextZIndex, useConnectGesture, useMoveGesture, useResizeGestures } from "./utils"
import { EmbeddedCellFrame } from "./EmbeddedCellFrame"
import { useEngine } from "../document/EngineContext"

interface CellFrameProps {
  stackId: string
  onOffsetChange: (offset: Vec2) => void
  onNewConnection: (cellId: CellId, to: Vec2, dragging: boolean) => void
}

export type CellFrameHandle = unknown

export default forwardRef(function CellFrame(
  { stackId, onOffsetChange, onNewConnection }: CellFrameProps,
  ref: Ref<CellFrameHandle>,
) {
  const engine = useEngine()

  const cellLayouts = useAppSelector(selectCellLayouts)
  const stack = useAppSelector((state) => selectCellStackById(state, stackId))

  const anchorCellId = stack.cells[0]
  const anchorCellLayout = cellLayouts[anchorCellId]

  const stackLayout: Box = stack.cells.reduce(
    (acc, cellId) => {
      const layout = cellLayouts[cellId]
      return {
        x: Math.min(acc.x, layout.x),
        y: Math.min(acc.y, layout.y),
        width: Math.max(acc.width, layout.width),
        height: acc.height + layout.height,
      }
    },
    { x: Infinity, y: Infinity, width: 0, height: 0 },
  )

  const gridBounds = useAppSelector(selectGridBounds)
  const containerRef = useRef<HTMLDivElement>(null)

  const [springStyle, spring] = useSpring(
    () => ({
      x: 0,
      y: 0,
      width: stackLayout.width,
      config: {
        mass: 1,
        friction: 50,
        tension: 500,
        clamp: true,
      },
      onChange(result) {
        onOffsetChange?.([result.value.x, result.value.y])
      },
      onRest(result, ctrl) {
        const { x: dx, y: dy, width } = result.value
        engine.stateManager.updateCellLayout(anchorCellId, {
          ...anchorCellLayout,
          x: anchorCellLayout.x + dx,
          y: anchorCellLayout.y + dy,
          width,
        })
        ctrl.set({ x: 0, y: 0 })
      },
    }),
    [cellLayouts, stack],
  )

  const [heightSpringStyle, heightSpring] = useSpring(
    () => ({
      height: stackLayout.height,
      config: {
        mass: 1,
        friction: 50,
        tension: 500,
        clamp: true,
      },
    }),
    [cellLayouts],
  )

  useImperativeHandle(ref, () => ({}))

  const moveGestures = useMoveGesture(containerRef, stackLayout, false, ([x, y]) =>
    spring.start({ x, y }),
  )
  const resizeGestures = useResizeGestures(containerRef, stackLayout, (args) => spring.start(args))
  const connectGestures = useConnectGesture(containerRef, stackLayout, onNewConnection)

  return (
    <animated.div
      ref={containerRef}
      onMouseDown={() => {
        if (containerRef.current) {
          containerRef.current.style.zIndex = getNextZIndex(containerRef.current.style.zIndex)
        }
      }}
      className="cell z-10 grid grid-cols-[4px_minmax(0,_1fr)_4px] grid-rows-[auto_minmax(0,_1fr)_16px]"
      style={{
        position: "absolute",
        left: stackLayout.x - gridBounds.left,
        top: stackLayout.y - gridBounds.top,
        ...springStyle,
        ...heightSpringStyle,
      }}
    >
      <div {...resizeGestures(-1, -1)} className="cursor-nwse-resize touch-none"></div>

      <div className="row-span-3">
        {stack.cells.map((cellId, index) => (
          <EmbeddedCellFrame
            key={cellId}
            cellId={cellId}
            index={index}
            moveGestures={cellId === anchorCellId ? moveGestures : null}
            connectGestures={connectGestures}
            onResize={(height) => heightSpring.start({ height: height + stackLayout.height })}
          />
        ))}
      </div>
      <div {...resizeGestures(1, -1)} className="cursor-nesw-resize touch-none"></div>
      <div {...resizeGestures(-1, 0)} className="cursor-ew-resize touch-none"></div>
      <div {...resizeGestures(1, 0)} className="cursor-ew-resize touch-none"></div>
      <div {...resizeGestures(-1, 0)} className="cursor-ew-resize touch-none"></div>
      <div {...resizeGestures(1, 0)} className="cursor-ew-resize touch-none"></div>
    </animated.div>
  )
})
