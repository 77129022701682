import * as Y from "yjs"
import { Subscription, SupabaseClient } from "@supabase/supabase-js"
import { Database } from "@/app/supabase/database.types"
import { Debounced, debounce } from "@/packages/util/debounce"
import { Callable } from "@/packages/util/types"
import { areArraysEqual, decodeFromHex, Encoder, encodeToHex } from "@/packages/util/encoding"
import { Room } from "y-webrtc"
import { BaseEventObservable } from "@/packages/emitter"

type Events = {
  syncComplete: void
  statusUpdated: void
  saved: void
}

type LastSyncData = {
  milestoneId: string
  timestamp: number
}

export class SupabasePersistance extends BaseEventObservable<Events> {
  private isConnected: boolean = false
  private isSyncing: boolean = false
  private isDestroyed: boolean = false
  private isSaving: boolean = false

  private lastSync: LastSyncData | null = null

  private pendingUpdates: Uint8Array[] = []

  private saveDebounceInterval: number = 7000

  private docId: string

  private _docUpdateHandler: Callable
  private _saveDebounce: Debounced<Callable>
  private _supabaseAuthHandler: Subscription | null = null

  constructor(
    private yDoc: Y.Doc,
    private supabase: SupabaseClient<Database>,
    private encoder: Encoder | null,
  ) {
    super()
    this.docId = yDoc.guid

    this._docUpdateHandler = (update: Uint8Array, origin: any) => {
      // Ignore updates from self or form WebRTC (we assume these are saved by the sending party)
      if (origin === this || origin instanceof Room) {
        return
      }
      if (!this.pendingUpdates.length) {
        this.emitter.emit("statusUpdated")
      }
      this.pendingUpdates.push(update)
      this._saveDebounce()
    }

    this._saveDebounce = debounce(async () => {
      if (this.isSyncing) {
        await this.emitter.once("syncComplete")
      }

      if (!this.pendingUpdates.length) return
      if (!this.lastSync) return

      console.log("Saving updates", this.pendingUpdates.length)
      this.isSaving = true
      this.emitter.emit("statusUpdated")
      const update = Y.mergeUpdates(this.pendingUpdates)
      this.pendingUpdates = []
      try {
        await this.saveUpdate(update, this.lastSync.milestoneId)
        this.emitter.emit("saved")
      } catch (e) {
        this.pendingUpdates.splice(0, 0, update)
        console.error(e)
      }
      this.isSaving = false
      this.emitter.emit("statusUpdated")
    }, this.saveDebounceInterval)

    this.yDoc.on("update", this._docUpdateHandler)
  }

  async connect() {
    if (this.isConnected) {
      return
    }
    this._supabaseAuthHandler = this.supabase.auth.onAuthStateChange((_, session) => {
      if (session && !this.lastSync) {
        this.sync()
      }
    }).data.subscription
    this.isConnected = true
  }

  async whenSynced() {
    if (this.lastSync === null || this.isSyncing) {
      await this.emitter.once("syncComplete")
    }
  }

  async whenSaved() {
    if (this.pendingUpdates.length || this.isSaving) {
      await this.emitter.once("saved")
    }
  }

  private async toPostgresBinary(data: Uint8Array) {
    if (this.encoder) {
      data = await this.encoder.encode(data)
    }
    return "\\x" + encodeToHex(data)
  }

  private async fromPostgresBinary(stringData: string) {
    const data = decodeFromHex(stringData.slice(2))
    if (this.encoder) {
      return await this.encoder.decode(data)
    } else {
      return data
    }
  }

  get isSynced() {
    return this.lastSync !== null
  }

  get isWorking() {
    return this.isSyncing || this.isSaving
  }

  get hasPendingUpdates() {
    return this.pendingUpdates.length > 0
  }

  async sync() {
    if (!this.supabase.auth.getSession()) {
      return
    }

    if (this.isSyncing) {
      return await this.emitter.once("syncComplete")
    }
    this.isSyncing = true
    try {
      this.emitter.emit("statusUpdated")
      console.log("Syncing document...")
      const docResponse = await this.supabase
        .rpc("y_doc_get_updates", {
          _y_doc_id: this.docId,
        })
        .maybeSingle()

      if (this.isDestroyed) {
        return
      }

      if (docResponse.error) {
        throw new Error(docResponse.error.message)
      }

      if (!docResponse.data) {
        return
      }

      if (docResponse.data.updates) {
        const updates = Y.mergeUpdates(
          await Promise.all(docResponse.data.updates.map((d) => this.fromPostgresBinary(d))),
        )

        const remoteDoc = new Y.Doc()
        Y.applyUpdate(remoteDoc, updates, this)
        Y.applyUpdate(this.yDoc, updates, this)

        const stateVector = Y.encodeStateVectorFromUpdate(updates)
        const diff1 = Y.encodeStateAsUpdate(this.yDoc, stateVector)
        const diff2 = Y.encodeStateAsUpdate(remoteDoc, stateVector)

        this.pendingUpdates = []

        // The DeleteSet is always appended to the update, this is an attempt to check
        // if anything actually needs to be updated
        if (areArraysEqual(diff1, diff2)) {
          console.log("Document is in sync")
        } else {
          console.log("Document is out of sync")
          await this.saveUpdate(diff1, docResponse.data.milestone_id)
        }
      } else {
        this.pendingUpdates = []
        console.log("Document is out of sync")
        await this.saveUpdate(Y.encodeStateAsUpdate(this.yDoc), docResponse.data.milestone_id)
      }

      this.lastSync = {
        milestoneId: docResponse.data.milestone_id,
        timestamp: Date.now(),
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.isSyncing = false
      this.emitter.emit("syncComplete")
      this.emitter.emit("statusUpdated")
    }
  }

  flush() {
    this._saveDebounce.flush()
  }

  private async saveUpdate(updates: Uint8Array, milestoneId: string) {
    if (!this.supabase.auth.getSession()) {
      throw new Error("Not authenticated")
    }

    const response = await this.supabase.rpc("y_doc_add_update", {
      _y_doc_id: this.docId,
      _milestone_id: milestoneId,
      _update: await this.toPostgresBinary(updates),
    })

    if (response.error) {
      throw new Error(response.error.message)
    }
  }

  async optimize() {
    if (!this.supabase.auth.getSession()) {
      throw new Error("Not authenticated")
    }

    if (this.isSyncing) {
      await this.emitter.once("syncComplete")
    }

    this._saveDebounce.clear()

    try {
      this.isSyncing = true
      this.lastSync = null

      const response = await this.supabase.rpc("y_doc_optimize", {
        _y_doc_id: this.docId,
        _data: await this.toPostgresBinary(Y.encodeStateAsUpdate(this.yDoc)),
      })

      if (response.error) {
        throw new Error(response.error.message)
      }

      this.lastSync = {
        milestoneId: response.data,
        timestamp: Date.now(),
      }
    } finally {
      this.isSyncing = false
      this.emitter.emit("syncComplete")
    }
  }

  destroy() {
    this.isDestroyed = true
    this.yDoc.off("update", this._docUpdateHandler)
    this._saveDebounce.clear()
    this._supabaseAuthHandler?.unsubscribe()
    this.emitter.clearListeners()
  }
}
