import { Check, ChevronRight } from "lucide-react"
import {
  Menu as AriaMenu,
  MenuItem as AriaMenuItem,
  MenuProps as AriaMenuProps,
  MenuTrigger as AriaMenuTrigger,
  MenuItemProps as AriaMenuItemProps,
  SubmenuTrigger as AriaSubmenuTrigger,
  Separator as AriaSeparator,
  SeparatorProps as AriaSeparatorProps,
  composeRenderProps,
} from "react-aria-components"
import { DropdownSection, DropdownSectionProps, dropdownItemStyles } from "./ListBox"
import { Popover, PopoverProps } from "./Popover"
import { twMerge } from "tailwind-merge"

interface MenuProps<T> extends AriaMenuProps<T> {
  placement?: PopoverProps["placement"]
}

export function Menu<T extends object>(props: MenuProps<T>) {
  return (
    <Popover placement={props.placement} className="min-w-[150px]">
      <AriaMenu
        {...props}
        className={twMerge(
          "max-h-[inherit] overflow-auto p-1 outline outline-0 [clip-path:inset(0_0_0_0_round_.75rem)]",
          props.className,
        )}
      />
    </Popover>
  )
}

export function Submenu<T extends object>(props: MenuProps<T>) {
  return (
    <Popover className="min-w-40">
      <AriaMenu
        {...props}
        className={twMerge("max-h-[inherit] overflow-auto p-1 outline outline-0", props.className)}
      />
    </Popover>
  )
}

export function MenuItem(props: AriaMenuItemProps) {
  return (
    <AriaMenuItem
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        dropdownItemStyles({ ...renderProps, className }),
      )}
    >
      {composeRenderProps(props.children, (children, { selectionMode, isSelected, hasSubmenu }) => (
        <>
          {selectionMode !== "none" && (
            <span className="flex w-4 items-center">
              {isSelected && <Check aria-hidden className="h-4 w-4" />}
            </span>
          )}
          <span className="flex flex-1 items-center gap-2 truncate font-normal group-selected:font-semibold">
            {children}
          </span>
          {hasSubmenu && <ChevronRight aria-hidden className="absolute right-2 h-4 w-4" />}
        </>
      ))}
    </AriaMenuItem>
  )
}

export function MenuSeparator(props: AriaSeparatorProps) {
  return (
    <AriaSeparator
      {...props}
      className={twMerge("mx-3 my-1 border-b border-zinc-700", props.className)}
    />
  )
}

export function MenuSection<T extends object>(props: DropdownSectionProps<T>) {
  return <DropdownSection {...props} />
}

export { AriaMenuTrigger as MenuTrigger }
export { AriaSubmenuTrigger as SubmenuTrigger }
