import { Check } from "lucide-react"
import {
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
  ListBoxProps as AriaListBoxProps,
  Collection as AriaCollection,
  Header as AriaHeader,
  ListBoxItemProps as AriaListBoxItemProps,
  Section as AriaSection,
  SectionProps as AriaSectionProps,
  composeRenderProps,
} from "react-aria-components"
import { tv } from "tailwind-variants"
import { composeTailwindRenderProps, focusRing } from "./utils"

type ListBoxProps<T> = Omit<AriaListBoxProps<T>, "layout" | "orientation">

export function ListBox<T extends object>({ children, ...props }: ListBoxProps<T>) {
  return (
    <AriaListBox
      {...props}
      className={composeTailwindRenderProps(props.className, "p-1 outline-0")}
    >
      {children}
    </AriaListBox>
  )
}

export const itemStyles = tv({
  extend: focusRing,
  base: "group relative flex cursor-default select-none items-center gap-8 rounded-md px-2.5 py-1.5 text-sm will-change-transform forced-color-adjust-none",
  variants: {
    isSelected: {
      false: "text-zinc-300 -outline-offset-2 hover:bg-zinc-700",
      true: "bg-blue-600 text-white -outline-offset-4 outline-white [&+[data-selected]]:rounded-t-none [&:has(+[data-selected])]:rounded-b-none",
    },
    isDisabled: {
      true: "text-zinc-600",
    },
  },
})

export function ListBoxItem(props: AriaListBoxItemProps) {
  const textValue =
    props.textValue || (typeof props.children === "string" ? props.children : undefined)
  return (
    <AriaListBoxItem {...props} textValue={textValue} className={itemStyles}>
      {composeRenderProps(props.children, (children) => (
        <>
          {children}
          <div className="absolute bottom-0 left-4 right-4 hidden h-px bg-white/20 [.group[data-selected]:has(+[data-selected])_&]:block" />
        </>
      ))}
    </AriaListBoxItem>
  )
}

export const dropdownItemStyles = tv({
  base: "group flex cursor-default select-none items-center gap-4 rounded-lg py-2 pl-3 pr-1 text-sm outline outline-0 forced-color-adjust-none",
  variants: {
    isDisabled: {
      false: "text-zinc-100",
      true: "text-zinc-600",
    },
    isFocused: {
      true: "bg-blue-600 text-white",
    },
  },
  compoundVariants: [
    {
      isFocused: false,
      isOpen: true,
      className: "bg-zinc-700/60",
    },
  ],
})

export function DropdownItem(props: AriaListBoxItemProps) {
  const textValue =
    props.textValue || (typeof props.children === "string" ? props.children : undefined)
  return (
    <AriaListBoxItem {...props} textValue={textValue} className={dropdownItemStyles}>
      {composeRenderProps(props.children, (children, { isSelected }) => (
        <>
          <span className="flex flex-1 items-center gap-2 truncate font-normal group-selected:font-semibold">
            {children}
          </span>
          <span className="flex w-5 items-center">
            {isSelected && <Check className="h-4 w-4" />}
          </span>
        </>
      ))}
    </AriaListBoxItem>
  )
}

export interface DropdownSectionProps<T> extends AriaSectionProps<T> {
  title?: string
}

export function DropdownSection<T extends object>(props: DropdownSectionProps<T>) {
  return (
    <AriaSection className="after:block after:h-[5px] after:content-[''] first:-mt-[5px]">
      <AriaHeader className="sticky -top-[5px] z-10 -mx-1 -mt-px truncate border-y border-y-zinc-700 bg-zinc-700/60 px-4 py-1 text-sm font-semibold text-zinc-300 backdrop-blur-md supports-[-moz-appearance:none]:bg-gray-100 [&+*]:mt-1">
        {props.title}
      </AriaHeader>
      <AriaCollection items={props.items}>{props.children}</AriaCollection>
    </AriaSection>
  )
}
