import { foldedRanges, syntaxTree } from "@codemirror/language"
import type { SyntaxNodeRef } from "@lezer/common"
import { Decoration, EditorView } from "@codemirror/view"
import { EditorState } from "@codemirror/state"

export type ContentRange = [number, number]

export function checkRangeOverlap([start1, end1]: ContentRange, [start2, end2]: ContentRange) {
  return start1 <= end2 && start2 <= end1
}

export function checkRangeContains(
  [parentStart, parentEnd]: ContentRange,
  [childStart, childEnd]: ContentRange,
) {
  return childStart >= parentStart && childEnd <= parentEnd
}

export function isCursorInRange(view: EditorView, range: ContentRange) {
  if (view.hasFocus) {
    return view.state.selection.ranges.some((selection) =>
      checkRangeOverlap(range, [selection.from, selection.to]),
    )
  } else {
    return view.state.selection.ranges.some(
      (selection) => !selection.empty && checkRangeOverlap(range, [selection.from, selection.to]),
    )
  }
}

/**
 * Iterate over the syntax tree in the visible ranges of the document
 * @param view - Editor view
 * @param handlers - Object with `enter` and `leave` iterate function
 */
export function iterateTreeInVisibleRanges(
  view: EditorView,
  handlers: {
    enter(node: SyntaxNodeRef): boolean | void
    leave?(node: SyntaxNodeRef): void
  },
) {
  for (const { from, to } of view.visibleRanges) {
    syntaxTree(view.state).iterate({ ...handlers, from, to })
  }
}

/**
 * Decoration to simply hide anything.
 */
export const invisibleDecoration = Decoration.replace({})

/**
 * Returns the lines of the editor that are in the given range and not folded.
 * This function is of use when you need to get the lines of a particular
 * block node and add line decorations to each line of it.
 *
 * @param view - Editor view
 * @param from - Start of the range
 * @param to - End of the range
 * @returns A list of line blocks that are in the range
 */
export function editorLines(view: EditorView, from: number, to: number) {
  let lines = view.viewportLineBlocks.filter((block) =>
    // Keep lines that are in the range
    checkRangeOverlap([block.from, block.to], [from, to]),
  )

  const folded = foldedRanges(view.state).iter()
  while (folded.value) {
    lines = lines.filter(
      (line) => !checkRangeOverlap([folded.from, folded.to], [line.from, line.to]),
    )
    folded.next()
  }

  return lines
}
