import { CellId } from "./state/types"

export function getDependencies(
  cellId: CellId,
  connections: { [id: CellId]: CellId[] },
  dependencyMap: Map<CellId, CellId[]> = new Map(),
  refChain: CellId[] = [],
): CellId[] {
  if (refChain.includes(cellId)) {
    // console.error(`Circular dependency detected: ${refChain.join(" -> ")} -> ${cellId}`)
    return []
  }

  if (dependencyMap.has(cellId)) {
    return dependencyMap.get(cellId)!
  }

  const deps = connections[cellId].flatMap((id) => [
    ...getDependencies(id, connections, dependencyMap, [...refChain, cellId]),
    id,
  ])
  dependencyMap.set(cellId, deps)
  return deps
}

export function buildDependencyMap(connections: { [id: string]: string[] }): Map<CellId, CellId[]> {
  const dependencyMap = new Map<CellId, CellId[]>()
  for (const id of Object.keys(connections)) {
    getDependencies(id, connections, dependencyMap)
  }
  return dependencyMap
}
