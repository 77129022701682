import { Encoder, encodeUtf8 } from "@/packages/util/encoding"

export class AesEncoder implements Encoder {
  constructor(private readonly key: CryptoKey) {}

  async encode(source: Uint8Array) {
    const iv = crypto.getRandomValues(new Uint8Array(16))
    const encoded = new Uint8Array(
      await crypto.subtle.encrypt({ name: "AES-CBC", iv }, this.key, source),
    )
    return new Uint8Array([...iv, ...encoded])
  }

  async decode(source: Uint8Array) {
    const iv = source.slice(0, 16)
    const encoded = source.slice(16)
    return new Uint8Array(await crypto.subtle.decrypt({ name: "AES-CBC", iv }, this.key, encoded))
  }
}

// Reusing the IV is generally not a good idea however since we are using CBC the only
// information we would be leaking is if the first blocks of two updates in a document are the same
// since an attacker has no reasonable way to forge updates without the key it should not be a problem.
// Need to further investigate if this can be exploited.
export class FixedIvAesEncoder implements Encoder {
  constructor(
    private readonly key: CryptoKey,
    private readonly iv: Uint8Array,
  ) {}

  async encode(source: Uint8Array) {
    return new Uint8Array(
      await crypto.subtle.encrypt({ name: "AES-CBC", iv: this.iv }, this.key, source),
    )
  }

  async decode(source: Uint8Array) {
    return new Uint8Array(
      await crypto.subtle.decrypt({ name: "AES-CBC", iv: this.iv }, this.key, source),
    )
  }
}

export async function derivePassphraseKey(passphrase: string, salt: Uint8Array) {
  const passphraseKey = await crypto.subtle.importKey(
    "raw",
    encodeUtf8(passphrase),
    "PBKDF2",
    false,
    ["deriveKey"],
  )

  const key = await crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: salt,
      iterations: 50000,
      hash: { name: "SHA-256" },
    },
    passphraseKey,
    { name: "AES-CBC", length: 128 },
    false,
    ["encrypt", "decrypt"],
  )

  return key
}
