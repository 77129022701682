import { TypeScriptWorker } from "./compiler"
import {
  CompletionContext,
  CompletionResult,
  CompletionSource,
  autocompletion,
  closeBrackets,
} from "@codemirror/autocomplete"
import ts from "typescript"
import { Annotation, EditorState, Extension } from "@codemirror/state"
import { Diagnostic, linter } from "@codemirror/lint"
import {
  EditorView,
  Tooltip,
  ViewPlugin,
  crosshairCursor,
  drawSelection,
  dropCursor,
  highlightActiveLine,
  highlightActiveLineGutter,
  highlightSpecialChars,
  hoverTooltip,
  keymap,
  lineNumbers,
  rectangularSelection,
} from "@codemirror/view"
import { javascript } from "@codemirror/lang-javascript"
import { CellId } from "../state/types"
import { buildScript } from "./templates"
import {
  foldGutter,
  indentOnInput,
  syntaxHighlighting,
  defaultHighlightStyle,
  bracketMatching,
} from "@codemirror/language"
import { highlightSelectionMatches } from "@codemirror/search"
import { getCodeFormater } from "@/packages/codemirror/formatter"
import { vscodeKeymap } from "@/packages/codemirror/keymap"

export function getTypeScriptExtensions(worker: TypeScriptWorker, cellId: CellId): Extension {
  return [
    lineNumbers(),
    highlightActiveLineGutter(),
    highlightSpecialChars(),
    // history(),
    foldGutter(),
    drawSelection(),
    dropCursor(),
    EditorState.allowMultipleSelections.of(true),
    indentOnInput(),
    syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
    bracketMatching(),
    closeBrackets(),
    rectangularSelection(),
    crosshairCursor(),
    highlightActiveLine(),
    highlightSelectionMatches(),
    keymap.of([
      ...vscodeKeymap,
      { key: "Meta-Alt-l", run: getCodeFormater("typescript"), preventDefault: true },
      { key: "Alt-Shift-f", run: getCodeFormater("typescript"), preventDefault: true },
    ]),
    EditorView.lineWrapping,
    javascript({ typescript: true, jsx: true }),
    getAutocomplete(worker, cellId),
    getLinter(worker, cellId),
    getHoverTooltip(worker, cellId),
  ]
}

function getAutocomplete(worker: TypeScriptWorker, cellId: string): Extension {
  const completionSource: CompletionSource = async (
    completionContext: CompletionContext,
  ): Promise<CompletionResult | null> => {
    const { state, pos, explicit } = completionContext
    try {
      const scriptContent = state.doc.toString()

      const options: ts.GetCompletionsAtPositionOptions = {}
      if (explicit) {
        options.triggerKind = ts.CompletionTriggerKind.Invoked
      } else {
        options.triggerKind = ts.CompletionTriggerKind.TriggerCharacter
        const lastChar = scriptContent[pos - 1]
        if (["(", ")", "{", "}", "[", "]", ";", ","].includes(lastChar)) {
          return null
        }

        if ([".", '"', "'", "`", "/", "@", "<", "#", " "].includes(lastChar)) {
          // @ts-ignore
          options.triggerCharacter = lastChar
        }
      }

      const { fileName, fileContent, startIndex } = buildScript(cellId, scriptContent)

      const completions = await worker.getCompletions(
        fileName,
        fileContent,
        startIndex + pos,
        options,
      )

      // console.log("signatureHelp", await worker.getSignatureHelpItems(fileName, startIndex + pos))

      if (!completions) {
        return null
      }

      const { from, text: lastWordText } = completionContext.matchBefore(/\w*/) || {
        from: pos,
        text: "",
      }

      // console.log("Completions", { from, pos, completions })

      return {
        from,
        options: completions.entries.map((completion) => {
          const parsedSortText = Number(completion.sortText)
          const sortIndex = isNaN(parsedSortText) ? -99 : -parsedSortText

          // TODO
          const type: string = completion.kind

          return {
            type: type,
            label: completion.name,
            sortText: completion.sortText,
            boost: sortIndex,
          }
        }),
      }
    } catch (e) {
      console.error("Unable to get completions", { pos, error: e })
      return null
    }
  }

  return autocompletion({
    defaultKeymap: false,
    override: [completionSource],
  })
}

function getLinter(worker: TypeScriptWorker, cellId: string): Extension {
  const lintDiagnostics = async (state: EditorState): Promise<Diagnostic[]> => {
    const scriptContent = state.doc.toString()

    const { fileName, fileContent, startIndex } = buildScript(cellId, scriptContent)

    const diagnostics = await worker.getDiagnostics(fileName, fileContent)

    if (diagnostics.length) {
      console.debug("Diagnostics", { diagnostics })
    }

    return (
      diagnostics
        // .filter((d) => d.code !== 2307) // Ignore "Cannot find module" errors
        .filter(
          (d) =>
            d.start !== undefined &&
            d.length !== undefined &&
            d.start + d.length <= startIndex + scriptContent.length,
        )
        .map((d) => {
          let severity: "info" | "warning" | "error" = "info"
          if (d.category === ts.DiagnosticCategory.Error) {
            severity = "error"
          } else if (d.category === ts.DiagnosticCategory.Warning) {
            severity = "warning"
          }

          const start = Math.max(0, (d.start || 0) - startIndex)
          const length = Math.min(scriptContent.length - start, d.length || 0)

          return {
            from: start,
            to: start + length,
            severity,
            source: d.source,
            message: ts.flattenDiagnosticMessageText(d.messageText, "\n", 0),
          }
        })
    )
  }

  const forceRefreshAnnotation = Annotation.define<boolean>()

  return [
    linter((view) => lintDiagnostics(view.state), {
      needsRefresh(update) {
        return (
          (update.focusChanged && update.view.hasFocus) ||
          update.transactions.some((t) => t.annotation(forceRefreshAnnotation))
        )
      },
    }),
    ViewPlugin.define((view) => {
      const unsubscribe = worker.on("ataStatus", ({ status }) => {
        if (status === "idle") {
          view.dispatch({ annotations: forceRefreshAnnotation.of(true) })
        }
      })

      return {
        destroy() {
          unsubscribe()
        },
      }
    }),
  ]
}

function getHoverTooltip(worker: TypeScriptWorker, cellId: string): Extension {
  const hoverTooltipSource = async (state: EditorState, pos: number): Promise<Tooltip | null> => {
    // const ts = state.field(tsStateField);
    const scriptContent = state.doc.toString()
    const { fileName, startIndex } = buildScript(cellId, scriptContent)

    const quickInfo = await worker.getQuickInfo(fileName, startIndex + pos)

    if (!quickInfo) {
      return null
    }

    return {
      pos,
      create() {
        const dom = document.createElement("div")
        dom.setAttribute("class", "cm-quickinfo-tooltip")
        const displayParts = document.createElement("div")
        displayParts.setAttribute("class", "cm-quickinfo-tooltip-display-parts")
        quickInfo.displayParts?.forEach((part) => {
          const el = document.createElement("span")
          el.setAttribute("class", part.kind)
          el.textContent = part.text
          displayParts.appendChild(el)
        })
        dom.appendChild(displayParts)

        if (quickInfo.documentation?.length) {
          const documentation = document.createElement("div")
          documentation.setAttribute("class", "cm-quickinfo-tooltip-documentation")
          quickInfo.documentation?.forEach((part) => {
            const el = document.createElement("div")
            el.setAttribute("class", part.kind)
            el.textContent = part.text
            documentation.appendChild(el)
          })
          dom.appendChild(documentation)
        }

        return {
          dom,
        }
      },
    }
  }

  return hoverTooltip((view, pos) => hoverTooltipSource(view.state, pos), { hideOnChange: true })
}
