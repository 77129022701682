import { selectCellById } from "@/app/store/cellsSlice"
import CodeCellView from "./code/CodeCellView"
import SpreadsheetCellView from "./spreadsheet/SpreadsheetCellView"
import { CellId } from "@/engine/state/types"
import { RootState, useAppSelector } from "@/app/store"
import EmptyCellView from "./EmptyCellView"
import TextCellView from "./text/TextCellView"
import HttpCellView from "./http/HttpCellView"
import SnippetCellView from "./snippet/SnippetCellView"

interface CellViewProps {
  cellId: CellId
}

export default function CellView({ cellId }: CellViewProps) {
  const cell = useAppSelector((state: RootState) => selectCellById(state, cellId))

  let cellContentView
  switch (cell.type) {
    case "empty":
      cellContentView = <EmptyCellView cellId={cellId} />
      break
    case "code":
      cellContentView = <CodeCellView cellId={cellId} />
      break
    case "http":
      cellContentView = <HttpCellView cellId={cellId} />
      break
    case "table":
      cellContentView = <SpreadsheetCellView cellId={cellId} />
      break
    case "text":
      cellContentView = <TextCellView cellId={cellId} />
      break
    case "snippet":
      cellContentView = <SnippetCellView cellId={cellId} />
      break
    default:
      cellContentView = <div>Unknown cell type: {cell.type}</div>
  }

  return cellContentView
}
