import {
  ArchiveRestoreIcon,
  Code2Icon,
  ExpandIcon,
  GlobeIcon,
  LayoutGridIcon,
  LucideIcon,
  NotepadTextIcon,
  SheetIcon,
  Trash2Icon,
} from "lucide-react"
import { useAppSelector } from "@/app/store"
import {
  selectCellById,
  selectCellLayoutById,
  selectCellLayouts,
  selectCells,
} from "@/app/store/cellsSlice"
import { useNavigate } from "@tanstack/react-router"
import { cellRoute, documentRoute } from "../../routes"
import { CellId } from "@/engine/state/types"
import { twJoin } from "tailwind-merge"
import { useEngine } from "./EngineContext"

interface ArchivePanelProps {
  onClose: () => void
}

export function ArchivePanel({ onClose }: ArchivePanelProps) {
  const cells = useAppSelector((state) => selectCells(state))
  const cellLayouts = useAppSelector((state) => selectCellLayouts(state))

  function getSortOrder(cellId: CellId) {
    return (cellLayouts[cellId] as any).archivedAt ?? 0
  }

  return (
    <div className="relative flex h-full flex-col gap-4 overflow-y-scroll px-4 py-4">
      {Object.values(cells)
        .filter((cell) => cellLayouts[cell.id].type === "archived")
        .toSorted((a, b) => getSortOrder(b.id) - getSortOrder(a.id))
        .map((cell) => (
          <ArchiveItem key={cell.id} cellId={cell.id} />
        ))}
    </div>
  )
}

interface ArchiveItemProps {
  cellId: CellId
}

export default function ArchiveItem({ cellId }: ArchiveItemProps) {
  const engine = useEngine()
  const navigate = useNavigate({ from: documentRoute.fullPath })

  const cell = useAppSelector((state) => selectCellById(state, cellId))
  const cellLayout = useAppSelector((state) => selectCellLayoutById(state, cellId))

  const deleteCell = () => {
    engine.stateManager.deleteCell(cell.id)
  }

  const openFullscreen = () => {
    navigate({ to: cellRoute.fullPath, params: (prev) => ({ ...prev, cellId }) })
  }

  const restoreCell = () => {
    engine.stateManager.updateCellLayout(cell.id, {
      type: "absolute",
      x: cellLayout.x,
      y: cellLayout.y,
      width: cellLayout.width,
      height: cellLayout.height,
    })
  }

  let Icon: LucideIcon
  switch (cell.type) {
    case "code":
      Icon = Code2Icon
      break
    case "table":
      Icon = SheetIcon
      break
    case "text":
      Icon = NotepadTextIcon
      break
    case "http":
      Icon = GlobeIcon
      break
    default:
      Icon = LayoutGridIcon
      break
  }

  return (
    <div className="flow-row relative flex select-none items-center pl-1 pr-2 text-sm text-gray-400">
      <Icon className="mr-2 h-4 w-4 min-w-4 flex-initial" />
      <span className="flex flex-auto items-center overflow-hidden">
        <span className={twJoin("relative truncate", cell.name || "italic text-gray-600")}>
          {cell.name || "untitled"}
        </span>
        <span className="ligatures-none mx-1 flex-initial whitespace-nowrap text-xs text-gray-600">
          (id: {cell.id})
        </span>
      </span>

      <div className={twJoin("relative flex items-center gap-2")}>
        <button onClick={openFullscreen} className="hover:text-blue-600">
          <ExpandIcon className="mr-2 h-4 w-4" strokeWidth={1.5} />
        </button>

        <button onClick={restoreCell} className="flex w-full leading-4 hover:text-blue-600">
          <ArchiveRestoreIcon className="mr-2 h-4 w-4" strokeWidth={1.5} />
        </button>

        <button onClick={deleteCell} className="flex w-full leading-4 hover:text-red-600">
          <Trash2Icon className="mr-2 h-4 w-4" strokeWidth={1.5} />
        </button>
      </div>
    </div>
  )
}
