import { forwardRef, Ref, useImperativeHandle, useMemo, useState } from "react"
import { FolderListItem, SelectedNavItem } from "./types"
import { Modal } from "../../components/Modal"
import { Dialog, Heading } from "../../components/Dialog"
import { moveDocumentApi, moveFolderApi } from "@/app/supabase"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify"
import { ListBox, ListBoxItem } from "../../components/ListBox"

export interface MoveItemDialogHandle {
  show: (item: SelectedNavItem) => void
}

export default forwardRef(function MoveItemDialog(
  { folderList }: { folderList: FolderListItem[] },
  ref: Ref<MoveItemDialogHandle>,
) {
  const queryClient = useQueryClient()

  const { mutate: moveDocumentToFolder } = useMutation({
    mutationFn: moveDocumentApi,
    onSuccess({ data }) {
      queryClient.invalidateQueries({ queryKey: ["folder_documents"] })
    },
    onError(err) {
      console.error(err)
      toast.error("Failed to move document: " + err.message)
    },
  })

  const { mutate: moveFolderToParent } = useMutation({
    mutationFn: moveFolderApi,
    onSuccess({ data }) {
      queryClient.invalidateQueries({ queryKey: ["folders"] })
    },
    onError(err) {
      console.error(err)
      toast.error("Failed to move folder: " + err.message)
    },
  })

  const [item, setItem] = useState<SelectedNavItem | null>(null)

  const moveItemToFolder = (folderId: string) => {
    if (!item) return
    if (item.type === "folder") {
      moveFolderToParent({
        id: item.id,
        parentId: folderId === "root" ? null : folderId,
      })
    } else {
      moveDocumentToFolder({
        id: item.id,
        folderId: folderId === "root" ? null : folderId,
      })
    }
    setItem(null)
  }

  const filteredFolderList = useMemo(() => {
    if (!item) return folderList
    return folderList.filter((f) => !f.path.includes(item.id))
  }, [folderList, item])

  useImperativeHandle(ref, () => ({
    show(item: SelectedNavItem) {
      setItem(item)
    },
  }))

  return (
    <Modal
      isOpen={!!item}
      isDismissable
      onOpenChange={(open) => {
        if (!open) setItem(null)
      }}
    >
      <Dialog aria-label="Move item" className="flex flex-col overflow-hidden">
        <Heading slot="title">
          Move {item?.type} {item?.name} to folder:
        </Heading>
        <ListBox aria-label="Select target folder" className="flex-1 overflow-y-auto">
          {filteredFolderList.map((folder) => (
            <ListBoxItem
              aria-label={folder.label || "root"}
              key={folder.id}
              onAction={() => moveItemToFolder(folder.id)}
            >
              {folder.label || "/"}
            </ListBoxItem>
          ))}
        </ListBox>
      </Dialog>
    </Modal>
  )
})
