import { useState } from "react"

const storedUiState = new Map<string, any>()

export function useTransientUiState<T>(
  key: string,
  defaultValue: T,
): readonly [T, (value: T) => void] {
  const value = storedUiState.get(key) ?? defaultValue
  const setValue = (value: T) => {
    storedUiState.set(key, value)
  }

  return [value, setValue] as const
}

export function useLocalUiState<T>(key: string, defaultValue: T): readonly [T, (value: T) => void] {
  const rawValue = localStorage.getItem(key)
  const [valueInternal, setValueInternal] = useState(rawValue ? JSON.parse(rawValue) : defaultValue)

  const setValue = (value: T) => {
    localStorage.setItem(key, JSON.stringify(value))
    setValueInternal(value)
  }

  return [valueInternal, setValue] as const
}
