import { useSupabase } from "@/app/supabase"
import { useOnMountUnsafe } from "@/packages/components/useOnMountUnsafe"
import { Auth } from "@supabase/auth-ui-react"
import { ThemeSupa } from "@supabase/auth-ui-shared"
import { useNavigate, useSearch } from "@tanstack/react-router"
import { flushSync } from "react-dom"
import { useAuth } from "./AuthProvider"
import { loginRoute } from "../routes"

export default function Login() {
  const auth = useAuth()
  const navigate = useNavigate()
  const supabase = useSupabase()

  const search = useSearch({
    from: loginRoute.fullPath,
  })

  useOnMountUnsafe(() => {
    let loaded = false
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session !== null && !loaded) {
        loaded = true
        flushSync(() => {
          auth.setUser({ session })
        })
        if (search.redirect) {
          navigate({ to: search.redirect })
        } else {
          navigate({ to: "/" })
        }
      }
    })

    return () => subscription.unsubscribe()
  })

  return (
    <div className="flex h-full min-h-full flex-1 flex-col justify-center overflow-auto px-6 py-12 lg:px-8">
      <div className="rounded border border-slate-900 border-slate-950/40 bg-cell p-12 sm:mx-auto sm:w-full sm:max-w-sm">
        <Auth
          supabaseClient={supabase}
          providers={["github"]}
          onlyThirdPartyProviders={true}
          appearance={{ theme: ThemeSupa }}
          showLinks={false}
          redirectTo={window.location.href}
        />
      </div>
    </div>
  )
}
