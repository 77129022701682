import { useLayoutEffect, useRef } from "react"
import useMemoCleanup from "@/packages/components/useMemoCleanup"
import { CellId } from "@/engine/state/types"
import { editorTheme } from "@/packages/codemirror/theme"
import { useEngine } from "../../document/EngineContext"

interface HttpCellViewProps {
  cellId: CellId
}

export default function HttpCellView({ cellId }: HttpCellViewProps) {
  const engine = useEngine()

  const editorContainerRef = useRef<HTMLDivElement>(null)
  const editor = useMemoCleanup(() => {
    const editor = engine.createCellEditor(cellId, [editorTheme])

    return [
      editor,
      () => {
        editor.destroy()
      },
    ]
  }, [cellId])

  useLayoutEffect(() => {
    let container: HTMLDivElement | null = null
    if (editorContainerRef.current) {
      container = editorContainerRef.current
      container.appendChild(editor.dom)
    }
    return () => {
      if (container && editor.dom.parentElement === container) {
        container.removeChild(editor.dom)
      }
    }
  }, [editor])

  return <div className="h-full w-full" ref={editorContainerRef} />
}
