import { Outlet, useMatchRoute } from "@tanstack/react-router"
import { documentRoute } from "../../routes"
import PreviewBoard from "../board/PreviewBoard"
import ToolbarMobile from "./ToolbarMobile"
import Statusbar from "./Statusbar"

export default function DocumentMobile() {
  const matchRoute = useMatchRoute()
  const hasSubRoute = !matchRoute({ to: documentRoute.fullPath })

  return (
    <div className="flex h-full w-full touch-none flex-row">
      <div className="relative flex flex-1 flex-col overflow-hidden">
        <div className="relative min-h-0 w-full flex-1">
          {hasSubRoute ? <Outlet /> : <PreviewBoard />}
          <ToolbarMobile />
        </div>
        <div className="box-content flex-initial">
          <Statusbar />
        </div>
      </div>
      <div className="flex-initial" />
    </div>
  )
}
