import { ViewPlugin } from "@codemirror/view"
import { DecorateMarkdownPlugin } from "./plugin"
import { Extension } from "@codemirror/state"
import "./markdown.css"
import { headingSlugField } from "./headingSlug"

const decorateMarkdownPlugin = ViewPlugin.fromClass(DecorateMarkdownPlugin, {
  decorations: (v) => v.decorations,
})

export const decorateMarkdown = (): Extension => [headingSlugField, decorateMarkdownPlugin]
