import { forwardRef, Ref, useImperativeHandle, useState } from "react"
import { FolderListItem } from "./types"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { createFolderApi } from "@/app/supabase"
import { toast } from "react-toastify"
import { Modal } from "../../components/Modal"
import { Dialog, Heading } from "../../components/Dialog"
import { Select, SelectItem } from "../../components/Select"
import { Form } from "../../components/Form"
import { Button } from "../../components/Button"
import { TextField } from "../../components/TextField"
import { FieldError, Input } from "../../components/Field"

export interface CreateFolderDialogHandle {
  show: (folderId: string | null) => void
}

export default forwardRef(function CreateFolderDialog(
  { folderList }: { folderList: FolderListItem[] },
  ref: Ref<CreateFolderDialogHandle>,
) {
  const queryClient = useQueryClient()

  const { mutate: createFolder } = useMutation({
    mutationFn: createFolderApi,
    onSuccess({ data }) {
      queryClient.invalidateQueries({ queryKey: ["folders"] })
    },
    onError(err) {
      console.error(err)
      toast.error("Failed to create folder: " + err.message)
    },
  })

  const [open, setOpen] = useState(false)
  const [folderId, setFolderId] = useState<string | null>(null)
  const [name, setName] = useState("")

  useImperativeHandle(ref, () => ({
    show(parentFolderId: string | null) {
      setFolderId(parentFolderId)
      setName("")
      setOpen(true)
    },
  }))

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!name) return
    createFolder({ name: name, parentId: folderId === "root" ? null : folderId })
    setOpen(false)
  }

  return (
    <Modal
      isOpen={open}
      isDismissable
      onOpenChange={(open) => {
        if (!open) setOpen(false)
      }}
    >
      <Dialog aria-label="Create folder">
        <Heading slot="title">Create folder</Heading>
        <Form onSubmit={handleSubmit}>
          <TextField
            aria-label="Folder name"
            name="name"
            type="text"
            autoFocus
            isRequired
            value={name}
            onChange={setName}
          >
            <Input />
            <FieldError />
          </TextField>

          <Select
            aria-label="Select parent folder"
            placeholder="Folder"
            selectedKey={folderId}
            onSelectionChange={(key) => setFolderId(key as string)}
          >
            {folderList.map((f) => (
              <SelectItem key={f.id} id={f.id}>
                {f.label || "/"}
              </SelectItem>
            ))}
          </Select>

          <Button type="submit" className="mt-2">
            Create
          </Button>
        </Form>
      </Dialog>
    </Modal>
  )
})
