import { DefaultEvents, EventEmitter, EventObservable } from "../emitter"

export type WorkerBridge<T, Events = DefaultEvents> = {
  [K in keyof T]: T[K] extends (...args: any[]) => Promise<any>
    ? T[K]
    : T[K] extends (...args: infer P) => any
      ? (...args: P) => Promise<ReturnType<T[K]>>
      : never
} & EventObservable<Events>

export type WorkerResponse =
  | {
      type: "result"
      id: string
      value?: any
      error?: any
    }
  | {
      type: "event"
      event: string
      data: any
    }

type WorkerRequest = {
  type: "invoke"
  id: string
  method: string
  args: any[]
}

export function createWorkerBridge<T, Events = DefaultEvents>(
  worker: Worker,
): WorkerBridge<T, Events> {
  const pendingRequests = new Map<string, { resolve: (result: any) => void }>()
  const emitter = new EventEmitter<Events>()

  worker.onmessage = ({ data: response }: { data: WorkerResponse }) => {
    if (response.type === "result") {
      const request = pendingRequests.get(response.id)
      if (request) {
        pendingRequests.delete(response.id)
        if (response.error) {
          request.resolve(Promise.reject(response.error))
        } else {
          request.resolve(response.value)
        }
      }
    } else if (response.type === "event") {
      // @ts-ignore
      emitter.emit(response.event, response.data)
    }
  }

  return new Proxy({} as WorkerBridge<T, Events>, {
    get(_, prop) {
      if (prop === "on") {
        return emitter.on.bind(emitter)
      }
      if (prop === "off") {
        return emitter.off.bind(emitter)
      }
      if (prop === "once") {
        return emitter.once.bind(emitter)
      }
      if (prop === "onAny") {
        return emitter.onAny.bind(emitter)
      }
      if (prop === "offAny") {
        return emitter.offAny.bind(emitter)
      }

      if (typeof prop !== "string") {
        return
      }

      return (...args: any[]) => {
        return new Promise((resolve) => {
          const request: WorkerRequest = {
            type: "invoke",
            id: Math.random().toString(),
            method: prop,
            args,
          }
          pendingRequests.set(request.id, { resolve })
          worker.postMessage(request)
        })
      }
    },
  })
}

export function setupWorker<T, Events = DefaultEvents>(
  window: Window,
  factory: () => Promise<T>,
): EventEmitter<Events> {
  let instance: any
  let initPromise: Promise<T> | undefined
  async function ensureInit() {
    if (!instance) {
      if (!initPromise) {
        initPromise = factory()
      }
      instance = await initPromise
    }
  }

  window.onmessage = async ({ data: request }: { data: WorkerRequest }) => {
    let response: WorkerResponse
    try {
      await ensureInit()

      // eslint-disable-next-line prefer-spread
      const result = await instance[request.method].apply(instance, request.args)
      response = { type: "result", value: result, id: request.id }
    } catch (error) {
      console.error(error)
      response = { type: "result", error, id: request.id }
    }
    postMessage(response)
  }

  const emitter = new EventEmitter<Events>()
  emitter.onAny((event, data) => {
    postMessage({ type: "event", event, data })
  })

  return emitter
}
