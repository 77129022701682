import { CellId, CellType } from "../../state/types"
import { assertUnreachable } from "@/packages/util/assert"
import builtinTypesString from "./builtinTypes.ts?raw"
import { Liquid } from "liquidjs"

const liquid = new Liquid()

const getImports = (cellId: string) =>
  [
    `import type { Context, ScriptInput } from "./cell_${cellId}.types"`,
    `import type { Normalize } from "./builtin-types"`,
  ].join("\n")

const SCRIPT_HEADER = `
class Script {
  async run(
  self: typeof globalThis, 
  $: Normalize<ScriptInput>,
  context: Context,
  console: Console,
  ) {
`.trim()

const SCRIPT_FOOTER = `
  }
}
export type Result = Awaited<ReturnType<Script["run"]>>
`.trim()

const SCRIPT_TYPES_TEMPLATE = liquid.parse(`
import type { Table, HttpResponse, BaseContext } from "./builtin-types"
{% for import in imports %}
{{import}}
{% endfor %}

export type Context = BaseContext

export type ScriptInput = {
  {% for input in inputs %}
  "{{input.name}}": {{input.type}}
  {% endfor %}
}`)

export type InputCellInfo = {
  id: CellId
  name: string
  type: CellType
}

function getCellType(cell: InputCellInfo) {
  switch (cell.type) {
    case "code":
      // If the name starts with a digit the type is not valid
      return `Cell_${cell.id}_Result`
    case "http":
      return "HttpResponse"
    case "text":
      return "string"
    case "table":
      return "Table"
    case "prompt":
      return "string"
    case "snippet":
      return "string"
    case "empty":
      return "never"
    default:
      assertUnreachable(cell.type)
  }
}

export function getBuiltInTypes() {
  return {
    fileName: "/builtin-types.ts",
    fileContent: builtinTypesString,
  }
}

export function buildScriptTypes(cellId: CellId, inputCells: InputCellInfo[]) {
  const imports = inputCells
    .filter((cell) => cell.type === "code")
    .map((cell) => `import { Result as Cell_${cell.id}_Result } from "./cell_${cell.id}"`)

  const inputs = inputCells.map((cell) => ({
    name: cell.name,
    type: getCellType(cell),
  }))

  return {
    fileName: `/cell_${cellId}.types.ts`,
    fileContent: liquid.renderSync(SCRIPT_TYPES_TEMPLATE, { imports, inputs }),
  }
}

export function buildScript(cellId: CellId, content: string) {
  const header = `${getImports(cellId)}\n\n${SCRIPT_HEADER}`

  return {
    fileName: `/cell_${cellId}.tsx`,
    fileContent: `${header}\n${content}\n${SCRIPT_FOOTER}`,
    startIndex: header.length + 1,
  }
}
