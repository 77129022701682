import { Parser } from "hot-formula-parser"

export function evaluateTableData(data: any[][], input: Record<string, any>) {
  const parser = new Parser()

  const cache = new Map<string, any>()
  const refChain = new Set<string>()
  function parseCell(row: number, col: number, formula: string) {
    const key = `${row},${col}`
    if (cache.has(key)) {
      return cache.get(key)
    }
    if (refChain.has(key)) {
      return "#REF!"
    }
    refChain.add(key)
    const res = parser.parse(formula)
    refChain.delete(key)

    let value: any
    if (res.error) {
      value = res.error
    } else {
      value = res.result
    }
    cache.set(key, value)
    return value
  }

  parser.setFunction("GET", (args: any) => {
    return input[args?.[0]]
  })

  parser.on("callCellValue", (cellCoord, done) => {
    const row = cellCoord.row.index
    const col = cellCoord.column.index

    if (row > data.length) {
      return done("")
    }

    const value = data[row][col]
    if (value === undefined) {
      return done("")
    } else if (typeof value === "string" && value.startsWith("=")) {
      return done(parseCell(row, col, value.substring(1)))
    } else {
      return done(value)
    }
  })

  parser.on("callRangeValue", (startCellCoord, endCellCoord, done) => {
    const startCol = startCellCoord.column.index
    const startRow = startCellCoord.row.index
    const endCol = endCellCoord.column.index
    const endRow = endCellCoord.row.index

    const fragment = []
    for (let i = startRow; i <= endRow; i++) {
      const row = []
      for (let j = startCol; j <= endCol; j++) {
        let value = data[i]?.[j]
        if (value === undefined) {
          value = ""
        }
        if (typeof value === "string" && value.startsWith("=")) {
          value = parseCell(i, j, value.substring(1))
        }
        row.push(value)
      }
      fragment.push(row)
    }

    done(fragment)
  })

  const result: any[][] = []
  for (let i = 0; i < data.length; i++) {
    const row = []
    for (let j = 0; j < data[i].length; j++) {
      let value = data[i][j]
      if (typeof value === "string" && value.startsWith("=")) {
        value = parseCell(i, j, value.substring(1))
      }
      row.push(value)
    }
    result.push(row)
  }

  return result
}
