const byteToHex: string[] = []
const hexToByte: Record<string, number> = {}

for (let n = 0; n <= 0xff; n++) {
  const hexOctet = n.toString(16).padStart(2, "0")
  byteToHex.push(hexOctet)
  hexToByte[hexOctet] = n
}

export function encodeToHex(data: Uint8Array): string {
  const output = new Array(data.length)

  for (let i = 0; i < data.length; ++i) {
    output[i] = byteToHex[data[i]]
  }
  return output.join("")
}

export function decodeFromHex(data: string): Uint8Array {
  if (data.length % 2 !== 0) {
    throw new Error("Invalid hex string")
  }

  const length = data.length / 2
  const result = new Uint8Array(length)

  for (let i = 0; i < length; i++) {
    const start = i * 2
    const byte = data.substring(start, start + 2)
    result[i] = hexToByte[byte]
  }

  return result
}

export function areArraysEqual(bytes1: Uint8Array, bytes2: Uint8Array): boolean {
  if (bytes1.length !== bytes2.length) {
    return false
  }

  for (let i = 0; i < bytes1.length; i++) {
    if (bytes1[i] !== bytes2[i]) {
      return false
    }
  }

  return true
}

const textEncoder = new TextEncoder()
const textDecoder = new TextDecoder()

export function encodeUtf8(data: string): Uint8Array {
  return textEncoder.encode(data)
}

export function decodeUtf8(data: Uint8Array): string {
  return textDecoder.decode(data)
}

export function encodeToBase64(data: Uint8Array): string {
  return btoa(String.fromCharCode(...data))
}

export function decodeFromBase64(data: string): Uint8Array {
  return new Uint8Array([...atob(data)].map((c) => c.charCodeAt(0)))
}

export interface Encoder {
  encode: (source: Uint8Array) => Promise<Uint8Array>
  decode: (source: Uint8Array) => Promise<Uint8Array>
}
