import { Engine } from "@/engine"
import { AppStore } from "."
import { cellsSlice } from "./cellsSlice"

export function observeState(store: AppStore, engine: Engine) {
  engine.stateManager.on("setCellsState", (state) => {
    store.dispatch(cellsSlice.actions.setCellState(state))
  })
  engine.stateManager.on("addCell", (cell) => {
    store.dispatch(cellsSlice.actions.addCell(cell))
  })
  engine.stateManager.on("removeCell", (cellId) => {
    store.dispatch(cellsSlice.actions.removeCell(cellId))
  })
  engine.stateManager.on("setCellInfo", (data) => {
    store.dispatch(cellsSlice.actions.setCellInfo(data))
  })
  engine.stateManager.on("setCellInputs", (data) => {
    store.dispatch(cellsSlice.actions.setCellInputs(data))
  })
  engine.stateManager.on("setCellOptions", (data) => {
    store.dispatch(cellsSlice.actions.setCellOptions(data))
  })
  engine.stateManager.on("setCellLayout", (data) => {
    store.dispatch(cellsSlice.actions.setCellLayout(data))
  })
  engine.stateManager.on("setViewportOrigin", (data) => {
    store.dispatch(cellsSlice.actions.setViewportOrigin(data))
  })
  engine.stateManager.on("reset", () => {
    store.dispatch(cellsSlice.actions.reset())
  })
}
