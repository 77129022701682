import { forwardRef, Ref, useImperativeHandle, useState } from "react"
import { FolderListItem } from "./types"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { createDocumentApi } from "@/app/supabase"
import { toast } from "react-toastify"
import { Modal } from "../../components/Modal"
import { Dialog, Heading } from "../../components/Dialog"
import { Select, SelectItem } from "../../components/Select"
import { Checkbox } from "../../components/Checkbox"
import { useNavigate } from "@tanstack/react-router"
import { Form } from "../../components/Form"
import { TextField } from "../../components/TextField"
import { FieldError, Input, Label } from "../../components/Field"
import { Button } from "../../components/Button"

export interface CreateDocumentDialogHandle {
  show: (folderId: string | null) => void
}

export default forwardRef(function CreateDocumentDialog(
  { folderList }: { folderList: FolderListItem[] },
  ref: Ref<CreateDocumentDialogHandle>,
) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate: createDocument } = useMutation({
    mutationFn: createDocumentApi,
    onSuccess({ data }) {
      queryClient.invalidateQueries({ queryKey: ["documents"] })
      queryClient.invalidateQueries({ queryKey: ["folder_documents"] })
      navigate({ to: "/g/$slug", params: { slug: data!.slug }, replace: true })
    },
    onError(err) {
      console.error(err)
      toast.error("Failed to create document: " + err.message)
    },
  })

  const [open, setOpen] = useState(false)
  const [folderId, setFolderId] = useState<string | null>(null)
  const [name, setName] = useState("")
  const [encryption, setEncryption] = useState(false)
  const [passphrase, setPassphrase] = useState("")

  useImperativeHandle(ref, () => ({
    show(parentFolderId: string | null) {
      setFolderId(parentFolderId)
      setName("")
      setEncryption(false)
      setPassphrase("")
      setOpen(true)
    },
  }))

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!name) return
    if (encryption && !passphrase) return

    createDocument({
      name: name,
      folderId: folderId === "root" ? null : folderId,
      passphrase: encryption ? passphrase : null,
    })
    setOpen(false)
  }

  return (
    <Modal
      isOpen={open}
      isDismissable
      onOpenChange={(open) => {
        if (!open) setOpen(false)
      }}
    >
      <Dialog aria-label="Create document">
        <Heading slot="title">Create document</Heading>
        <Form onSubmit={handleSubmit}>
          <TextField
            aria-label="Document name"
            name="name"
            type="text"
            autoFocus
            isRequired
            value={name}
            onChange={setName}
          >
            <Input />
            <FieldError />
          </TextField>
          <Select
            aria-label="Select parent folder"
            selectedKey={folderId}
            placeholder="Folder"
            onSelectionChange={(key) => setFolderId(key as string)}
          >
            {folderList.map((f) => (
              <SelectItem key={f.id} id={f.id}>
                {f.label || "/"}
              </SelectItem>
            ))}
          </Select>

          <Checkbox isSelected={encryption} onChange={setEncryption}>
            Encrypt document
          </Checkbox>

          {encryption && (
            <TextField
              name="passphrase"
              type="password"
              label="Passphrase"
              isRequired
              value={passphrase}
              onChange={setPassphrase}
            >
              <Input />
              <FieldError />
            </TextField>
          )}

          <Button type="submit" className="mt-4">
            Create
          </Button>
        </Form>
      </Dialog>
    </Modal>
  )
})
