export type LogLevel = "LOG" | "DEBUG" | "INFO" | "WARN" | "ERROR" | "ASSERT"
export type LogMessage = {
  level: LogLevel
  timestamp: number
  data: any[]
}

export class InterceptConsole implements Console {
  Console: console.ConsoleConstructor = null as any

  messages: LogMessage[] = []

  assert(condition?: boolean | undefined, ...data: any[]): void {
    console.assert(condition, ...data)
    if (!condition) {
      this.messages.push({
        level: "ASSERT",
        timestamp: Date.now(),
        data,
      })
    }
  }
  clear(): void {
    this.messages = []
  }
  count(label?: string | undefined): void {
    // TODO
  }
  countReset(label?: string | undefined): void {
    // TODO
  }
  debug(...data: any[]): void {
    console.debug(...data)
    this.messages.push({
      level: "DEBUG",
      timestamp: Date.now(),
      data,
    })
  }
  dir(item?: any, options?: any): void {
    // TODO
  }
  dirxml(...data: any[]): void {
    // TODO
  }
  error(...data: any[]): void {
    console.error(...data)
    this.messages.push({
      level: "ERROR",
      timestamp: Date.now(),
      data,
    })
  }
  group(...data: any[]): void {
    // TODO
  }
  groupCollapsed(...data: any[]): void {
    // TODO
  }
  groupEnd(): void {
    // TODO
  }
  info(...data: any[]): void {
    console.info(...data)
    this.messages.push({
      level: "INFO",
      timestamp: Date.now(),
      data,
    })
  }
  log(...data: any[]): void {
    console.log(...data)
    this.messages.push({
      level: "LOG",
      timestamp: Date.now(),
      data,
    })
  }
  table(tabularData?: any, properties?: string[] | undefined): void {
    // TODO
  }
  time(label?: string | undefined): void {
    // TODO
  }
  timeEnd(label?: string | undefined): void {
    // TODO
  }
  timeLog(label?: string | undefined, ...data: any[]): void {
    // TODO
  }
  timeStamp(label?: string | undefined): void {
    // TODO
  }
  trace(...data: any[]): void {
    // TODO
  }
  warn(...data: any[]): void {
    console.warn(...data)
    this.messages.push({
      level: "WARN",
      timestamp: Date.now(),
      data,
    })
  }

  profile(label?: string | undefined): void {
    // TODO
  }
  profileEnd(label?: string | undefined): void {
    // TODO
  }
}
