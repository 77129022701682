import { useEffect, useLayoutEffect, useState } from "react"
import { Vec2 } from "../util/geometry"

export const useViewportResizeEffect = (
  effect: (size: Vec2, prev: Vec2 | null) => void,
  deps: any[],
) => {
  useEffect(() => {
    if (!window.visualViewport) {
      return
    }

    let prev: Vec2 | null = null
    const handleChange = () => {
      const size: Vec2 = [window.visualViewport!.width, window.visualViewport!.height]
      effect(size, prev)
      prev = size
    }

    handleChange()

    window.visualViewport?.addEventListener("resize", handleChange)

    return () => {
      window.visualViewport?.removeEventListener("resize", handleChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export function useMediaQuery(query: string, defaultValue: boolean = false): boolean {
  const [matches, setMatches] = useState<boolean>(defaultValue)

  useLayoutEffect(() => {
    function handleChange(event: MediaQueryListEvent) {
      setMatches(event.matches)
    }

    const matchMedia = window.matchMedia(query)

    if (matchMedia.matches !== matches) {
      setMatches(matchMedia.matches)
    }

    matchMedia.addEventListener("change", handleChange)

    return () => {
      matchMedia.removeEventListener("change", handleChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return matches
}

export function useIsTouchInput() {
  return useMediaQuery("(pointer: coarse)")
}

export function useIsMobile() {
  return useMediaQuery("(max-width: 768px) and (pointer: coarse)")
}
