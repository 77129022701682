import {
  Code2Icon,
  GlobeIcon,
  LayoutGridIcon,
  LucideIcon,
  NotepadTextIcon,
  SheetIcon,
} from "lucide-react"
import { selectCellById } from "@/app/store/cellsSlice"
import { CellId } from "@/engine/state/types"
import { RootState, useAppSelector } from "@/app/store"
import ContentLoader, { IContentLoaderProps } from "react-content-loader"

interface CellViewProps {
  cellId: CellId
}

export default function CellView({ cellId }: CellViewProps) {
  const cell = useAppSelector((state: RootState) => selectCellById(state, cellId))

  const placeholderProps: IContentLoaderProps = {
    animate: false,
    backgroundColor: "#52525b",
    preserveAspectRatio: "xMinYMin meet",
  }

  let placeholder: React.ReactNode
  switch (cell.type) {
    case "code":
      placeholder = <CodePlaceholder {...placeholderProps} />
      break
    case "table":
      placeholder = <TablePlaceholder {...placeholderProps} />
      break
    case "text":
      placeholder = <TextPlaceholder {...placeholderProps} />
      break
    case "http":
      placeholder = <GenericPlaceholder {...placeholderProps} />
      break
    default:
      placeholder = <GenericPlaceholder {...placeholderProps} />
      break
  }

  let Icon: LucideIcon
  switch (cell.type) {
    case "code":
      Icon = Code2Icon
      break
    case "table":
      Icon = SheetIcon
      break
    case "text":
      Icon = NotepadTextIcon
      break
    case "http":
      Icon = GlobeIcon
      break
    default:
      Icon = LayoutGridIcon
      break
  }

  return (
    <div className="relative flex h-full overflow-hidden">
      {placeholder}
      <div className="absolute inset-0 flex h-full flex-row items-center justify-center">
        <Icon className="h-12 w-20" />
        <div className="overflow-hidden text-ellipsis whitespace-nowrap font-mono text-6xl">
          {cell.name}
        </div>
      </div>
    </div>
  )
}

function CodePlaceholder(props: IContentLoaderProps) {
  return (
    <ContentLoader viewBox="0 0 340 150" {...props}>
      <rect x="0" y="0" width="67" height="11" rx="3" />
      <rect x="76" y="0" width="140" height="11" rx="3" />
      <rect x="127" y="48" width="53" height="11" rx="3" />
      <rect x="187" y="48" width="72" height="11" rx="3" />
      <rect x="18" y="48" width="100" height="11" rx="3" />
      <rect x="0" y="71" width="37" height="11" rx="3" />
      <rect x="18" y="23" width="140" height="11" rx="3" />
      <rect x="166" y="23" width="173" height="11" rx="3" />
    </ContentLoader>
  )
}

function TablePlaceholder(props: IContentLoaderProps) {
  return (
    <ContentLoader width="auto" height="auto" viewBox="0 100 1500 400" {...props}>
      <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
      <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
      <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
      <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
      <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="203" r="12" />
      <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="265" r="12" />
      <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="323" r="12" />
      <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="386" r="12" />
      <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
      <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
      <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />
    </ContentLoader>
  )
}

function TextPlaceholder(props: IContentLoaderProps) {
  return (
    <ContentLoader viewBox="0 0 400 200" {...props}>
      <rect x="15" y="15" rx="4" ry="4" width="130" height="10" />
      <rect x="155" y="15" rx="3" ry="3" width="130" height="10" />
      <rect x="295" y="15" rx="3" ry="3" width="90" height="10" />
      <rect x="15" y="50" rx="3" ry="3" width="90" height="10" />
      <rect x="115" y="50" rx="3" ry="3" width="60" height="10" />
      <rect x="185" y="50" rx="3" ry="3" width="200" height="10" />
      <rect x="15" y="90" rx="3" ry="3" width="130" height="10" />
      <rect x="160" y="90" rx="3" ry="3" width="120" height="10" />
      <rect x="290" y="90" rx="3" ry="3" width="95" height="10" />
      <rect x="15" y="130" rx="3" ry="3" width="130" height="10" />
      <rect x="160" y="130" rx="3" ry="3" width="225" height="10" />
    </ContentLoader>
  )
}

function GenericPlaceholder(props: IContentLoaderProps) {
  return <div />
}
