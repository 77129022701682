import { EditorView, WidgetType } from "@codemirror/view"
import { headingSlugField } from "./headingSlug"
import { ChangeSpec } from "@codemirror/state"

export class LineWidget extends WidgetType {
  constructor() {
    super()
  }
  toDOM(): HTMLElement {
    const hr = document.createElement("hr")
    return hr
  }
}

export class LinkMarkerWidget extends WidgetType {
  constructor(
    readonly link: string,
    readonly title: string | null,
  ) {
    super()
  }
  toDOM(view: EditorView): HTMLElement {
    const anchor = document.createElement("a")

    if (this.link.startsWith("#")) {
      // Handle links within the markdown document.
      const slugs = view.state.field(headingSlugField)
      anchor.addEventListener("click", () => {
        const pos = slugs.find((h) => h.slug === this.link.slice(1))?.pos
        // pos could be zero, so instead check if its undefined
        if (typeof pos !== "undefined") {
          const tr = view.state.update({
            selection: { anchor: pos },
            scrollIntoView: true,
          })
          view.dispatch(tr)
        }
      })
    } else {
      anchor.href = this.link
    }

    anchor.target = "_blank"
    anchor.classList.add(classes.link.widget)
    anchor.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
  </svg>`

    if (this.title) {
      anchor.title = this.title
    }

    return anchor
  }
}

export class ListBulletWidget extends WidgetType {
  constructor(readonly bullet: string) {
    super()
  }
  toDOM(): HTMLElement {
    const listBullet = document.createElement("span")
    listBullet.textContent = this.bullet
    listBullet.className = classes.list.bullet
    return listBullet
  }
}

export class CheckboxWidget extends WidgetType {
  constructor(
    public checked: boolean,
    readonly pos: number,
  ) {
    super()
  }
  toDOM(view: EditorView): HTMLElement {
    const wrap = document.createElement("span")
    wrap.classList.add(classes.list.taskCheckbox)
    const checkbox = document.createElement("input")
    checkbox.type = "checkbox"
    checkbox.checked = this.checked
    checkbox.addEventListener("click", ({ target }) => {
      const change: ChangeSpec = {
        from: this.pos,
        to: this.pos + 1,
        insert: this.checked ? " " : "x",
      }
      view.dispatch({ changes: change })
      this.checked = !this.checked
      ;(target as HTMLInputElement).checked = this.checked
    })
    wrap.appendChild(checkbox)
    return wrap
  }
}

export const classes = {
  blockquote: {
    widget: "md-blockquote",
  },
  hr: "md-hr",
  codeblock: {
    inline: "md-inline-code",
    widget: "md-codeblock",
    widgetStart: "md-codeblock-start",
    widgetEnd: "md-codeblock-end",
  },
  heading: {
    heading: "md-heading",
    level: (level: string) => `md-heading-${level}`,
  },
  link: {
    widget: "md-link-marker",
  },
  list: {
    bullet: "md-list-bullet",
    taskCheckbox: "md-task-marker-checkbox",
    taskChecked: "md-task-checked",
  },
}
