import { Fragment, useEffect, useState } from "react"
import DataRenderer from "./DataRenderer"
import { LogMessage } from "@/engine/eval/console"
import ObjectInspector from "@/packages/inspector/ObjectInspector"
import { EvalOutput } from "@/engine/eval"
import { useEngine } from "../document/EngineContext"

export interface OutputPanelProps {
  onClose: () => void
}

const loading = Symbol("loading")

export function OutputPanel({ onClose }: OutputPanelProps) {
  const engine = useEngine()
  const [data, setData] = useState<EvalOutput | null | typeof loading>(null)

  useEffect(() => {
    const state = engine.evalState
    if (state.state === "complete") {
      setData(state.result)
    } else if (state.state === "running") {
      setData(loading)
    } else {
      setData(null)
    }

    const unsubscribe = [
      engine.on("evalStart", () => setData(loading)),
      engine.on("evalComplete", ({ result }) => setData(result)),
    ]
    return () => {
      unsubscribe.forEach((e) => e())
    }
  }, [engine])

  let content: JSX.Element
  if (!data) {
    content = <div className="text-base text-gray-500">No data</div>
  } else if (data === loading) {
    content = <div className="text-base text-gray-500">Evaluating...</div>
  } else if (data.result.success) {
    const output = data.result.output
    content = (
      <Fragment key={output.cellId}>
        <div className="rounded-sm bg-gray-800 p-2">
          <DataRenderer data={output.value} />
        </div>
        <div>
          <ConsoleMessages messages={output.messages} />
        </div>
      </Fragment>
    )
  } else {
    const output = data.result.error
    content = (
      <Fragment key={output.cellId}>
        <div className="font-mono text-sm text-red-400">{output.message}</div>
        <div className="rounded-sm bg-gray-800 p-2">
          <DataRenderer data={output.innerError} />
        </div>
        <div>
          <ConsoleMessages messages={output.messages} />
        </div>
      </Fragment>
    )
  }

  return <div className="relative flex h-full flex-col overflow-y-scroll px-2 pb-4">{content}</div>
}

function ConsoleMessages({ messages }: { messages: LogMessage[] }) {
  return (
    <>
      {messages.length ? (
        <>
          <div className="mb-1 mt-4 text-sm">Console</div>
          {messages.map((message, i) => (
            <div
              key={i}
              className="flex flex-row flex-wrap gap-x-2 gap-y-1 border-b border-gray-800 font-mono text-xs text-red-400"
            >
              {/* <span>[{new Date(message.timestamp).toLocaleTimeString()}]:</span> */}
              {message.data.map((v, i) => (
                <ObjectInspector key={i} data={v} expandLevel={0} />
              ))}
            </div>
          ))}
        </>
      ) : (
        <div />
      )}
    </>
  )
}
