export function assertUnreachable(value: never): never {
  throw new Error("Unexpected value: " + value)
}

export function assertExists<T>(
  val: T | null | undefined,
  message: string | Error = "val does not exist",
): asserts val is T {
  if (val === null || val === undefined) {
    if (message instanceof Error) {
      throw message
    }
    throw new Error(message)
  }
}
