import CellHeader from "../cells/CellHeader"
import CellView from "../cells/CellView"
import { useRouter } from "@tanstack/react-router"
import { useAppSelector } from "@/app/store"
import { selectCellById, selectCellsLoaded } from "@/app/store/cellsSlice"
import { ArrowLeft } from "lucide-react"
import { cellRoute } from "@/app/ui/routes"
import { useEvalShortcut } from "../board/utils"
import { useEffect, useRef } from "react"
import { useEngine } from "./EngineContext"

export default function FullscreenCell() {
  const { cellId, slug } = cellRoute.useParams()
  const router = useRouter()

  const engine = useEngine()
  const containerRef = useRef<HTMLDivElement>(null)

  const cellsLoaded = useAppSelector(selectCellsLoaded)
  const cell = useAppSelector((state) => selectCellById(state, cellId))

  useEffect(() => {
    if (cellsLoaded && !cell) {
      router.navigate({ to: `/g/${slug}` })
    }
  }, [cellsLoaded, cell, router, slug])

  useEvalShortcut(containerRef, cellId, engine)

  if (!cell) {
    return <div>loading...</div>
  }

  return (
    <div
      ref={containerRef}
      className="cell-fullscreen flex h-full w-full flex-col rounded border border-slate-900 bg-cell shadow-lg"
    >
      <div className="flex h-11 w-full flex-initial bg-slate-800/30">
        <button className="px-2" onClick={() => router.history.back()}>
          <ArrowLeft strokeWidth={1} />
        </button>
        <CellHeader cellId={cellId} fullScreen className="flex-1" />
      </div>

      <div className="min-h-0 flex-1">
        <CellView cellId={cellId} />
      </div>
      <div className="flex cursor-ns-resize touch-none items-center justify-end text-gray-400" />
    </div>
  )
}
