import * as csvSync from "csv-stringify/browser/esm/sync"

type TableCellValue = string | number | boolean | null

type TableReference = {
  text: string
  startRow: number
  startColumn: number
  endRow: number
  endColumn: number
}

export type TableWithLookup = Table & ReferenceLookup

interface Table extends Array<TableCellValue[]> {
  readonly rows: number
  readonly columns: number

  resolveReferences(
    references: TableReference[],
  ): TableCellValue | TableCellValue[] | TableCellValue[][]

  [index: number]: TableCellValue[]
}

class CsvArray<T> extends Array<T> {
  public toString(): string {
    if (!this.every((e) => e instanceof Array)) {
      return super.toString()
    }
    return csvSync.stringify(this, { escape: "\\" })
  }
}

export class TableWrapper extends CsvArray<TableCellValue[]> implements Table {
  readonly rows: number
  readonly columns: number

  constructor(cells: TableCellValue[][]) {
    super(...cells)
    this.rows = this.length
    this.columns = this.reduce((max, row) => Math.max(max, row.length), 0)
  }

  static get [Symbol.species]() {
    return Array
  }

  public toString(): string {
    if (this.length === 0) {
      return super.toString()
    }
    return csvSync.stringify(this, { escape: "\\" })
  }

  resolveReferences(
    references: TableReference[],
  ): TableCellValue | TableCellValue[] | TableCellValue[][] {
    if (!references.length) return null

    let resultStartRow = +Infinity
    let resultStartColumn = +Infinity
    let resultEndRow = -Infinity
    let resultEndColumn = -Infinity

    references = references.map((reference) => {
      let { startRow, endRow, startColumn, endColumn } = reference
      if (startRow === -Infinity || startRow < 0) startRow = 0
      if (endRow === +Infinity) endRow = this.rows - 1
      if (startColumn === -Infinity || startColumn < 0) startColumn = 0
      if (endColumn === +Infinity) endColumn = this.columns - 1

      resultStartRow = Math.min(startRow, resultStartRow)
      resultStartColumn = Math.min(startColumn, resultStartColumn)
      resultEndRow = Math.max(endRow, resultEndRow)
      resultEndColumn = Math.max(endColumn, resultEndColumn)

      return { ...reference, startRow, endRow, startColumn, endColumn }
    })

    const result: TableCellValue[][] = new CsvArray(resultEndRow - resultStartRow + 1)

    for (const reference of references) {
      for (let i = reference.startRow; i <= reference.endRow; i++) {
        const resultRow = i - resultStartRow
        if (result[resultRow] === undefined) {
          result[resultRow] = new Array(resultEndColumn - resultStartColumn + 1)
        }
        for (let j = reference.startColumn; j <= reference.endColumn; j++) {
          const resultColumn = j - resultStartColumn
          result[resultRow][resultColumn] = this[i][j]
        }
      }
    }

    if (result.length === 1 && result[0].length === 1) {
      return result[0][0]
    } else if (result.length === 1) {
      return result[0]
    } else if (result[0].length === 1) {
      return result.map((row) => row[0])
    }

    return result
  }
}

function parseTablePosition(position: string): [number | null, number | null] {
  const parts = /^([A-Z]*)(\d*)$/.exec(position.toUpperCase())
  if (!parts) throw new Error("Invalid table position: " + position)

  const [column, row] = parts.slice(1)
  const rowIndex = row ? parseInt(row) - 1 : null
  let columnIndex: number | null = null

  if (column) {
    columnIndex = 0
    for (let i = 0; i < column.length; i++) {
      const code = column.charCodeAt(i)
      columnIndex += (code - 65) * 26 ** (column.length - i - 1)
    }
  }

  return [rowIndex, columnIndex]
}

function parseTableReference(reference: string): TableReference {
  const referenceParts = reference.split(":")
  if (referenceParts.length > 2) {
    throw new Error("Invalid table reference: " + reference)
  }

  const positions = referenceParts.map(parseTablePosition)
  let [[startRow, startColumn], [endRow, endColumn]] = [
    [positions[0][0], positions[0][1]],
    [positions[1] ? positions[1][0] : null, positions[1] ? positions[1][1] : null],
  ]

  if (startRow !== null && endRow !== null) {
    startRow = Math.min(startRow, endRow)
    endRow = Math.max(startRow, endRow)
  } else if (startRow !== null) {
    endRow = startRow
  } else if (endRow !== null) {
    startRow = endRow
  } else {
    startRow = -Infinity
    endRow = +Infinity
  }

  if (startColumn !== null && endColumn !== null) {
    startColumn = Math.min(startColumn, endColumn)
    endColumn = Math.max(startColumn, endColumn)
  } else if (startColumn !== null) {
    endColumn = startColumn
  } else if (endColumn !== null) {
    startColumn = endColumn
  } else {
    startColumn = -Infinity
    endColumn = +Infinity
  }

  return {
    text: reference,
    startRow,
    startColumn,
    endRow,
    endColumn,
  }
}

type ReferenceLookup = {
  [reference: string]: TableCellValue[][]
}

function createProxy<T = TableWrapper>(table: T): T & ReferenceLookup {
  return new Proxy<any>(table, {
    get(target, prop) {
      if (typeof prop === "number") {
        return target[prop]
      }

      if (prop in target) {
        // @ts-ignore
        return target[prop]
      }

      if (typeof prop === "string") {
        try {
          const references = prop.split(",").map((r) => parseTableReference(r.trim()))
          return target.resolveReferences(references)
        } catch (e) {
          return "ERROR: " + e
        }
      }
    },
  })
}

export function createTable(data: TableCellValue[][] = [[]]): TableWithLookup {
  return createProxy(new TableWrapper(data))
}
