// Based on: https://github.com/retronav/ixora

import { Decoration, DecorationSet, EditorView, ViewUpdate } from "@codemirror/view"
import { Range } from "@codemirror/state"
import {
  ContentRange,
  checkRangeOverlap,
  editorLines,
  invisibleDecoration,
  isCursorInRange,
  iterateTreeInVisibleRanges,
} from "./util"
import { SyntaxNodeRef } from "@lezer/common"
import { classes, CheckboxWidget, LinkMarkerWidget, ListBulletWidget, LineWidget } from "./widgets"

const headingLevelRegex = /[1-6]$/
const atxMarkerRegex = /^#+\s*\S+/
const autoLinkMarkRegex = /^<|>$/g
const quoteMarkRegex = /^(\s*>+)/gm
const bulletListMarkerRegex = /^[-+*]/
const leadingSpaceRegex = /^\s+/

const headingTypes = [
  "ATXHeading1",
  "ATXHeading2",
  "ATXHeading3",
  "ATXHeading4",
  "ATXHeading5",
  "ATXHeading6",
  "SetextHeading1",
  "SetextHeading2",
]

const textStyleTypes = ["Emphasis", "StrongEmphasis", "InlineCode", "Strikethrough"]
const textStyleMarks = ["EmphasisMark", "CodeMark", "StrikethroughMark"]

export class DecorateMarkdownPlugin {
  decorations: DecorationSet

  constructor(view: EditorView) {
    this.decorations = this.handleDocUpdate(view)
  }

  update(update: ViewUpdate) {
    if (update.docChanged || update.viewportChanged || update.selectionSet || update.focusChanged) {
      this.decorations = this.handleDocUpdate(update.view)
    }
  }

  private handleDocUpdate(view: EditorView) {
    const decorations: Range<Decoration>[] = []
    const context: Record<string, any> = {}

    iterateTreeInVisibleRanges(view, {
      enter: (nodeRef) => {
        const textDecorations = this.decorateTextStyles(view, nodeRef, context)
        if (textDecorations?.length) decorations.push(...textDecorations)

        const headingDecorations = this.decorateHeadings(view, nodeRef)
        if (headingDecorations?.length) decorations.push(...headingDecorations)

        const hrDecorations = this.decorateHorizontalRules(view, nodeRef)
        if (hrDecorations?.length) decorations.push(...hrDecorations)

        const codeBlockDecorations = this.decorateCodeBlocks(view, nodeRef)
        if (codeBlockDecorations?.length) decorations.push(...codeBlockDecorations)

        const blockquoteDecorations = this.decorateBlockquotes(view, nodeRef)
        if (blockquoteDecorations?.length) decorations.push(...blockquoteDecorations)

        const linkDecorations = this.decorateLinks(view, nodeRef)
        if (linkDecorations?.length) decorations.push(...linkDecorations)

        const listDecorations = this.decorateLists(view, nodeRef)
        if (listDecorations?.length) decorations.push(...listDecorations)

        const taskListDecorations = this.decorateTaskLists(view, nodeRef)
        if (taskListDecorations?.length) decorations.push(...taskListDecorations)
      },
    })

    return Decoration.set(decorations, true)
  }

  private decorateTextStyles(
    view: EditorView,
    nodeRef: SyntaxNodeRef,
    context: Record<string, any>,
  ) {
    if (!textStyleTypes.includes(nodeRef.name)) return

    // There can be a possibility that the current node is a
    // child eg. a bold node in a emphasis node, so check
    // for that or else save the node range
    const parentRange: ContentRange = context["parentRange"]
    if (parentRange && checkRangeOverlap([nodeRef.from, nodeRef.to], parentRange)) return

    context["parentRange"] = [nodeRef.from, nodeRef.to]

    const decorations: Range<Decoration>[] = []

    if (nodeRef.name === "InlineCode") {
      decorations.push(
        Decoration.mark({
          tagName: "code",
          attributes: { spellcheck: "false" },
          class: classes.codeblock.inline,
        }).range(nodeRef.from, nodeRef.to),
      )
    }

    if (!isCursorInRange(view, [nodeRef.from, nodeRef.to])) {
      nodeRef.node.toTree().iterate({
        enter({ type, from: markFrom, to: markTo }) {
          if (!textStyleMarks.includes(type.name)) return
          decorations.push(
            invisibleDecoration.range(nodeRef.from + markFrom, nodeRef.from + markTo),
          )
        },
      })
    }
    return decorations
  }

  private decorateHeadings(view: EditorView, nodeRef: SyntaxNodeRef) {
    let decorations: Range<Decoration>[] | undefined

    if (headingTypes.includes(nodeRef.name)) {
      decorations ??= []
      const level = headingLevelRegex.exec(nodeRef.name)![0]
      const decoration = Decoration.line({
        class: [classes.heading.heading, classes.heading.level(level)].join(" "),
      })
      decorations.push(decoration.range(view.state.doc.lineAt(nodeRef.from).from))
    } else if (nodeRef.name === "HeaderMark") {
      const line = view.lineBlockAt(nodeRef.from)
      const cursorOverlaps = isCursorInRange(view, [line.from, line.to])
      if (!cursorOverlaps) {
        decorations ??= []
        const lineText = view.state.sliceDoc(nodeRef.from, line.to)
        if (atxMarkerRegex.test(lineText)) {
          decorations.push(invisibleDecoration.range(nodeRef.from, nodeRef.to + 1))
        }
      }
    }

    return decorations
  }

  private decorateHorizontalRules(view: EditorView, nodeRef: SyntaxNodeRef) {
    if (nodeRef.name !== "HorizontalRule") return

    const decorations: Range<Decoration>[] = []
    const line = view.lineBlockAt(nodeRef.from)
    const cursorOverlaps = isCursorInRange(view, [line.from, line.to])
    if (!cursorOverlaps) {
      decorations.push(
        Decoration.line({
          class: classes.hr,
        }).range(line.from),
      )
      decorations.push(
        Decoration.widget({
          widget: new LineWidget(),
        }).range(nodeRef.from, nodeRef.from),
      )
      decorations.push(
        Decoration.mark({
          tagName: "span",
        }).range(nodeRef.from, nodeRef.to),
      )
    }
    return decorations
  }

  private decorateCodeBlocks(view: EditorView, nodeRef: SyntaxNodeRef) {
    if (!["FencedCode", "CodeBlock"].includes(nodeRef.name)) return

    const decorations: Range<Decoration>[] = []

    editorLines(view, nodeRef.from, nodeRef.to).forEach((block, i) => {
      const cls = [
        classes.codeblock.widget,
        i === 0
          ? classes.codeblock.widgetStart
          : block.to === nodeRef.to
            ? classes.codeblock.widgetEnd
            : "",
      ]
      const lineDec = Decoration.line({
        attributes: { spellcheck: "false" },
        class: cls.join(" "),
      })
      decorations.push(lineDec.range(block.from))
    })

    if (!isCursorInRange(view, [nodeRef.from, nodeRef.to])) {
      const codeBlock = nodeRef.node.toTree()
      codeBlock.iterate({
        enter: ({ type, from: nodeFrom, to: nodeTo }) => {
          switch (type.name) {
            case "CodeInfo":
            case "CodeMark": {
              const decRange = invisibleDecoration.range(
                nodeRef.from + nodeFrom,
                nodeRef.from + nodeTo,
              )
              decorations.push(decRange)
              break
            }
          }
        },
      })
    }

    return decorations
  }

  private decorateLinks(view: EditorView, nodeRef: SyntaxNodeRef) {
    if (nodeRef.name !== "URL") return

    const decorations: Range<Decoration>[] = []
    const parent = nodeRef.node.parent

    // FIXME: make this configurable
    const blackListedParents = ["Image"]
    if (parent && !blackListedParents.includes(parent.name)) {
      const marks = parent.getChildren("LinkMark")
      const linkTitle = parent.getChild("LinkTitle")

      let cursorOverlaps = isCursorInRange(view, [parent.from, parent.to])
      if (!cursorOverlaps && marks.length > 0) {
        decorations.push(
          ...marks.map(({ from, to }) => invisibleDecoration.range(from, to)),
          invisibleDecoration.range(nodeRef.from, nodeRef.to),
        )
        if (linkTitle) {
          decorations.push(invisibleDecoration.range(linkTitle.from, linkTitle.to))
        }
      }

      let linkContent = view.state.sliceDoc(nodeRef.from, nodeRef.to)
      if (autoLinkMarkRegex.test(linkContent)) {
        // Remove '<' and '>' from link and content
        linkContent = linkContent.replace(autoLinkMarkRegex, "")
        cursorOverlaps = isCursorInRange(view, [nodeRef.from, nodeRef.to])
        if (!cursorOverlaps) {
          decorations.push(
            invisibleDecoration.range(nodeRef.from, nodeRef.from + 1),
            invisibleDecoration.range(nodeRef.to - 1, nodeRef.to),
          )
        }
      }
      const linkTitleContent = linkTitle ? view.state.sliceDoc(linkTitle.from, linkTitle.to) : null
      const decoration = Decoration.widget({
        widget: new LinkMarkerWidget(linkContent, linkTitleContent),
        side: 1,
      })
      decorations.push(decoration.range(nodeRef.to, nodeRef.to))

      return decorations
    }
  }

  private decorateBlockquotes(view: EditorView, nodeRef: SyntaxNodeRef) {
    if (nodeRef.name !== "Blockquote") return

    const decorations: Range<Decoration>[] = []

    const lines = editorLines(view, nodeRef.from, nodeRef.to)
    lines.forEach((line) => {
      decorations.push(
        Decoration.line({
          class: classes.blockquote.widget,
        }).range(line.from),
      )
    })

    if (lines.every((line) => !isCursorInRange(view, [line.from, line.to]))) {
      Array.from(view.state.sliceDoc(nodeRef.from, nodeRef.to).matchAll(quoteMarkRegex))
        .map((x) => nodeRef.from + x.index!)
        .forEach((i) => decorations.push(invisibleDecoration.range(i, i + 1)))
    }
    return decorations
  }

  private decorateLists(view: EditorView, nodeRef: SyntaxNodeRef) {
    if (nodeRef.name !== "ListMark") return
    if (isCursorInRange(view, [nodeRef.from, nodeRef.to])) return
    const decorations: Range<Decoration>[] = []

    const listMark = view.state.sliceDoc(nodeRef.from, nodeRef.to)
    if (bulletListMarkerRegex.test(listMark)) {
      decorations.push(
        Decoration.replace({
          widget: new ListBulletWidget(listMark),
        }).range(nodeRef.from, nodeRef.to),
      )
    }
    return decorations
  }

  private decorateTaskLists(view: EditorView, nodeRef: SyntaxNodeRef) {
    if (nodeRef.name !== "Task") return

    const decorations: Range<Decoration>[] = []

    nodeRef.node.toTree().iterate({
      enter({ type, from, to }) {
        if (type.name !== "TaskMarker") return
        if (isCursorInRange(view, [nodeRef.from + from, nodeRef.from + to])) return

        const checkbox = view.state.sliceDoc(nodeRef.from + from, nodeRef.from + to)
        const checked = "xX".includes(checkbox[1])

        decorations.push(
          Decoration.replace({
            widget: new CheckboxWidget(checked, nodeRef.from + from + 1),
          }).range(nodeRef.from + from, nodeRef.from + to),
        )

        if (checked) {
          const text = view.state.sliceDoc(nodeRef.from + to, nodeRef.to)
          const whiteSpace = leadingSpaceRegex.exec(text)?.[0].length ?? 0
          decorations.push(
            Decoration.mark({
              tagName: "span",
              class: classes.list.taskChecked,
            }).range(nodeRef.from + to + whiteSpace, nodeRef.to),
          )
        }
      },
    })

    return decorations
  }
}
