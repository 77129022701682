import type { EffectCallback } from "react"
import { useEffect, useRef } from "react"

// Workaround for strict mode calling effects twice for when there's no easy way to properly handle that
export function useOnMountUnsafe(effect: EffectCallback) {
  const initialized = useRef(false)

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      effect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
