import { Engine, createEngine } from "@/engine"
import { getDocumentBySlugQuery } from "@/app/supabase"
import { useSuspenseQuery } from "@tanstack/react-query"
import { useState, useEffect, memo } from "react"
import { useIsMobile } from "@/packages/components/responsive"
import { useAppStore } from "@/app/store"
import { observeState } from "@/app/store/observer"
import DocumentMobile from "./DocumentMobile"
import DocumentDesktop from "./DocumentDesktop"
import DocumentPassphase from "./DocumentPasspharse"
import { Encoder } from "@/packages/util/encoding"
import { documentRoute } from "../../routes"
import { EngineContext } from "./EngineContext"
import { useSaveShortcut } from "../board/utils"

export default memo(function Document() {
  const { slug } = documentRoute.useParams()
  const { data: document } = useSuspenseQuery(getDocumentBySlugQuery(slug))

  const store = useAppStore()

  const [engine, setEngine] = useState<Engine | null>(null)
  const [documentSecurity, setDocumentSecurity] = useState<[string, string, Encoder] | null>(null)

  const isMobile = useIsMobile()

  useEffect(() => {
    if (!document) return

    if (document.encryption && !documentSecurity) return
    if (documentSecurity && documentSecurity[0] !== document.id) return

    console.log("init engine")
    const engine = createEngine(
      document.y_doc_id,
      documentSecurity?.[1] ?? document.p2p_key,
      documentSecurity?.[2] ?? null,
    )
    observeState(store, engine)
    engine.init()
    setEngine(engine)
    return () => {
      setDocumentSecurity(null)
      setEngine(null)
      engine.destroy()
    }
  }, [document, documentSecurity, store])

  useSaveShortcut(engine)

  console.log("render Document")

  if (document?.encryption && !documentSecurity) {
    return (
      <DocumentPassphase
        p2pKey={document.p2p_key}
        config={document.encryption as any}
        onSuccess={(k, e) => setDocumentSecurity([document.id, k, e])}
      />
    )
  }

  if (!engine) {
    return (
      <div className="blueprint-background h-full w-full animate-pulse pt-2 text-center text-gray-600">
        loading...
      </div>
    )
  }

  const component = isMobile ? (
    <DocumentMobile key={engine.docId} />
  ) : (
    <DocumentDesktop key={engine.docId} />
  )

  return <EngineContext.Provider value={engine}>{component}</EngineContext.Provider>
})
