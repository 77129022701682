import { useEffect, useLayoutEffect, useRef } from "react"
import { editorTheme } from "@/packages/codemirror/theme"
import useMemoCleanup from "@/packages/components/useMemoCleanup"
import { EditorState } from "@codemirror/state"
import { EditorView } from "@codemirror/view"
import { getHttpExtensions } from "@/engine/http"

interface HttpResponseViewProps {
  content: string
  className?: string
}

export default function HttpResponseView({ content }: HttpResponseViewProps) {
  const editorContainerRef = useRef<HTMLDivElement>(null)
  const editor = useMemoCleanup(() => {
    const editor = new EditorView({
      state: EditorState.create({
        extensions: [getHttpExtensions(), EditorState.readOnly.of(true), editorTheme],
      }),
    })

    editor.dispatch({
      changes: { from: 0, to: editor.state.doc.length, insert: content },
    })

    return [
      editor,
      () => {
        editor.destroy()
      },
    ]
  }, [])

  useLayoutEffect(() => {
    let container: HTMLDivElement | null = null
    if (editorContainerRef.current) {
      container = editorContainerRef.current
      container.appendChild(editor.dom)
    }
    return () => {
      if (container && editor.dom.parentElement === container) {
        container.removeChild(editor.dom)
      }
    }
  }, [editor])

  useEffect(() => {
    const currentValue = editor.state.doc.toString()
    if (content !== currentValue) {
      editor.dispatch({
        changes: { from: 0, to: currentValue.length, insert: content },
      })
    }
  }, [content, editor])

  return (
    <div className="flex h-full flex-row">
      <div className="w-full flex-1">
        <div className="h-full" ref={editorContainerRef} />
      </div>
    </div>
  )
}
