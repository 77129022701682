import { useEffect, useState } from "react"
import { Callable } from "@/packages/util/types"
import { typeScriptWorker } from "@/engine/ts"
import { CircleCheckIcon, CircleDashedIcon, CircleEllipsisIcon, CircleOffIcon } from "lucide-react"
import { useEngine } from "./EngineContext"

export default function Statusbar() {
  const engine = useEngine()
  const [rtcStatus, setRtcStatus] = useState(engine.stateManager.ySync.rtcStatus)
  const [remoteStatus, setRemoteStatus] = useState(engine.stateManager.ySync.remoteStatus)
  const [ataStatus, setAtaStatus] = useState("idle")
  const [evalStatus, setEvalStatus] = useState("idle")

  useEffect(() => {
    const unsubscribe: Callable[] = []

    setRtcStatus(engine.stateManager.ySync.rtcStatus)
    unsubscribe.push(
      engine.stateManager.ySync.on("rtcStatus", () => {
        setRtcStatus(engine.stateManager.ySync.rtcStatus)
      }),
    )

    setRemoteStatus(engine.stateManager.ySync.remoteStatus)
    unsubscribe.push(
      engine.stateManager.ySync.on("remoteStatus", () => {
        setRemoteStatus(engine.stateManager.ySync.remoteStatus)
      }),
    )

    unsubscribe.push(
      typeScriptWorker.on("ataStatus", ({ status }) => {
        setAtaStatus(status)
      }),
    )

    unsubscribe.push(
      engine.on("evalStart", () => {
        setEvalStatus("running")
      }),
    )
    unsubscribe.push(
      engine.on("evalComplete", () => {
        setEvalStatus("idle")
      }),
    )

    return () => {
      unsubscribe.forEach((e) => e())
    }
  }, [engine])

  const syncNow = () => {
    if (remoteStatus.hasPendingUpdates) {
      engine.stateManager.ySync.saveToRemote()
    } else {
      engine.stateManager.ySync.syncRemote()
    }
  }

  return (
    <div
      className="box-content flex h-6 items-center justify-between border-t border-zinc-900 bg-ui px-4 font-mono text-xs text-gray-500"
      style={{
        paddingBottom: "env(safe-area-inset-bottom)",
      }}
    >
      <div className="flex items-center space-x-1">
        <div>WebRTC:</div>
        <div>
          {rtcStatus.connected ? (
            <CircleCheckIcon className="w-4" strokeWidth={1} />
          ) : (
            <CircleOffIcon className="w-4" strokeWidth={1} />
          )}
        </div>
        <div>|</div>
        <div>Remote:</div>
        <div onClick={syncNow} className="cursor-pointer">
          {!remoteStatus.connected ? (
            <CircleOffIcon className="w-4" strokeWidth={1} />
          ) : remoteStatus.isSyncing ? (
            <CircleEllipsisIcon className="w-4 animate-pulse" strokeWidth={1} />
          ) : remoteStatus.hasPendingUpdates ? (
            <CircleDashedIcon className="w-4" strokeWidth={1} />
          ) : (
            <CircleCheckIcon className="w-4" strokeWidth={1} />
          )}
        </div>
      </div>
      <div className="ml-2 flex items-center space-x-1">
        <div>Eval:</div>
        <div>
          {evalStatus === "idle" ? (
            <CircleCheckIcon className="w-4" strokeWidth={1} />
          ) : (
            <CircleEllipsisIcon className="w-4 animate-pulse" strokeWidth={1} />
          )}
        </div>
        <div>|</div>
        <div>ATA:</div>
        <div>
          {ataStatus === "idle" ? (
            <CircleCheckIcon className="w-4" strokeWidth={1} />
          ) : ataStatus === "loading" ? (
            <CircleEllipsisIcon className="w-4 animate-pulse" strokeWidth={1} />
          ) : (
            <CircleDashedIcon className="w-4" strokeWidth={1} />
          )}
        </div>
      </div>
    </div>
  )
}
