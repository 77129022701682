import {
  archiveDocumentApi,
  deleteFolderApi,
  getDocumentsQuery,
  getFolderDocumentsQuery,
  getFoldersQuery,
} from "@/app/supabase"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Link } from "@tanstack/react-router"
import {
  ChevronDownIcon,
  ChevronRightIcon,
  FilePlusIcon,
  FolderPlusIcon,
  Icon,
  LockKeyholeIcon,
  PlusIcon,
} from "lucide-react"
import { Fragment, useMemo, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"
import { useLocalUiState } from "../../components/useUiState"
import ContextMenu, { ContextMenuHandle } from "../../components/ContextMenu"
import { useLongPress } from "react-aria"
import { FolderListItem, FolderTreeItem, SelectedNavItem } from "./types"
import MoveItemDialog, { MoveItemDialogHandle } from "./MoveItemDialog"
import RenameItemDialog, { RenameItemDialogHandle } from "./RenameItemDialog"
import CreateFolderDialog, { CreateFolderDialogHandle } from "./CreateFolderDialog"
import CreateDocumentDialog, { CreateDocumentDialogHandle } from "./CreateDocumentDialog"
import { IconButton } from "../../components/Button"
import { toast } from "react-toastify"

export default function DocumentTree({ onSelect }: { onSelect?: () => void }) {
  const { folderTree, folderList, findFolder, findDocument } = useFolderData()

  const { deleteFolder, archiveDocument } = useDocumentTreeMutations()

  const contextMenuRef = useRef<ContextMenuHandle>(null)
  const moveItemDialogRef = useRef<MoveItemDialogHandle>(null)
  const renameItemDialogRef = useRef<RenameItemDialogHandle>(null)
  const createFolderDialogRef = useRef<CreateFolderDialogHandle>(null)
  const createDocumentDialogRef = useRef<CreateDocumentDialogHandle>(null)

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null)

  const [collapsedFolders, setCollapsedFolders] = useLocalUiState<string[]>("navState", [])

  const toggleFolder = (folderId: string) => {
    if (collapsedFolders.includes(folderId)) {
      setCollapsedFolders(collapsedFolders.filter((f) => f !== folderId))
    } else {
      setCollapsedFolders([...collapsedFolders, folderId])
    }
  }

  const showFolderContextMenu = (folderId: string, x: number, y: number) => {
    const folder = findFolder(folderId)
    if (!folder) return

    setSelectedItemId(folderId)

    contextMenuRef.current?.open(
      [
        {
          label: "New Folder",
          type: "option",
          onClick: () => {
            createFolderDialogRef.current?.show(folderId)
          },
        },
        {
          label: "New Document",
          type: "option",
          onClick: () => {
            createDocumentDialogRef.current?.show(folderId)
          },
        },
        {
          type: "separator",
        },
        {
          label: "Rename Folder",
          type: "option",
          onClick: () => {
            renameItemDialogRef.current?.show({
              type: "folder",
              id: folderId,
              name: folder.name,
            })
          },
        },
        {
          label: "Move Folder",
          type: "option",
          onClick: () => {
            moveItemDialogRef.current?.show({ type: "folder", id: folderId, name: folder.name })
          },
        },
        {
          label: "Delete Folder",
          type: "option",
          onClick: () => {
            if (confirm("Are you sure you want to delete this folder?")) {
              deleteFolder({ id: folderId })
            }
          },
        },
      ],
      { x, y },
    )
  }

  const showDocumentContextMenu = (documentId: string, x: number, y: number) => {
    const document = findDocument(documentId)
    if (!document) return

    setSelectedItemId(documentId)

    contextMenuRef.current?.open(
      [
        {
          label: "Rename Document",
          type: "option",
          onClick: () => {
            renameItemDialogRef.current?.show({
              type: "document",
              id: documentId,
              name: document.name,
            })
          },
        },
        {
          label: "Move Document",
          type: "option",
          onClick: () => {
            moveItemDialogRef.current?.show({
              type: "document",
              id: documentId,
              name: document.name,
            })
          },
        },
        {
          label: "Archive Document",
          type: "option",
          onClick: () => {
            if (confirm("Are you sure you want to archive this document?")) {
              archiveDocument({ id: documentId })
            }
          },
        },
      ],
      { x, y },
    )
  }

  const { longPressProps: folderLongPressProps } = useLongPress({
    onLongPress: (e) => {
      const folderId = e.target.getAttribute("data-folder-id")
      if (folderId) {
        const bounds = e.target.getBoundingClientRect()
        showFolderContextMenu(folderId, bounds.left + e.x, bounds.top + e.y)
      }
    },
  })

  const { longPressProps: documentLongPressProps } = useLongPress({
    onLongPress: (e) => {
      const documentId = e.target.getAttribute("data-document-id")
      if (documentId) {
        const bounds = e.target.getBoundingClientRect()
        showDocumentContextMenu(documentId, bounds.left + e.x, bounds.top + e.y)
      }
    },
  })

  const renderFolder = (folder: typeof folderTree, depth = 0) => {
    const padding = (depth + 1) * 1
    return (
      <ul className={twMerge("ml-4 border-l border-dotted border-slate-700")}>
        <li className="flex flex-col">
          {folder.children.map((f) => (
            <Fragment key={f.id}>
              <button
                className="group flex items-center gap-x-1 py-1 pr-4 text-sm text-gray-400 data-[selected=true]:bg-slate-950/20 hover:bg-slate-950/20"
                data-folder-id={f.id}
                data-selected={selectedItemId === f.id}
                style={{
                  marginLeft: `-${padding}rem`,
                  paddingLeft: `${padding + 0.5}rem`,
                }}
                onContextMenu={(e) => {
                  e.preventDefault()
                  showFolderContextMenu(f.id, e.pageX, e.pageY)
                }}
                {...folderLongPressProps}
                onClick={(_) => toggleFolder(f.id)}
              >
                {collapsedFolders.includes(f.id) ? (
                  <ChevronRightIcon className="h-4 w-4" strokeWidth={1} />
                ) : (
                  <ChevronDownIcon className="h-4 w-4" strokeWidth={1} />
                )}

                <span className="flex-1 text-left">{f.name}</span>

                {/* <button className="hidden group-hover:block">
                  <FolderPlusIcon className="h-4 w-4" strokeWidth={1} />
                </button>
                <button className="hidden group-hover:block">
                  <FilePlusIcon className="h-4 w-4" strokeWidth={1} />
                </button> */}
              </button>
              {!collapsedFolders.includes(f.id) && renderFolder(f, depth + 1)}
            </Fragment>
          ))}
        </li>
        {folder.documents.map((document) => (
          <li key={document.id}>
            <Link
              to="/g/$slug"
              params={{ slug: document.slug }}
              data-document-id={document.id}
              data-selected={selectedItemId === document.id}
              className="flex items-center gap-x-1 py-1 pl-3 pr-2 text-sm text-gray-300 data-[selected=true]:bg-slate-950/20 hover:bg-slate-950/20"
              style={{
                marginLeft: `-${padding}rem`,
                paddingLeft: `${padding + 0.75}rem`,
                WebkitTouchCallout: "none",
              }}
              activeProps={{ className: "text-gray-100 font-semibold" }}
              {...(documentLongPressProps as any)}
              onClick={onSelect}
              onContextMenu={(e) => {
                e.preventDefault()
                showDocumentContextMenu(document.id, e.pageX, e.pageY)
              }}
            >
              <span>{document.name}</span>
              {document.encryption && <LockKeyholeIcon className="h-3 w-3" />}
            </Link>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <>
      <div className="flex items-center gap-x-1 pb-1 pl-3 pr-2 text-sm text-gray-500">
        <h5 className="font-semibold">Documents</h5>
        <div className="flex-1" />
        <IconButton onPress={() => createFolderDialogRef.current?.show(null)}>
          <FolderPlusIcon className="h-4 w-4" strokeWidth={1.5} />
        </IconButton>
        <IconButton onPress={() => createDocumentDialogRef.current?.show(null)}>
          <PlusIcon className="h-4 w-4" strokeWidth={1.5} />
        </IconButton>
      </div>
      <div className="flex w-full flex-1 flex-col overflow-x-auto overflow-y-auto">
        <ul role="list" className="space-y-1">
          {renderFolder(folderTree)}
          <ContextMenu ref={contextMenuRef} onClose={() => setSelectedItemId(null)} />
          <MoveItemDialog ref={moveItemDialogRef} folderList={folderList} />
          <RenameItemDialog ref={renameItemDialogRef} />
          <CreateFolderDialog ref={createFolderDialogRef} folderList={folderList} />
          <CreateDocumentDialog ref={createDocumentDialogRef} folderList={folderList} />
        </ul>
      </div>
    </>
  )
}

function useFolderData() {
  const {
    data: documents,
    error: documentsError,
    isPending: documentsPending,
  } = useQuery(getDocumentsQuery())
  const {
    data: folders,
    error: foldersError,
    isPending: foldersPending,
  } = useQuery(getFoldersQuery())
  const {
    data: folderDocuments,
    error: folderDocumentsError,
    isPending: folderDocumentsPending,
  } = useQuery(getFolderDocumentsQuery())

  const folderTree = useMemo(() => {
    if (!folders || !folderDocuments || !documents)
      return {
        name: "root",
        id: "root",
        children: [],
        documents: [],
      }

    const documentsInFolders: string[] = []

    const buildFolder = (
      folder: Exclude<typeof folders, null | undefined>[0],
      chain: string[] = [],
    ): FolderTreeItem => {
      return {
        id: folder.id,
        name: folder.name,
        children: folders
          .filter((f) => !chain.includes(f.id) && f.parent_id === folder.id)
          .map((f) => buildFolder(f, [...chain, folder.id]))
          .sort((a, b) => a.name.localeCompare(b.name)),
        documents: folderDocuments
          .filter((fd) => fd.folder_id === folder.id)
          .map((fd) => {
            documentsInFolders.push(fd.document_id)
            return documents.find((d) => d.id === fd.document_id)
          })
          .filter((d) => d)
          // TODO: this should no longer be necessary in the next version of typescript
          .map((d) => d!)
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
      }
    }

    return {
      id: "root",
      name: "",
      children: folders
        .filter((folder) => folder.parent_id === null)
        .map((folder) => buildFolder(folder, [folder.id]))
        .sort((a, b) => a.name.localeCompare(b.name)),
      documents: documents
        .filter((d) => !documentsInFolders.includes(d.id))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
    }
  }, [folders, folderDocuments, documents])

  const folderList = useMemo(() => {
    const result: FolderListItem[] = []
    const traverse = (folder: FolderTreeItem, path: string[] = [], prefix: string = "") => {
      result.push({ id: folder.id, path: [...path, folder.id], label: prefix + folder.name })
      folder.children.forEach((f) =>
        traverse(f, [...path, folder.id], prefix + folder.name ? `${prefix}${folder.name}/` : ""),
      )
    }
    traverse(folderTree)
    return result
  }, [folderTree])

  const findFolder = (folderId: string) => {
    return folders?.find((f) => f.id === folderId)
  }

  const findDocument = (documentId: string) => {
    return documents?.find((d) => d.id === documentId)
  }

  return { folderTree, findFolder, findDocument, folderList }
}

export function useDocumentTreeMutations() {
  const queryClient = useQueryClient()

  const { mutate: deleteFolder } = useMutation({
    mutationFn: deleteFolderApi,
    onSuccess({ data }) {
      queryClient.invalidateQueries({ queryKey: ["documents"] })
      queryClient.invalidateQueries({ queryKey: ["folders"] })
      queryClient.invalidateQueries({ queryKey: ["folder_documents"] })
    },
    onError(err) {
      console.error(err)
      toast.error("Failed to delete folder: " + err.message)
    },
  })

  const { mutate: archiveDocument } = useMutation({
    mutationFn: archiveDocumentApi,
    onSuccess({ data }) {
      queryClient.invalidateQueries({ queryKey: ["documents"] })
      queryClient.invalidateQueries({ queryKey: ["folder_documents"] })
    },
    onError(err) {
      console.error(err)
      toast.error("Failed to archive document: " + err.message)
    },
  })

  return { deleteFolder, archiveDocument }
}
