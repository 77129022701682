import { AesEncoder, derivePassphraseKey, FixedIvAesEncoder } from "@/packages/util/crypto"
import { decodeFromBase64, Encoder, encodeToBase64 } from "@/packages/util/encoding"
import { useState } from "react"

interface Props {
  p2pKey: string
  config: {
    key: string
    salt: string
    iv: string
  }
  onSuccess: (p2pKey: string, encoder: Encoder) => void
}

export default function DocumentPassphase({ p2pKey, config, onSuccess }: Props) {
  const [passphrase, setPassphrase] = useState("")
  const [error, setError] = useState("")

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (passphrase === "") {
      setError("Passphrase is required")
      return
    }

    try {
      const passphraseKey = await derivePassphraseKey(passphrase, decodeFromBase64(config.salt))
      const passphraseDecoder = new AesEncoder(passphraseKey)

      const key = await crypto.subtle.importKey(
        "raw",
        await passphraseDecoder.decode(decodeFromBase64(config.key)),
        "AES-CBC",
        false,
        ["encrypt", "decrypt"],
      )
      console.log("key", key)
      const p2pDecryptedKey = encodeToBase64(
        await new AesEncoder(key).decode(decodeFromBase64(p2pKey)),
      )

      const documentDecoder = new FixedIvAesEncoder(key, decodeFromBase64(config.iv))

      onSuccess(p2pDecryptedKey, documentDecoder)
    } catch (e) {
      console.error(e)
      setError("Invalid passphrase")
      return
    }
  }

  return (
    <div className="blueprint-background flex min-h-full flex-1 flex-col justify-center bg-grid px-6 py-12 lg:px-8">
      <div className="rounded border border-slate-900 border-slate-950/40 bg-cell p-6 sm:mx-auto sm:w-full sm:max-w-sm">
        <form action="#" className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="passphrase"
              className="block text-sm font-medium leading-6 text-gray-400"
            >
              Document passphrase
            </label>
            <div className="mt-2">
              <input
                id="passphrase"
                name="passphrase"
                type="password"
                autoFocus
                required
                autoComplete="off"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                value={passphrase}
                onChange={(e) => setPassphrase(e.target.value)}
              />
            </div>
            {error && (
              <p className="mt-2 text-sm text-red-500" id="email-error">
                {error}
              </p>
            )}
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Access
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
