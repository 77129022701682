import { Liquid } from "liquidjs"
import { createTable } from "../tables/table"
import { TypeScriptWorker } from "../ts/compiler"
import { buildScript } from "../ts/templates"
import { CellInfo, CellId } from "@/engine/state/types"
import { InterceptConsole, LogMessage } from "./console"
import parseHttpRequest from "../http/requestParser"
import { evaluateTableData } from "../tables/formulas"

export type CellInput = Record<string, any>

export interface Script {
  run(self: typeof globalThis, $: CellInput, context: EvalContext, console: Console): Promise<any>
}

export type CellOutput = {
  cellId: CellId
  startTimestamp: number
  endTimestamp: number
  messages: LogMessage[]
  value: any
}

export type EvalResult =
  | {
      success: true
      output: CellOutput
    }
  | {
      success: false
      error: EvalError
    }

class EvalError extends Error {
  constructor(
    message: string,
    public cellId: CellId,
    public messages: LogMessage[],
    public innerError: unknown,
  ) {
    super(message)
  }
}

const AsyncFunction = async function () {}.constructor

async function evalScript(script: string): Promise<Script> {
  script = script.replace(/export {};\n?$/, "")
  script = `${script}\nreturn new Script();`

  const result: Script = await AsyncFunction(script)()
  return result
}

function createInputProxy(input: Record<string, any>) {
  return new Proxy(input, {
    get(target, key: string) {
      if (key in target) {
        return target[key]
      } else {
        throw new Error(`Input "${key}" not found, are you missing a dependency?`)
      }
    },
  })
}

export class EvalContext {
  cache = new Map<CellId, CellOutput>()

  private data = new Map<string, any>()
  private liquid = new Liquid()
  private interceptConsole = new InterceptConsole()

  constructor(
    private cellId: CellId,
    private cells: Record<string, CellInfo>,
    private cellContent: Record<string, any>,
    private dependencyMap: Map<CellId, CellId[]>,
    private typeScriptWorker: TypeScriptWorker,
  ) {
    this.liquid.registerFilter("get", (initial: any, ...rest: any[]) => {
      return rest.reduce((acc, key) => acc[key], initial)
    })
  }

  // TODO: proper handling state handling if eval is going to be allowed from scripts
  async eval(): Promise<EvalResult> {
    this.cache.clear()
    this.data.clear()
    this.interceptConsole.clear()

    let result: EvalResult
    try {
      result = {
        success: true,
        output: await this.evalCell(this.cells[this.cellId]),
      }
    } catch (e) {
      if (e instanceof EvalError) {
        result = { success: false, error: e }
      } else {
        result = { success: false, error: new EvalError("Unable to eval cell", this.cellId, [], e) }
      }
    }

    return result
  }

  get(key: string) {
    return this.data.get(key)
  }

  set(key: string, value: any) {
    this.data.set(key, value)
  }

  has(key: string) {
    return this.data.has(key)
  }

  private async evalCell(cell: CellInfo, refChain: CellId[] = []): Promise<CellOutput> {
    console.log(`Evaluating: ${refChain.join(" -> ")} -> ${cell.id}`, this.cache.has(cell.id))
    if (this.cache.has(cell.id)) {
      return this.cache.get(cell.id)!
    }

    if (refChain.includes(cell.id)) {
      // Allow circular dependencies for starting cell
      // This is to allow writing back to a cell
      if (cell.id === this.cellId) {
        return {
          cellId: cell.id,
          startTimestamp: 0,
          endTimestamp: 0,
          messages: [],
          value: undefined,
        }
      } else {
        throw new Error(`Circular dependency detected: ${refChain.join(" -> ")} -> ${cell.id}`)
      }
    }

    const dependencies = this.dependencyMap
      .get(cell.id)!
      .map((id) => this.cells[id])
      .filter((dep) => dep.name)

    const inputValues: [string, any][] = []
    for (const dep of dependencies) {
      const output = await this.evalCell(dep, [...refChain, cell.id])
      inputValues.push([dep.name!, output.value])
    }

    const input = Object.fromEntries(inputValues)

    const startTimestamp = Date.now()
    const cellContent = this.cellContent[cell.id]
    let value: any
    try {
      switch (cell.type) {
        case "empty": {
          value = undefined
          break
        }
        case "code": {
          if (typeof cellContent !== "string") {
            throw new TypeError(`Expected cell content to be a string, got ${typeof cellContent}`)
          }
          const { fileName, fileContent } = buildScript(cell.id, cellContent)
          const script = await evalScript(
            await this.typeScriptWorker.compile(fileName, fileContent),
          )
          const inputProxy = createInputProxy(input)

          value = await script.run(globalThis, inputProxy, this, this.interceptConsole)
          break
        }
        case "http": {
          if (typeof cellContent !== "string") {
            throw new TypeError(`Expected cell content to be a string, got ${typeof cellContent}`)
          }
          const text = await this.liquid.parseAndRender(cellContent, input)
          const request = parseHttpRequest(text)

          value = await request.execute()

          break
        }
        case "table": {
          if (!Array.isArray(cellContent)) {
            throw new TypeError(`Expected cell content to be an array, got ${typeof cellContent}`)
          }

          value = createTable(evaluateTableData(cellContent, input))
          break
        }
        case "text": {
          if (typeof cellContent !== "string") {
            throw new TypeError(`Expected cell content to be a string, got ${typeof cellContent}`)
          }
          value = await this.liquid.parseAndRender(cellContent, input)
          break
        }
        default: {
          value = cellContent
          break
        }
      }
    } catch (e) {
      console.error(e)
      throw new EvalError(
        `Error evaluating cell "${cell.id}"`,
        cell.id,
        this.interceptConsole.messages,
        e,
      )
    }

    const endTimestamp = Date.now()
    const output: CellOutput = {
      cellId: cell.id,
      startTimestamp,
      endTimestamp,
      messages: this.interceptConsole.messages,
      value,
    }

    this.cache.set(cell.id, output)
    return output
  }
}
