import { EvalData } from ".."
import { createWorkerBridge } from "@/packages/worker-rpc"
import { TypeScriptWorker } from "./compiler"
import { buildScript, buildScriptTypes, getBuiltInTypes } from "./templates"
import Worker from "./worker?worker"

export const typeScriptWorker: TypeScriptWorker = createWorkerBridge(new Worker())

export async function updateTsWorker(state: EvalData, firstLoad = false) {
  const files: Record<string, string> = {}

  const builtinTypes = getBuiltInTypes()
  files[builtinTypes.fileName] = builtinTypes.fileContent

  Object.values(state.cells).forEach((cell) => {
    if (cell.type === "code") {
      const cellInputs = (state.dependencies.get(cell.id) ?? [])
        .map((id) => state.cells[id])
        .filter((cell) => cell.name)
        .map((cell) => {
          return {
            id: cell.id,
            name: cell.name!,
            type: cell.type,
          }
        })

      const cellTypes = buildScriptTypes(cell.id, cellInputs)
      const cellScript = buildScript(cell.id, state.content[cell.id])

      files[cellTypes.fileName] = cellTypes.fileContent
      files[cellScript.fileName] = cellScript.fileContent
    }
  })

  await typeScriptWorker.updateFiles(files, firstLoad)
}
