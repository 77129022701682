import { OutputPanel } from "../output/OutputPanel"
import { useEffect, useState } from "react"
import { ArchiveIcon, SettingsIcon, TerminalSquareIcon, XIcon } from "lucide-react"
import { useEngine } from "./EngineContext"
import { ArchivePanel } from "./ArchivePanel"
import { Menu, MenuItem, MenuTrigger } from "../../components/Menu"
import { queryClient } from "@/app/supabase"
import { useNavigate } from "@tanstack/react-router"
import { documentRoute } from "../../routes"
import { IconButton } from "../../components/Button"

type Panel = "output" | "archive"

const panelTitles: Record<Panel, string> = {
  output: "Cell Output",
  archive: "Archive",
}

export default function ToolbarDesktop() {
  const engine = useEngine()
  const [openPanel, setOpenPanel] = useState<Panel | null>(null)

  const panelTitle = openPanel ? panelTitles[openPanel] : ""

  const { slug } = documentRoute.useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = engine.on("evalStart", ({ runAction }) => {
      if (runAction !== "fill") setOpenPanel("output")
    })
    return () => {
      unsubscribe()
    }
  }, [engine])

  useEffect(() => {
    if (!openPanel) return

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpenPanel(null)
        e.preventDefault()
        e.stopImmediatePropagation()
      }
    }
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [openPanel])

  const togglePanel = (panel: Panel) => {
    if (openPanel === panel) {
      setOpenPanel(null)
    } else {
      setOpenPanel(panel)
    }
  }

  const optimizeDocument = async () => {
    console.log("optimize document")
    const newDocId = await engine.stateManager.ySync.optimize()
    console.log("new doc id", newDocId)

    queryClient.invalidateQueries({ queryKey: ["document"] })
  }

  const editDocument = () => {
    navigate({ to: "/g/$slug/edit", params: { slug } })
  }

  return (
    <div className="relative flex h-full w-full flex-row bg-ui">
      {openPanel && (
        <>
          <div className="pointer-events-none absolute inset-0 -left-1 z-[1000] w-1 bg-gradient-to-l from-zinc-900 to-transparent"></div>
          <div className="flex w-[32rem] max-w-[100dvw] flex-col">
            <div className="flex h-11 items-center px-4">
              <button
                type="button"
                className="relative mr-3 rounded-md text-gray-400 hover:text-gray-500"
                onClick={() => setOpenPanel(null)}
              >
                <XIcon className="h-6" aria-hidden="true" strokeWidth={1.5} />
              </button>
              <div className="text-base font-semibold leading-6 text-gray-400">{panelTitle}</div>
            </div>

            <div className="flex-1 overflow-hidden">
              {openPanel === "output" && <OutputPanel onClose={() => setOpenPanel(null)} />}
              {openPanel === "archive" && <ArchivePanel onClose={() => setOpenPanel(null)} />}
            </div>
          </div>
        </>
      )}
      <div className="flex w-10 flex-col items-center border-l border-zinc-900 py-2">
        <button
          onClick={() => togglePanel("output")}
          className="mb-2 h-8 w-8 rounded hover:bg-zinc-800"
        >
          <TerminalSquareIcon className="m-auto h-5 w-5" strokeWidth={1.5} />
        </button>
        <button
          onClick={() => togglePanel("archive")}
          className="mb-2 h-8 w-8 rounded hover:bg-zinc-800"
        >
          <ArchiveIcon className="m-auto h-5 w-5" strokeWidth={1.5} />
        </button>
        <div className="flex-1" />
        <MenuTrigger>
          <IconButton className="h-8 w-8 rounded hover:bg-zinc-800">
            <SettingsIcon className="m-auto h-5 w-5" strokeWidth={1.5} />
          </IconButton>
          <Menu placement="top left">
            <MenuItem textValue="Optimize Document" onAction={optimizeDocument}>
              Optimize Document
            </MenuItem>
            <MenuItem textValue="Edit Document" onAction={editDocument}>
              Edit Document
            </MenuItem>
          </Menu>
        </MenuTrigger>
      </div>
    </div>
  )
}
