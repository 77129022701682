import "./init"
import React, { useEffect } from "react"
import ReactDOM from "react-dom/client"
import { Provider as ReduxProvider } from "react-redux"
import { store } from "./app/store"
import { ToastContainer } from "react-toastify"
import { QueryClientProvider } from "@tanstack/react-query"
import { RouterProvider, ErrorComponent, createRouter } from "@tanstack/react-router"
import { routeTree } from "./app/ui/routes"
import { queryClient, supabase } from "./app/supabase"
import * as Sentry from "@sentry/react"
import { AuthProvider, useAuth } from "./app/ui/auth/AuthProvider"
import "react-toastify/dist/ReactToastify.css"
import "./index.css"
import { ServiceWorkerProvider } from "./app/ui/workspace/ServiceWorkerContext"

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://74b496f5752adc3eaa8f003ea1dc9624@us.sentry.io/4506696229060608",
    integrations: [
      Sentry.globalHandlersIntegration({
        onerror: true,
        onunhandledrejection: false,
      }),
    ],
  })
}

const router = createRouter({
  routeTree,
  defaultPendingComponent: () => (
    <div className="p-2">
      <div className="animate-pulse">Loading...</div>
    </div>
  ),
  defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
  context: {
    auth: undefined!, // We'll inject this when we render
    supabase: supabase,
    queryClient,
  },
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
})

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
}

console.log(BUILD_TIME, BUILD_HASH, BUILD_BRANCH)

function App() {
  const auth = useAuth()

  useEffect(() => {
    const handler = (e: Event) => e.preventDefault()
    document.addEventListener("gesturestart", handler)
    document.addEventListener("gesturechange", handler)
    document.addEventListener("gestureend", handler)
    return () => {
      document.removeEventListener("gesturestart", handler)
      document.removeEventListener("gesturechange", handler)
      document.removeEventListener("gestureend", handler)
    }
  }, [])

  return <RouterProvider router={router} context={{ auth }} />
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <ServiceWorkerProvider>
          <AuthProvider>
            <App />
            <ToastContainer />
          </AuthProvider>
        </ServiceWorkerProvider>
      </QueryClientProvider>
    </ReduxProvider>
  </React.StrictMode>,
)
