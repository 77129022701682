// @ts-ignore
import { parser as httpParser } from "./syntax.grammar"
import { LanguageSupport, LRLanguage } from "@codemirror/language"
import { styleTags, tags as t } from "@lezer/highlight"
import { parseMixed, Parser } from "@lezer/common"

import { html } from "@codemirror/lang-html"
import { json } from "@codemirror/lang-json"
import { javascript } from "@codemirror/lang-javascript"
import { xml } from "@codemirror/lang-xml"

import { autocomplete } from "./autocomplete.js"

const languageParsers: Record<string, Parser> = {
  ContentJSON: json().language.parser,
  ContentJS: javascript().language.parser,
  ContentHTML: html().language.parser,
  ContentXML: xml().language.parser,
  // ContentGraphQL: graphql().language.parser,
}

export const mixedParser = httpParser.configure({
  props: [
    styleTags({
      Method: t.keyword,
      HttpVersion: t.annotation,
      Scheme: t.content,
      Host: t.labelName,
      Path: t.content,
      Query: t.string,
      Fragment: t.string,
      QueryString: t.string,
      QueryArgName: t.variableName,
      QueryArgValue: t.color,
      HeaderName: t.propertyName,
      HeaderValue: t.string,
      FormName: t.variableName,
      FormValue: t.string,
      StatusSuccess: t.labelName,
      StatusError: t.definitionKeyword,
    }),
  ],
  wrap: parseMixed((node) => {
    const parser = languageParsers[node.name]
    return parser ? { parser } : null
  }),
})

const httpMessageLanguage = LRLanguage.define({ parser: mixedParser })

export function http() {
  return new LanguageSupport(httpMessageLanguage, [
    httpMessageLanguage.data.of({
      autocomplete: autocomplete,
    }),
  ])
}
